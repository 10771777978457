
import React, { useState, useEffect, useContext } from 'react';
import { translate } from 'react-multi-lang';
import { Row, Col, Form, Input } from 'antd';

import { PerfilUsuariContext } from "../context/perfil-usuari";
import { Encriptar } from '../helpers';


function AutoritzarContrasenya(props){

  const [perfilUsuari, setPerfilUsuari] = useContext(PerfilUsuariContext);
  const [password1, setPassword1] = useState('');
  const [password2, setPassword2] = useState(perfilUsuari.verificar);
  const [placeholderText, setPlaceholderText] = useState(props.t('autoritzar_contrasenya.contrasenya'));

  const [errorPassword1, setErrorPassword1] = useState('warning');
  const [errorPassword1Text, setErrorPassword1Text] = useState(props.t('autoritzar_contrasenya.descripcio'));

  useEffect(() => {
    setPlaceholderText(props.t('autoritzar_contrasenya.contrasenya'));
    setErrorPassword1Text(props.t('autoritzar_contrasenya.descripcio'));
  },[props]);

  const onChangePassword1 = (valor) => {
    setPassword1(valor);
    if(valor.length>0){
      if(!Encriptar.compararContrasenya(valor, password2)){
        setErrorPassword1('error');
        setErrorPassword1Text(props.t('autoritzar_contrasenya.contrasenya_incorrecte'));
        props.resposta(false);
      }else{
        props.resposta(true);
        setErrorPassword1('success');
        setErrorPassword1Text('');
      }
    }else{
      setErrorPassword1('warning');
      setErrorPassword1Text(props.t('autoritzar_contrasenya.descripcio'));
    }
  }

  const data = new Date();
    return(

      <Row type="flex" justify="center">
      <Form
          layout="vertical"
          autoComplete="new-password"
        >
        <Form.Item hasFeedback validateStatus={errorPassword1} help={errorPassword1Text} autoComplete="new-pasword">
          <Input.Password name="contrasenya_validar" placeholder={placeholderText} onChange={(e) => onChangePassword1(e.target.value)} defaultValue={password1} autoComplete="ñlaskdfjñalkdfj"/>
        </Form.Item>
      </Form>
      </Row>
    );
}
export default translate(AutoritzarContrasenya);
