import React, { useState, useEffect } from "react";
import ReactExport from "react-export-excel";
import { translate } from 'react-multi-lang';
import { SyncOutlined, PercentageOutlined, LeftOutlined, EditOutlined, DeleteOutlined, SearchOutlined, FileExcelOutlined } from '@ant-design/icons';
import {Table, Tooltip, Popconfirm, Modal, Form, Row, Col, Select, Button, Input, InputNumber, Divider, Switch, DatePicker, Space, message } from 'antd';

import { AllHtmlEntities } from 'html-entities';
import moment from 'moment';
import 'moment/locale/ca';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const entities = new AllHtmlEntities();

function ComandesExcursionsExportaExcel(props){

  const [nursery, setNursery] = useState([]);
  const [reception, setReception] = useState([]);
  const [year1, setYear1] = useState([]);
  const [year2, setYear2] = useState([]);
  const [year34, setYear34] = useState([]);
  const [year56, setYear56] = useState([]);
  const [year78, setYear78] = useState([]);

  const nom_fulla = props.nom_fulla ? props.nom_fulla : props.t('menu.ComandesSummerCamp');
  const nom_fitxer = props.nom_fitxer ? props.nom_fitxer : props.t('menu.ComandesSummerCamp');

  useEffect(() => {

    let fnursery_temp = [];
    let freception_temp = [];
    let fyear1_temp = [];
    let fyear2_temp = [];
    let fyear34_temp = [];
    let fyear56_temp = [];
    let fyear78_temp = [];
    console.log('props.dades', props.dades)

    props.dades.forEach((comanda, i) => {

      if(comanda.estado === 'PENDIENTE PAGO'){
        return true;
      }
      comanda.json.nens.forEach((nen, i) => {
        nen.id_comanda = comanda.id;
        nen.estat = comanda.estado;
        nen.creat = comanda.fcrea;
        nen.alumne_complet = nen.nom+' '+nen.cognoms;
        nen.pare = comanda.json.pare;
        nen.pare2 = comanda.json.pare2;

        for(let i=1; i<2000; i++ ){
          nen[`w${i}`] = 0;
        }

        if(nen.comprat && nen.comprat.propietats.weeks.w1 > 0){
          const p = nen.comprat.propietats.weeks.w1;
          nen[`w${p}`] = 1;
        }
        if(nen.comprat && nen.comprat.propietats.weeks.w2 > 0){
          const p = nen.comprat.propietats.weeks.w2;
          nen[`w${p}`] = 1;
        }
        if(nen.comprat && nen.comprat.propietats.weeks.w3 > 0){
          const p = nen.comprat.propietats.weeks.w3;
          nen[`w${p}`] = 1;
        }
        if(nen.comprat && nen.comprat.propietats.weeks.w4 > 0){
          const p = nen.comprat.propietats.weeks.w4;
          nen[`w${p}`] = 1;
        }
        if(nen.comprat && nen.comprat.propietats.weeks.w5 > 0){
          const p = nen.comprat.propietats.weeks.w5;
          nen[`w${p}`] = 1;
        }
        if(nen.comprat && nen.comprat.propietats.bus.b1 > 0){
          const p = nen.comprat.propietats.bus.b1;
          nen[`w${p}`] = 1;
        }
        if(nen.comprat && nen.comprat.propietats.bus.b2 > 0){
          const p = nen.comprat.propietats.bus.b2;
          nen[`w${p}`] = 1;
        }
        if(nen.comprat && nen.comprat.propietats.bus.b3 > 0){
          const p = nen.comprat.propietats.bus.b3;
          nen[`w${p}`] = 1;
        }
        if(nen.comprat && nen.comprat.propietats.bus.b4 > 0){
          const p = nen.comprat.propietats.bus.b4;
          nen[`w${p}`] = 1;
        }
        if(nen.comprat && nen.comprat.propietats.bus.b5 > 0){
          const p = nen.comprat.propietats.bus.b5;
          nen[`w${p}`] = 1;
        }

        if(nen.comprat){
          switch (nen.comprat.id_producte) {
            case 209:
              fnursery_temp.push(nen);
              break;
            case 210:
              freception_temp.push(nen);
              break;
            case 211:
              fyear1_temp.push(nen);
              break;
            case 212:
              fyear2_temp.push(nen);
              break;
            case 213:
              fyear34_temp.push(nen);
              break;
            case 214:
              fyear56_temp.push(nen);
              break;
            case 215:
              fyear78_temp.push(nen);
              break;

            default:
          }
        }
      });

    });

    setNursery(fnursery_temp);
    setReception(freception_temp);
    setYear1(fyear1_temp);
    setYear2(fyear2_temp);
    setYear34(fyear34_temp);
    setYear56(fyear56_temp);
    setYear78(fyear78_temp);

  },[props.dades]);




  return (
    <ExcelFile
      filename={nom_fitxer}
      element={<Button type="primary" style={{backgroundColor: '#217346', borderColor: '#217346', marginBottom: '0.5rem', marginLeft: '0.5rem', marginRight: '0.5rem'}} shape="round" icon={<FileExcelOutlined />}>{props.btn_txt}</Button>}>
        <ExcelSheet data={nursery} name={'Nursery'}>
          <ExcelColumn label="Id_comanda"  value={'id_comanda'}/>
          <ExcelColumn label="Id_fill" value="id"/>
          <ExcelColumn label={props.t('botiga.titolEstat')} value={(col) => col.estat}/>
          <ExcelColumn label={props.t('botiga.producte')} value={(col) => entities.decode(col.nom[props.t('idioma')])}/>
          <ExcelColumn label={props.t('alumnes.classe')} value={(col) => entities.decode(col.classe)}/>
          <ExcelColumn label={props.t('generic.alumne')} value={(col) => entities.decode(col.alumne_complet)}/>
          <ExcelColumn label={props.t('generic.total')} value={(col) => parseFloat(col.total)}/>

          <ExcelColumn label={'Setmana 1: 9h a 13h'} value="w1435"/>
          <ExcelColumn label={'Setmana 1: 9h-16h (dinar)'} value="w1436"/>
          <ExcelColumn label={'Setmana 1: bus anada'} value="w1437"/>
          <ExcelColumn label={'Setmana 1: bus tornada'} value="w1438"/>
          <ExcelColumn label={'Setmana 1: bus anada i tornada'} value="w1439"/>

          <ExcelColumn label={'Setmana 2: 9h a 13h'} value="w1440"/>
          <ExcelColumn label={'Setmana 2: 9h-16h (dinar)'} value="w1441"/>
          <ExcelColumn label={'Setmana 2: bus anada'} value="w1442"/>
          <ExcelColumn label={'Setmana 2: bus tornada'} value="w1443"/>
          <ExcelColumn label={'Setmana 2: bus anada i tornada'} value="w1444"/>

          <ExcelColumn label={'Setmana 3: 9h a 13h'} value="w1445"/>
          <ExcelColumn label={'Setmana 3: 9h-16h (dinar)'} value="w1446"/>
          <ExcelColumn label={'Setmana 3: bus anada'} value="w1447"/>
          <ExcelColumn label={'Setmana 3: bus tornada'} value="w1448"/>
          <ExcelColumn label={'Setmana 3: bus anada i tornada'} value="w1449"/>

          <ExcelColumn label={'Setmana 4: 9h a 13h'} value="w1450"/>
          <ExcelColumn label={'Setmana 4: 9h-16h (dinar)'} value="w1451"/>
          <ExcelColumn label={'Setmana 4: bus anada'} value="w1452"/>
          <ExcelColumn label={'Setmana 4: bus tornada'} value="w1453"/>
          <ExcelColumn label={'Setmana 4: bus anada i tornada'} value="w1454"/>

          <ExcelColumn label={'Setmana 5: 9h a 13h'} value="w1455"/>
          <ExcelColumn label={'Setmana 5: 9h-16h (dinar)'} value="w1456"/>
          <ExcelColumn label={'Setmana 5: bus anada'} value="w1457"/>
          <ExcelColumn label={'Setmana 5: bus tornada'} value="w1458"/>
          <ExcelColumn label={'Setmana 5: bus anada i tornada'} value="w1459"/>

            <ExcelColumn label={props.t('generic.created_at')} value={(col) => moment(col.creat).tz('Europe/Madrid').format('DD/MM/YYYY')}/>

            {/* Dades alumne */}
            <ExcelColumn label="CatSalut" value={(col) => entities.decode(col.catsalut)}/>
            <ExcelColumn label="Alergia" value={(col) => entities.decode(col.alergia)}/>
            <ExcelColumn label="Intolerancia" value={(col) => entities.decode(col.intolerancia)}/>
            <ExcelColumn label="Malalties" value={(col) => entities.decode(col.malalties)}/>
            <ExcelColumn label="Medicacio" value={(col) => entities.decode(col.medicacio)}/>
            <ExcelColumn label="Nascut" value={(col) => moment(col.naixement).tz('Europe/Madrid').format('DD/MM/YYYY')}/>
            {/* Dades Pare1 */}
            <ExcelColumn label="Pare1 Nom" value={(col) => entities.decode(col.pare.nom)}/>
            <ExcelColumn label="Cognoms" value={(col) => entities.decode(col.pare.cognoms)}/>
            <ExcelColumn label="Tel1" value={(col) => entities.decode(col.pare.tel1)}/>
            <ExcelColumn label="Tel2" value={(col) => entities.decode(col.pare.tel2)}/>
            <ExcelColumn label="email" value={(col) => entities.decode(col.pare.email)}/>
            <ExcelColumn label="Adress" value={(col) => entities.decode(col.pare.adress)}/>
            {/* Dades Pare2 */}
            <ExcelColumn label="Pare2 Nom" value={(col) => entities.decode(col.pare2.nom)}/>
            <ExcelColumn label="Cognoms" value={(col) => entities.decode(col.pare2.cognoms)}/>
            <ExcelColumn label="Tel1" value={(col) => entities.decode(col.pare2.tel1)}/>
            <ExcelColumn label="Tel2" value={(col) => entities.decode(col.pare2.tel2)}/>

          </ExcelSheet>

          <ExcelSheet data={reception} name={'Reception'}>
            <ExcelColumn label="Id_comanda"  value={'id_comanda'}/>
            <ExcelColumn label="Id_fill" value="id"/>
            <ExcelColumn label={props.t('botiga.titolEstat')} value={(col) => col.estat}/>
            <ExcelColumn label={props.t('botiga.producte')} value={(col) => entities.decode(col.nom[props.t('idioma')])}/>
            <ExcelColumn label={props.t('alumnes.classe')} value={(col) => entities.decode(col.classe)}/>
            <ExcelColumn label={props.t('generic.alumne')} value={(col) => entities.decode(col.alumne_complet)}/>
            <ExcelColumn label={props.t('generic.total')} value={(col) => parseFloat(col.total)}/>

            <ExcelColumn label={'Setmana 1: 9h a 13h'} value="w1466"/>
            <ExcelColumn label={'Setmana 1: 9h-16h (dinar)'} value="w1467"/>
            <ExcelColumn label={'Setmana 1: bus anada'} value="w1468"/>
            <ExcelColumn label={'Setmana 1: bus tornada'} value="w1469"/>
            <ExcelColumn label={'Setmana 1: bus anada i tornada'} value="w1470"/>

            <ExcelColumn label={'Setmana 2: 9h a 13h'} value="w1471"/>
            <ExcelColumn label={'Setmana 2: 9h-16h (dinar)'} value="w1472"/>
            <ExcelColumn label={'Setmana 2: bus anada'} value="w1473"/>
            <ExcelColumn label={'Setmana 2: bus tornada'} value="w1474"/>
            <ExcelColumn label={'Setmana 2: bus anada i tornada'} value="w1475"/>

            <ExcelColumn label={'Setmana 3: 9h a 13h'} value="w1476"/>
            <ExcelColumn label={'Setmana 3: 9h-16h (dinar)'} value="w1477"/>
            <ExcelColumn label={'Setmana 3: bus anada'} value="w1478"/>
            <ExcelColumn label={'Setmana 3: bus tornada'} value="w1479"/>
            <ExcelColumn label={'Setmana 3: bus anada i tornada'} value="w1480"/>

            <ExcelColumn label={'Setmana 4: 9h a 13h'} value="w1481"/>
            <ExcelColumn label={'Setmana 4: 9h-16h (dinar)'} value="w1482"/>
            <ExcelColumn label={'Setmana 4: bus anada'} value="w1483"/>
            <ExcelColumn label={'Setmana 4: bus tornada'} value="w1484"/>
            <ExcelColumn label={'Setmana 4: bus anada i tornada'} value="w1485"/>

            <ExcelColumn label={'Setmana 5: 9h a 13h'} value="w1486"/>
            <ExcelColumn label={'Setmana 5: 9h-16h (dinar)'} value="w1487"/>
            <ExcelColumn label={'Setmana 5: bus anada'} value="w1488"/>
            <ExcelColumn label={'Setmana 5: bus tornada'} value="w1489"/>
            <ExcelColumn label={'Setmana 5: bus anada i tornada'} value="w1490"/>

            <ExcelColumn label={props.t('generic.created_at')} value={(col) => moment(col.creat).tz('Europe/Madrid').format('DD/MM/YYYY')}/>

            {/* Dades alumne */}
            <ExcelColumn label="CatSalut" value={(col) => entities.decode(col.catsalut)}/>
            <ExcelColumn label="Alergia" value={(col) => entities.decode(col.alergia)}/>
            <ExcelColumn label="Intolerancia" value={(col) => entities.decode(col.intolerancia)}/>
            <ExcelColumn label="Malalties" value={(col) => entities.decode(col.malalties)}/>
            <ExcelColumn label="Medicacio" value={(col) => entities.decode(col.medicacio)}/>
            <ExcelColumn label="Nascut" value={(col) => moment(col.naixement).tz('Europe/Madrid').format('DD/MM/YYYY')}/>
            {/* Dades Pare1 */}
            <ExcelColumn label="Pare1 Nom" value={(col) => entities.decode(col.pare.nom)}/>
            <ExcelColumn label="Cognoms" value={(col) => entities.decode(col.pare.cognoms)}/>
            <ExcelColumn label="Tel1" value={(col) => entities.decode(col.pare.tel1)}/>
            <ExcelColumn label="Tel2" value={(col) => entities.decode(col.pare.tel2)}/>
            <ExcelColumn label="email" value={(col) => entities.decode(col.pare.email)}/>
            <ExcelColumn label="Adress" value={(col) => entities.decode(col.pare.adress)}/>
            {/* Dades Pare2 */}
            <ExcelColumn label="Pare2 Nom" value={(col) => entities.decode(col.pare2.nom)}/>
            <ExcelColumn label="Cognoms" value={(col) => entities.decode(col.pare2.cognoms)}/>
            <ExcelColumn label="Tel1" value={(col) => entities.decode(col.pare2.tel1)}/>
            <ExcelColumn label="Tel2" value={(col) => entities.decode(col.pare2.tel2)}/>
          </ExcelSheet>

          <ExcelSheet data={year1} name={'Year 1'}>
            <ExcelColumn label="Id_comanda"  value={'id_comanda'}/>
            <ExcelColumn label="Id_fill" value="id"/>
            <ExcelColumn label={props.t('botiga.titolEstat')} value={(col) => col.estat}/>
            <ExcelColumn label={props.t('botiga.producte')} value={(col) => entities.decode(col.nom[props.t('idioma')])}/>
            <ExcelColumn label={props.t('alumnes.classe')} value={(col) => entities.decode(col.classe)}/>
            <ExcelColumn label={props.t('generic.alumne')} value={(col) => entities.decode(col.alumne_complet)}/>
            <ExcelColumn label={props.t('generic.total')} value={(col) => parseFloat(col.total)}/>

            <ExcelColumn label={'Setmana 1: 9h a 13h'} value="w1497"/>
            <ExcelColumn label={'Setmana 1: 9h-16h (dinar)'} value="w1498"/>
            <ExcelColumn label={'Setmana 1: bus anada'} value="w1499"/>
            <ExcelColumn label={'Setmana 1: bus tornada'} value="w1500"/>
            <ExcelColumn label={'Setmana 1: bus anada i tornada'} value="w1501"/>

            <ExcelColumn label={'Setmana 2: 9h a 13h'} value="w1502"/>
            <ExcelColumn label={'Setmana 2: 9h-16h (dinar)'} value="w1503"/>
            <ExcelColumn label={'Setmana 2: bus anada'} value="w1504"/>
            <ExcelColumn label={'Setmana 2: bus tornada'} value="w1505"/>
            <ExcelColumn label={'Setmana 2: bus anada i tornada'} value="w1506"/>

            <ExcelColumn label={'Setmana 3: 9h a 13h'} value="w1507"/>
            <ExcelColumn label={'Setmana 3: 9h-16h (dinar)'} value="w1508"/>
            <ExcelColumn label={'Setmana 3: bus anada'} value="w1509"/>
            <ExcelColumn label={'Setmana 3: bus tornada'} value="w1510"/>
            <ExcelColumn label={'Setmana 3: bus anada i tornada'} value="w1511"/>

            <ExcelColumn label={'Setmana 4: 9h a 13h'} value="w1512"/>
            <ExcelColumn label={'Setmana 4: 9h-16h (dinar)'} value="w1513"/>
            <ExcelColumn label={'Setmana 4: bus anada'} value="w1514"/>
            <ExcelColumn label={'Setmana 4: bus tornada'} value="w1515"/>
            <ExcelColumn label={'Setmana 4: bus anada i tornada'} value="w1516"/>

            <ExcelColumn label={'Setmana 5: 9h a 13h'} value="w1517"/>
            <ExcelColumn label={'Setmana 5: 9h-16h (dinar)'} value="w1518"/>
            <ExcelColumn label={'Setmana 5: bus anada'} value="w1519"/>
            <ExcelColumn label={'Setmana 5: bus tornada'} value="w1520"/>
            <ExcelColumn label={'Setmana 5: bus anada i tornada'} value="w1521"/>

            <ExcelColumn label={props.t('generic.created_at')} value={(col) => moment(col.creat).tz('Europe/Madrid').format('DD/MM/YYYY')}/>

            {/* Dades alumne */}
            <ExcelColumn label="CatSalut" value={(col) => entities.decode(col.catsalut)}/>
            <ExcelColumn label="Alergia" value={(col) => entities.decode(col.alergia)}/>
            <ExcelColumn label="Intolerancia" value={(col) => entities.decode(col.intolerancia)}/>
            <ExcelColumn label="Malalties" value={(col) => entities.decode(col.malalties)}/>
            <ExcelColumn label="Medicacio" value={(col) => entities.decode(col.medicacio)}/>
            <ExcelColumn label="Nascut" value={(col) => moment(col.naixement).tz('Europe/Madrid').format('DD/MM/YYYY')}/>
            {/* Dades Pare1 */}
            <ExcelColumn label="Pare1 Nom" value={(col) => entities.decode(col.pare.nom)}/>
            <ExcelColumn label="Cognoms" value={(col) => entities.decode(col.pare.cognoms)}/>
            <ExcelColumn label="Tel1" value={(col) => entities.decode(col.pare.tel1)}/>
            <ExcelColumn label="Tel2" value={(col) => entities.decode(col.pare.tel2)}/>
            <ExcelColumn label="email" value={(col) => entities.decode(col.pare.email)}/>
            <ExcelColumn label="Adress" value={(col) => entities.decode(col.pare.adress)}/>
            {/* Dades Pare2 */}
            <ExcelColumn label="Pare2 Nom" value={(col) => entities.decode(col.pare2.nom)}/>
            <ExcelColumn label="Cognoms" value={(col) => entities.decode(col.pare2.cognoms)}/>
            <ExcelColumn label="Tel1" value={(col) => entities.decode(col.pare2.tel1)}/>
            <ExcelColumn label="Tel2" value={(col) => entities.decode(col.pare2.tel2)}/>
          </ExcelSheet>

          <ExcelSheet data={year2} name={'Year 2'}>
            <ExcelColumn label="Id_comanda"  value={'id_comanda'}/>
            <ExcelColumn label="Id_fill" value="id"/>
            <ExcelColumn label={props.t('botiga.titolEstat')} value={(col) => col.estat}/>
            <ExcelColumn label={props.t('botiga.producte')} value={(col) => entities.decode(col.nom[props.t('idioma')])}/>
            <ExcelColumn label={props.t('alumnes.classe')} value={(col) => entities.decode(col.classe)}/>
            <ExcelColumn label={props.t('generic.alumne')} value={(col) => entities.decode(col.alumne_complet)}/>
            <ExcelColumn label={props.t('generic.total')} value={(col) => parseFloat(col.total)}/>

            <ExcelColumn label={'Setmana 1: Sailing 9h-16h'} value="w1529"/>
            <ExcelColumn label={'Setmana 1: bus anada'} value="w1530"/>
            <ExcelColumn label={'Setmana 1: bus tornada'} value="w1531"/>
            <ExcelColumn label={'Setmana 1: bus anada i tornada'} value="w1532"/>

            <ExcelColumn label={'Setmana 2: 9h a 13h'} value="w1533"/>
            <ExcelColumn label={'Setmana 2: 9h-16h (dinar)'} value="w1534"/>
            <ExcelColumn label={'Setmana 2: bus anada'} value="w1535"/>
            <ExcelColumn label={'Setmana 2: bus tornada'} value="w1536"/>
            <ExcelColumn label={'Setmana 2: bus anada i tornada'} value="w1537"/>

            <ExcelColumn label={'Setmana 3: 9h a 13h'} value="w1538"/>
            <ExcelColumn label={'Setmana 3: 9h-16h (dinar)'} value="w1539"/>
            <ExcelColumn label={'Setmana 3: bus anada'} value="w1540"/>
            <ExcelColumn label={'Setmana 3: bus tornada'} value="w1541"/>
            <ExcelColumn label={'Setmana 3: bus anada i tornada'} value="w1542"/>

            <ExcelColumn label={'Setmana 4: 9h a 13h'} value="w1543"/>
            <ExcelColumn label={'Setmana 4: 9h-16h (dinar)'} value="w1544"/>
            <ExcelColumn label={'Setmana 4: bus anada'} value="w1545"/>
            <ExcelColumn label={'Setmana 4: bus tornada'} value="w1546"/>
            <ExcelColumn label={'Setmana 4: bus anada i tornada'} value="w1547"/>

            <ExcelColumn label={'Setmana 5: 9h a 13h'} value="w1548"/>
            <ExcelColumn label={'Setmana 5: 9h-16h (dinar)'} value="w1549"/>
            <ExcelColumn label={'Setmana 5: bus anada'} value="w1550"/>
            <ExcelColumn label={'Setmana 5: bus tornada'} value="w1551"/>
            <ExcelColumn label={'Setmana 5: bus anada i tornada'} value="w1552"/>

            <ExcelColumn label={props.t('generic.created_at')} value={(col) => moment(col.creat).tz('Europe/Madrid').format('DD/MM/YYYY')}/>

            {/* Dades alumne */}
            <ExcelColumn label="CatSalut" value={(col) => entities.decode(col.catsalut)}/>
            <ExcelColumn label="Alergia" value={(col) => entities.decode(col.alergia)}/>
            <ExcelColumn label="Intolerancia" value={(col) => entities.decode(col.intolerancia)}/>
            <ExcelColumn label="Malalties" value={(col) => entities.decode(col.malalties)}/>
            <ExcelColumn label="Medicacio" value={(col) => entities.decode(col.medicacio)}/>
            <ExcelColumn label="Nascut" value={(col) => moment(col.naixement).tz('Europe/Madrid').format('DD/MM/YYYY')}/>
            {/* Dades Pare1 */}
            <ExcelColumn label="Pare1 Nom" value={(col) => entities.decode(col.pare.nom)}/>
            <ExcelColumn label="Cognoms" value={(col) => entities.decode(col.pare.cognoms)}/>
            <ExcelColumn label="Tel1" value={(col) => entities.decode(col.pare.tel1)}/>
            <ExcelColumn label="Tel2" value={(col) => entities.decode(col.pare.tel2)}/>
            <ExcelColumn label="email" value={(col) => entities.decode(col.pare.email)}/>
            <ExcelColumn label="Adress" value={(col) => entities.decode(col.pare.adress)}/>
            {/* Dades Pare2 */}
            <ExcelColumn label="Pare2 Nom" value={(col) => entities.decode(col.pare2.nom)}/>
            <ExcelColumn label="Cognoms" value={(col) => entities.decode(col.pare2.cognoms)}/>
            <ExcelColumn label="Tel1" value={(col) => entities.decode(col.pare2.tel1)}/>
            <ExcelColumn label="Tel2" value={(col) => entities.decode(col.pare2.tel2)}/>
          </ExcelSheet>

          <ExcelSheet data={year34} name={'Year 3,4'}>
            <ExcelColumn label="Id_comanda"  value={'id_comanda'}/>
            <ExcelColumn label="Id_fill" value="id"/>
            <ExcelColumn label={props.t('botiga.titolEstat')} value={(col) => col.estat}/>
            <ExcelColumn label={props.t('botiga.producte')} value={(col) => entities.decode(col.nom[props.t('idioma')])}/>
            <ExcelColumn label={props.t('alumnes.classe')} value={(col) => entities.decode(col.classe)}/>
            <ExcelColumn label={props.t('generic.alumne')} value={(col) => entities.decode(col.alumne_complet)}/>
            <ExcelColumn label={props.t('generic.total')} value={(col) => parseFloat(col.total)}/>

            <ExcelColumn label={'Setmana 1: 9h a 13h'} value="w1559"/>
            <ExcelColumn label={'Setmana 1: 9h-16h (dinar)'} value="w1560"/>
            <ExcelColumn label={'Setmana 1: bus anada'} value="w1561"/>
            <ExcelColumn label={'Setmana 1: bus tornada'} value="w1562"/>
            <ExcelColumn label={'Setmana 1: bus anada i tornada'} value="w1563"/>

            <ExcelColumn label={'Setmana 2: 9h a 13h'} value="w1564"/>
            <ExcelColumn label={'Setmana 2: 9h-16h (dinar)'} value="w1565"/>
            <ExcelColumn label={'Setmana 2: bus anada'} value="w1566"/>
            <ExcelColumn label={'Setmana 2: bus tornada'} value="w1567"/>
            <ExcelColumn label={'Setmana 2: bus anada i tornada'} value="w1568"/>

            <ExcelColumn label={'Setmana 3: Sailing 9h-16h'} value="w1570"/>
            <ExcelColumn label={'Setmana 3: bus anada'} value="w1571"/>
            <ExcelColumn label={'Setmana 3: bus tornada'} value="w1572"/>
            <ExcelColumn label={'Setmana 3: bus anada i tornada'} value="w1573"/>

            <ExcelColumn label={'Setmana 4: 9h a 13h'} value="w1574"/>
            <ExcelColumn label={'Setmana 4: 9h-16h (dinar)'} value="w1575"/>
            <ExcelColumn label={'Setmana 4: bus anada'} value="w1576"/>
            <ExcelColumn label={'Setmana 4: bus tornada'} value="w1577"/>
            <ExcelColumn label={'Setmana 4: bus anada i tornada'} value="w1578"/>

            <ExcelColumn label={'Setmana 5: 9h a 13h'} value="w1579"/>
            <ExcelColumn label={'Setmana 5: 9h-16h (dinar)'} value="w1580"/>
            <ExcelColumn label={'Setmana 5: bus anada'} value="w1581"/>
            <ExcelColumn label={'Setmana 5: bus tornada'} value="w1582"/>
            <ExcelColumn label={'Setmana 5: bus anada i tornada'} value="w1583"/>

            <ExcelColumn label={props.t('generic.created_at')} value={(col) => moment(col.creat).tz('Europe/Madrid').format('DD/MM/YYYY')}/>

            {/* Dades alumne */}
            <ExcelColumn label="CatSalut" value={(col) => entities.decode(col.catsalut)}/>
            <ExcelColumn label="Alergia" value={(col) => entities.decode(col.alergia)}/>
            <ExcelColumn label="Intolerancia" value={(col) => entities.decode(col.intolerancia)}/>
            <ExcelColumn label="Malalties" value={(col) => entities.decode(col.malalties)}/>
            <ExcelColumn label="Medicacio" value={(col) => entities.decode(col.medicacio)}/>
            <ExcelColumn label="Nascut" value={(col) => moment(col.naixement).tz('Europe/Madrid').format('DD/MM/YYYY')}/>
            {/* Dades Pare1 */}
            <ExcelColumn label="Pare1 Nom" value={(col) => entities.decode(col.pare.nom)}/>
            <ExcelColumn label="Cognoms" value={(col) => entities.decode(col.pare.cognoms)}/>
            <ExcelColumn label="Tel1" value={(col) => entities.decode(col.pare.tel1)}/>
            <ExcelColumn label="Tel2" value={(col) => entities.decode(col.pare.tel2)}/>
            <ExcelColumn label="email" value={(col) => entities.decode(col.pare.email)}/>
            <ExcelColumn label="Adress" value={(col) => entities.decode(col.pare.adress)}/>
            {/* Dades Pare2 */}
            <ExcelColumn label="Pare2 Nom" value={(col) => entities.decode(col.pare2.nom)}/>
            <ExcelColumn label="Cognoms" value={(col) => entities.decode(col.pare2.cognoms)}/>
            <ExcelColumn label="Tel1" value={(col) => entities.decode(col.pare2.tel1)}/>
            <ExcelColumn label="Tel2" value={(col) => entities.decode(col.pare2.tel2)}/>
          </ExcelSheet>

          <ExcelSheet data={year56} name={'Year 5,6'}>
            <ExcelColumn label="Id_comanda"  value={'id_comanda'}/>
            <ExcelColumn label="Id_fill" value="id"/>
            <ExcelColumn label={props.t('botiga.titolEstat')} value={(col) => col.estat}/>
            <ExcelColumn label={props.t('botiga.producte')} value={(col) => entities.decode(col.nom[props.t('idioma')])}/>
            <ExcelColumn label={props.t('alumnes.classe')} value={(col) => entities.decode(col.classe)}/>
            <ExcelColumn label={props.t('generic.alumne')} value={(col) => entities.decode(col.alumne_complet)}/>
            <ExcelColumn label={props.t('generic.total')} value={(col) => parseFloat(col.total)}/>

            <ExcelColumn label={'Setmana 1: 9h a 13h'} value="w1590"/>
            <ExcelColumn label={'Setmana 1: 9h a 16h (Dinar)'} value="w1591"/>
            <ExcelColumn label={'Setmana 1: bus anada'} value="w1592"/>
            <ExcelColumn label={'Setmana 1: bus tornada'} value="w1593"/>
            <ExcelColumn label={'Setmana 1: bus anada i tornada'} value="w1594"/>

            <ExcelColumn label={'Setmana 2: Sailing 9h-16h'} value="w1596"/>
            <ExcelColumn label={'Setmana 2: bus anada'} value="w1597"/>
            <ExcelColumn label={'Setmana 2: bus tornada'} value="w1598"/>
            <ExcelColumn label={'Setmana 2: bus anada i tornada'} value="w1599"/>

            <ExcelColumn label={'Setmana 3: 9h a 13h'} value="w1600"/>
            <ExcelColumn label={'Setmana 3: 9h a 16h (Dinar)'} value="w1601"/>
            <ExcelColumn label={'Setmana 3: bus anada'} value="w1602"/>
            <ExcelColumn label={'Setmana 3: bus tornada'} value="w1603"/>
            <ExcelColumn label={'Setmana 3: bus anada i tornada'} value="w1604"/>

            <ExcelColumn label={'Setmana 4: 9h a 13h'} value="w1605"/>
            <ExcelColumn label={'Setmana 4: 9h a 16h (Dinar)'} value="w1606"/>
            <ExcelColumn label={'Setmana 4: bus anada'} value="w1607"/>
            <ExcelColumn label={'Setmana 4: bus tornada'} value="w1608"/>
            <ExcelColumn label={'Setmana 4: bus anada i tornada'} value="w1609"/>

            <ExcelColumn label={'Setmana 5: 9h a 13h'} value="w1610"/>
            <ExcelColumn label={'Setmana 5: 9h-16h (dinar)'} value="w1611"/>
            <ExcelColumn label={'Setmana 5: bus anada'} value="w1612"/>
            <ExcelColumn label={'Setmana 5: bus tornada'} value="w1613"/>
            <ExcelColumn label={'Setmana 5: bus anada i tornada'} value="w1614"/>

            <ExcelColumn label={props.t('generic.created_at')} value={(col) => moment(col.creat).tz('Europe/Madrid').format('DD/MM/YYYY')}/>

            {/* Dades alumne */}
            <ExcelColumn label="CatSalut" value={(col) => entities.decode(col.catsalut)}/>
            <ExcelColumn label="Alergia" value={(col) => entities.decode(col.alergia)}/>
            <ExcelColumn label="Intolerancia" value={(col) => entities.decode(col.intolerancia)}/>
            <ExcelColumn label="Malalties" value={(col) => entities.decode(col.malalties)}/>
            <ExcelColumn label="Medicacio" value={(col) => entities.decode(col.medicacio)}/>
            <ExcelColumn label="Nascut" value={(col) => moment(col.naixement).tz('Europe/Madrid').format('DD/MM/YYYY')}/>
            {/* Dades Pare1 */}
            <ExcelColumn label="Pare1 Nom" value={(col) => entities.decode(col.pare.nom)}/>
            <ExcelColumn label="Cognoms" value={(col) => entities.decode(col.pare.cognoms)}/>
            <ExcelColumn label="Tel1" value={(col) => entities.decode(col.pare.tel1)}/>
            <ExcelColumn label="Tel2" value={(col) => entities.decode(col.pare.tel2)}/>
            <ExcelColumn label="email" value={(col) => entities.decode(col.pare.email)}/>
            <ExcelColumn label="Adress" value={(col) => entities.decode(col.pare.adress)}/>
            {/* Dades Pare2 */}
            <ExcelColumn label="Pare2 Nom" value={(col) => entities.decode(col.pare2.nom)}/>
            <ExcelColumn label="Cognoms" value={(col) => entities.decode(col.pare2.cognoms)}/>
            <ExcelColumn label="Tel1" value={(col) => entities.decode(col.pare2.tel1)}/>
            <ExcelColumn label="Tel2" value={(col) => entities.decode(col.pare2.tel2)}/>
          </ExcelSheet>
          <ExcelSheet data={year78} name={'Year 7,8'}>
            <ExcelColumn label="Id_comanda"  value={'id_comanda'}/>
            <ExcelColumn label="Id_fill" value="id"/>
            <ExcelColumn label={props.t('botiga.titolEstat')} value={(col) => col.estat}/>            
            <ExcelColumn label={props.t('botiga.producte')} value={(col) => entities.decode(col.nom[props.t('idioma')])}/>
            <ExcelColumn label={props.t('alumnes.classe')} value={(col) => entities.decode(col.classe)}/>
            <ExcelColumn label={props.t('generic.alumne')} value={(col) => entities.decode(col.alumne_complet)}/>
            <ExcelColumn label={props.t('generic.total')} value={(col) => parseFloat(col.total)}/>

            <ExcelColumn label={'Setmana 1: Trekking 9h a 16h (Dinar)'} value="w1622"/>
            <ExcelColumn label={'Setmana 1: bus anada'} value="w1623"/>
            <ExcelColumn label={'Setmana 1: bus tornada'} value="w1624"/>
            <ExcelColumn label={'Setmana 1: bus anada i tornada'} value="w1625"/>

            <ExcelColumn label={'Setmana 2: Sailing 9h-16h'} value="w1627"/>
            <ExcelColumn label={'Setmana 2: bus anada'} value="w1628"/>
            <ExcelColumn label={'Setmana 2: bus tornada'} value="w1629"/>
            <ExcelColumn label={'Setmana 2: bus anada i tornada'} value="w1630"/>

            <ExcelColumn label={'Setmana 3: 9h a 13h'} value="w1631"/>
            <ExcelColumn label={'Setmana 3: 9h a 16h (Dinar)'} value="w1632"/>
            <ExcelColumn label={'Setmana 3: bus anada'} value="w1633"/>
            <ExcelColumn label={'Setmana 3: bus tornada'} value="w1634"/>
            <ExcelColumn label={'Setmana 3: bus anada i tornada'} value="w1635"/>

            <ExcelColumn label={'Setmana 4: 9h a 13h'} value="w1636"/>
            <ExcelColumn label={'Setmana 4: 9h a 16h (Dinar)'} value="w1637"/>
            <ExcelColumn label={'Setmana 4: bus anada'} value="w1638"/>
            <ExcelColumn label={'Setmana 4: bus tornada'} value="w1639"/>
            <ExcelColumn label={'Setmana 4: bus anada i tornada'} value="w1640"/>

            <ExcelColumn label={'Setmana 5: 9h a 13h'} value="w1641"/>
            <ExcelColumn label={'Setmana 5: 9h-16h (dinar)'} value="w1642"/>
            <ExcelColumn label={'Setmana 5: bus anada'} value="w1643"/>
            <ExcelColumn label={'Setmana 5: bus tornada'} value="w1644"/>
            <ExcelColumn label={'Setmana 5: bus anada i tornada'} value="w1645"/>

            <ExcelColumn label={props.t('generic.created_at')} value={(col) => moment(col.creat).tz('Europe/Madrid').format('DD/MM/YYYY')}/>

            {/* Dades alumne */}
            <ExcelColumn label="CatSalut" value={(col) => entities.decode(col.catsalut)}/>
            <ExcelColumn label="Alergia" value={(col) => entities.decode(col.alergia)}/>
            <ExcelColumn label="Intolerancia" value={(col) => entities.decode(col.intolerancia)}/>
            <ExcelColumn label="Malalties" value={(col) => entities.decode(col.malalties)}/>
            <ExcelColumn label="Medicacio" value={(col) => entities.decode(col.medicacio)}/>
            <ExcelColumn label="Nascut" value={(col) => moment(col.naixement).tz('Europe/Madrid').format('DD/MM/YYYY')}/>
            {/* Dades Pare1 */}
            <ExcelColumn label="Pare1 Nom" value={(col) => entities.decode(col.pare.nom)}/>
            <ExcelColumn label="Cognoms" value={(col) => entities.decode(col.pare.cognoms)}/>
            <ExcelColumn label="Tel1" value={(col) => entities.decode(col.pare.tel1)}/>
            <ExcelColumn label="Tel2" value={(col) => entities.decode(col.pare.tel2)}/>
            <ExcelColumn label="email" value={(col) => entities.decode(col.pare.email)}/>
            <ExcelColumn label="Adress" value={(col) => entities.decode(col.pare.adress)}/>
            {/* Dades Pare2 */}
            <ExcelColumn label="Pare2 Nom" value={(col) => entities.decode(col.pare2.nom)}/>
            <ExcelColumn label="Cognoms" value={(col) => entities.decode(col.pare2.cognoms)}/>
            <ExcelColumn label="Tel1" value={(col) => entities.decode(col.pare2.tel1)}/>
            <ExcelColumn label="Tel2" value={(col) => entities.decode(col.pare2.tel2)}/>
          </ExcelSheet>
      </ExcelFile>
  );

}

export default translate(ComandesExcursionsExportaExcel);
