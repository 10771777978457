import React, { useState, useEffect } from 'react';
import { translate } from 'react-multi-lang';
import { SyncOutlined, LeftOutlined, AlertFilled, CloseOutlined, PauseOutlined, MinusOutlined } from '@ant-design/icons';

import {Form, Row, Col, Button, Input, InputNumber, DatePicker, Select, Divider, Switch, Space, message } from 'antd';
import moment from 'moment';
import 'moment/locale/ca';
import 'moment/locale/es';

import locale_ca from 'antd/es/date-picker/locale/ca_ES';
import locale_en from 'antd/es/date-picker/locale/en_GB';
import locale_es from 'antd/es/date-picker/locale/es_ES';

import { AllHtmlEntities } from 'html-entities';

import { Api, HtmlStrings } from '../../../../helpers';

const entities = new AllHtmlEntities();

function ComandesExcursions(props){

  const [id_curs_escolar, setId_curs_escolar] = useState(props.id_curs_escolar);
  const [id, setId] = useState(props.item.id);
  const [enviar_email, setEnviar_email] = useState(false);
  const [estat, setEstat] = useState(props.item.estat);
  const [id_pare, setId_pare] = useState(props.item.id_pare);
  const [id_alumne, setId_alumne] = useState(props.item.id_alumne);
  const [id_producte, setId_producte] = useState(props.item.id_producte);
  //const [id_talla, setId_talla] = useState(0);
  const [id_propietat, setId_propietat] = useState(props.item.id_propietat);
  const [id_tipus_propietats, setId_tipus_propietats] = useState(props.item.id_tipus_propietats);
  const [nom_ca, setNom_ca] = useState(props.item.nom_ca);
  const [nom_en, setNom_en] = useState(props.item.nom_en);
  const [nom_es, setNom_es] = useState(props.item.nom_es);
  const [nom, setNom] = useState(props.item.nom);
  const [subtotal, setSubtotal] = useState(props.item.subtotal);
  const [descompte, setDescompte] = useState(props.item.descompte);
  const [descompte_x_unitats, setDescompte_x_unitats] = useState(props.item.descompte_x_unitats);
  const [total, setTotal] = useState(props.item.total);
  const [facturat, setFacturat] = useState(props.item.facturat);
  const [observacions, setObservacions] = useState(props.item.observacions);
  const [fcrea, setFcrea ] = useState(props.item.fcrea ? props.item.fcrea : moment.utc(new Date()));
  const [created_by, setCreated_by ] = useState(props.item.created_by);
  const [preu, setPreu ] = useState(props.item.preu);
  const [unitats, setUnitats ] = useState(1);
  const [talla, setTalla] = useState(props.item.talla);
  const [id_classe, setId_classe] = useState(props.item.id_classe);
  const [id_familia, setId_familia] = useState(props.item.id_familia);
  const [alumne_complet, setAlumne_complet ] = useState(props.item.alumne_complet);
  const [alumne_nom, setAlumne_nom ] = useState(props.item.alumne_nom);
  const [alumne_cognom1, setAlumne_cognom1 ] = useState(props.item.alumne_cognom1);
  const [alumne_cognom2, setAlumne_cognom2 ] = useState(props.item.alumne_cognom2);
  const [pare_nom, setPare_nom ] = useState(props.item.pare_nom);
  const [pare_cognom1, setPare_cognom1 ] = useState(props.item.pare_cognom1);
  const [pare_cognom2, setPare_cognom2 ] = useState(props.item.pare_cognom2);
  const [creat, setCreat ] = useState(props.item.creat ? props.item.creat : moment.utc(new Date()));

  const [familiesOptions, setFamiliesOptions] = useState([]);
  const [paresOptions, setParesOptions] = useState([]);
  const [alumnesOptions, setAlumnesOptions] = useState([]);
  const [productesOptions, setProductesOptions] = useState([]);
  const [productesLlistaIndexada, setProductesLlistaIndexada] = useState([]);
  const [productesPropietatsOptions, setProductesPropietatsOptions] = useState([]);
  const [productesPropietatsLlistaIndexada, setProductesPropietatsLlistaIndexada] = useState([]);
  const [estatsOptions, setEstatsOptions] = useState([])

  const [guardant, setGuardant] = useState(false);
  const [errorId_Familia, setErrorId_Familia] = useState('');
  const [errorId_FamiliaText, setErrorId_FamiliaText] = useState('');
  const [errorId_Pare, setErrorId_Pare] = useState('');
  const [errorId_PareText, setErrorId_PareText] = useState('');
  const [errorId_Alumne, setErrorId_Alumne] = useState('');
  const [errorId_AlumneText, setErrorId_AlumneText] = useState('');
  const [errorId_producte, setErrorId_producte] = useState('');
  const [errorId_producteText, setErrorId_producteText] = useState('');
  const [errorId_talla, setErrorId_talla] = useState('');
  const [errorId_tallaText, setErrorId_tallaText] = useState('');

  const [errorFcrea, setErrorFcrea] = useState('');

  const [nou, setNou] = useState(props.item.id===0);

  useEffect(() => {
    setId_curs_escolar(props.id_curs_escolar);
  },[props.id_curs_escolar]);

  useEffect(() => {
    if(id_familia > 0){
      buscarDadesPares();
      buscarDadesFills();
    }
    if(id_alumne > 0) buscarDadesProductes();
    buscarDadesFamilia();
    muntarSelectEstat();
  },[]);

  useEffect(() => {
    buscarDadesPares();
    buscarDadesFills();
  },[id_familia]);

  useEffect(() => {
    buscarDadesProductes();
  },[id_alumne]);

  useEffect(() => {
    calcularSubtotal(preu, unitats);
  },[preu, unitats]);

  useEffect(() => {
    calcularTotal(subtotal, descompte);
  },[subtotal, descompte]);


  const buscarDadesFamilia = async () => {
    const fam = await Api.get('/families');
    if(fam.result.success===1){
      const llista_fam = fam.data.families.map((item) =>{
            return(
              <Select.Option key={item.id} value={item.id}>
                {item.id + ' - ' + entities.decode(item.cognom1) + ' ' + entities.decode(item.cognom2)}
              </Select.Option>
            );
      });
      setFamiliesOptions(llista_fam);
    }
  }
  const buscarDadesPares = async () => {
    if(id_familia > 0){
      const pares = await Api.get(`/pares/familia/${id_familia}`);
      if(pares.result.success===1){
        const llista_pares = pares.data.map((item) =>{
              return(
                <Select.Option key={item.id} value={item.id}>
                  {entities.decode(item.nom) + ' ' + entities.decode(item.cognom1) + ' ' + entities.decode(item.cognom2)}
                </Select.Option>
              );
        });
        setParesOptions(llista_pares);
      }
    }
  }
  const buscarDadesFills = async () => {
    if(id_familia > 0){
      const fills = await Api.get(`/alumnes/familia/${id_familia}`);
      if(fills.result.success===1){
        const llista_fills =fills.data.alumnes.map((item) =>{
              return(
                <Select.Option key={item.id} value={item.id}>
                  {entities.decode(item.nom) + ' ' + entities.decode(item.cognom1) + ' ' + entities.decode(item.cognom2)}
                </Select.Option>
              );
        });
        setAlumnesOptions(llista_fills);
      }
    }
  }

  const buscarDadesProductes = async () => {
    if(id_alumne > 0 && id_familia > 0){
      const prods = await Api.get(`/botiga/admin/categoria/2/alumne/${id_alumne}/${id_familia}`);
      if(prods.result.success===1){

        const productes_curs_escolar = prods.data.productes.filter((element, index, arr) => element.id_curs_escolar === id_curs_escolar);
        productes_curs_escolar.sort((a,b)=> a.id_producte - b.id_producte);
        const llista_prods = productes_curs_escolar.map((item) =>{
              return(
                <Select.Option key={item.id_producte} value={item.id_producte}>
                  <span>{entities.decode(item.nom[props.t('idioma')])}</span>
                </Select.Option>
              );
        });
        setProductesOptions(llista_prods);

        const llistaIndexada = prods.data.productes.reduce((acumulat, item) => ({
          ...acumulat,
          [item.id_producte]: item,
        }),{});
        setProductesLlistaIndexada(llistaIndexada);
      }
    }
  }

  const muntarSelectEstat = () =>{

    let estats = [
      'Pendent',
      'SenseStock',
      'EnPreparacio',
      'Preparat',
      'Entregat',
      'Facturat',
      'Abonat',
      'Previsio',
      'Anulat'
    ]

    const llista_estats = estats.map((valor) =>{
      return(
        <Select.Option key={valor} value={valor}>
          <span><AlertFilled style={{color: `${props.t(`botiga.estatColor.${valor}`)}`}}/>&nbsp;{props.t(`botiga.estat.${valor}`)}</span>
        </Select.Option>
      );
    });
    setEstatsOptions(llista_estats);

  }

  const calcularTotal = (sub, desc) =>{
    if(sub !== 0.0){
      if(desc!==0.0){
        const tot =  sub - (sub * desc / 100);
        setTotal(tot);
      }else{
        setTotal(sub);
      }
    }else{
      setTotal(0);
    }
  }

  const calcularSubtotal = (_preu, _unitats) => {
    if(_preu !== 0.0 && _unitats!==0.0){
      setSubtotal(_preu * _unitats);
    }else{
      setSubtotal(0);
    }
  }

  const onChangePreu = (_preu) =>{
    setPreu(_preu);
  }

  const onChangeUnitats = (_unitats) =>{
    setUnitats(_unitats);
  }

  const onChangeSubtotal = (sub) =>{
    setSubtotal(sub);
  }

  const onChangeDescompte = (desc) =>{
    setDescompte(desc);
  }

  const changeFcrea = (date, dateString) => {
    if(date){
      setFcrea(date.toISOString());
      setCreat(date.toISOString());
      setErrorFcrea('');
    }else{
      setFcrea(null);
    }
  }

  const triaFamilia = (value) =>{
    setId_familia(value);
    if(value>0){
      setErrorId_Familia('');
      setErrorId_FamiliaText('');
      setId_pare(0);
      setId_alumne(0);
    }
  }
  const triaPare = (value) =>{
    setId_pare(value);
    if(value>0){
      setErrorId_Pare('');
      setErrorId_PareText('');
    }
  }
  const triaAlumne = (value) =>{
    setId_alumne(value);
    setId_producte(0);
    if(value>0){
      setErrorId_Alumne('');
      setErrorId_AlumneText('');
    }
  }
  const triaProducte = (value) =>{
    setId_producte(value);

    if(value>0){
      const producte = productesLlistaIndexada[value];
      setPreu(producte.preu);
      setErrorId_producte('');
      setErrorId_producteText('');
    }
  }

  const submitForm = async (values) => {

    let correcte = true;

    if(id_familia===0){
      setErrorId_Familia('error');
      setErrorId_FamiliaText(props.t('generic.tria'));
      correcte = false;
    }
    if(id_pare===0){
      setErrorId_Pare('error');
      setErrorId_PareText(props.t('generic.tria'));
      correcte = false;
    }
    if(id_alumne===0){
      setErrorId_Alumne('error');
      setErrorId_AlumneText(props.t('generic.tria'));
      correcte = false;
    }
    if(id_producte===0){
      setErrorId_producte('error');
      setErrorId_producteText(props.t('generic.tria'));
      correcte = false;
    }
    if(!fcrea){
      setErrorFcrea('error');
      correcte = false;
    }

    if(!correcte){
      return false;
    }
    setGuardant(true);
    let dades = {
      id_curs_escolar,
      id,
      estat,
      id_pare,
      id_alumne,
      id_producte,
      subtotal,
      descompte,
      total,
      fcrea: moment.utc(fcrea),
      observacions,
      preu,
      enviar_email
    }

    let method = nou?'POST':'PUT';
    let url_api = nou? '/comandes/excursio' : '/comandes/excursio/'+id;

    try{
      //console.log(dades)
      const resposta = await Api.myFetch(url_api, method, JSON.stringify(dades));
      if(resposta.result.success === 1){
        setGuardant(false);
        message.success(props.t('generic.OK_saved'), 5);
        props.callback();
      }else{
        setGuardant(false);
        console.error('Error: submit ComandesExtraescolarsEdit: ',resposta);
        message.config({top: 50});
        message.open({content: resposta.data.message, duration:10});
      }
    }catch(error){
      setGuardant(false);
      message.config({top: 50});
      message.open({content: "Error saving", duration:10});
      console.error('Error: ComandesExtraescolarsEdit: ', error);
    }

  }

  let localeDatePicker;
  switch (props.t('idioma')) {
    case 'ca':
      localeDatePicker = locale_ca;
      break;
    case 'en':
      localeDatePicker = locale_en;
      break;
    case 'es':
      localeDatePicker = locale_es;
      break;
    default:
      localeDatePicker = locale_ca;
  }
  const dateFormat = 'DD/MM/YYYY HH:mm';

  return(
    <Form onFinish={submitForm}
      layout="vertical"
      autoComplete="off"
    >
    <Row gutter={10}>
      <Col span={6}>
        <Form.Item label={props.t('botiga.titolEstat')}>
          <Select defaultValue={estat} onChange={(value) => setEstat(value)} disabled={['Facturat', 'Abonat'].indexOf(estat) == -1 ? false : true}>
            {estatsOptions}
          </Select>
        </Form.Item>
      </Col>
      <Col>
        <Form.Item label={props.t('generic.created_at')} required={true} validateStatus={errorFcrea}>
          <DatePicker
            disabled={['Facturat', 'Abonat'].indexOf(estat) == -1 ? false : true}
            onChange={changeFcrea}
            value={moment(creat).tz('Europe/Madrid')}
            showTime={{ format: 'HH:mm' }}
            format={dateFormat}
            locale={localeDatePicker}
            />
        </Form.Item>
      </Col>
    </Row>
    <Form.Item label={props.t('alumnes.familia')} required={true} validateStatus={errorId_Familia} help={errorId_FamiliaText}>
      <Select disabled={['Facturat', 'Abonat'].indexOf(estat) == -1 ? false : true}
        defaultValue={id_familia===0 ? '' : id_familia} onChange={(value) => triaFamilia(value)}
        showSearch
        filterOption={(input, option) =>
          HtmlStrings.normalize(option.children).includes(HtmlStrings.normalize(input))
        }
      >
        {familiesOptions}
      </Select>
    </Form.Item>

  {id_familia <=0 ? null :
    <>
      <Form.Item label={props.t('generic.responsable')} required={true} validateStatus={errorId_Pare} help={errorId_PareText}>
        <Select
          disabled={['Facturat', 'Abonat'].indexOf(estat) == -1 ? false : true}
          value={id_pare===0 ? '' : id_pare} onChange={(value) => triaPare(value)}
          showSearch
          filterOption={(input, option) =>
            HtmlStrings.normalize(option.children).includes(HtmlStrings.normalize(input))
          }
        >
          {paresOptions}
        </Select>
      </Form.Item>
      <Form.Item label={props.t('generic.alumne')} required={true} validateStatus={errorId_Alumne} help={errorId_AlumneText}>
        <Select
          disabled={['Facturat', 'Abonat'].indexOf(estat) == -1 ? false : true}
          value={id_alumne===0 ? '' : id_alumne} onChange={(value) => triaAlumne(value)}
          showSearch
          filterOption={(input, option) =>
            HtmlStrings.normalize(option.children).includes(HtmlStrings.normalize(input))
          }
        >
          {alumnesOptions}
        </Select>
      </Form.Item>
    </>
  }

<Row gutter={10}>

  {productesOptions.length == 0 ? null :
    <Col span={12}>
    <Form.Item label={props.t('menu.ComandesExcursions')} required={true} validateStatus={errorId_producte} help={errorId_producteText}>
      <Select
        disabled={['Facturat', 'Abonat'].indexOf(estat) == -1 ? false : true}
        value={id_producte===0 ? '' : id_producte}
        onChange={(value) => triaProducte(value)}
      >
        {productesOptions}
      </Select>
    </Form.Item>
    </Col>
  }
</Row>


    <Row align="middle" justify="space-around">
      <Col>
        <Form.Item label={props.t('generic.preu')}>
            <InputNumber name="preu" onChange={onChangePreu} value={preu}
            disabled={['Facturat', 'Abonat'].indexOf(estat) == -1 ? false : true}
            step={1.0}
            formatter={value => `${value}€`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
            parser={value => value.replace(/€\s?|(,*)/g, '')}
            />
        </Form.Item>
      </Col>
      <Col style={{paddingTop: 8}}>
        <CloseOutlined />
      </Col>
      <Col>
        <Form.Item label={props.t('generic.unitats')}>
            <InputNumber name="unitats" onChange={onChangeUnitats} value={unitats} precision={0} min={0} disabled/>
        </Form.Item>
      </Col>
      <Col style={{paddingTop: 8}}>
        <PauseOutlined rotate={90}/>
      </Col>

      <Col>
        <Form.Item label={props.t('generic.subtotal')}>
            <InputNumber name="subtotal" onChange={onChangeSubtotal} value={subtotal} disabled
            step={1.0}
            formatter={value => `${value}€`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
            parser={value => value.replace(/€\s?|(,*)/g, '')}
            />
        </Form.Item>
      </Col>
      <Col style={{paddingTop: 8}}>
        <MinusOutlined />
      </Col>
      <Col>
        <Form.Item label={props.t('generic.descompte')}>
            <InputNumber name="descompte" onChange={onChangeDescompte} value={descompte}
            disabled={['Facturat', 'Abonat'].indexOf(estat) == -1 ? false : true}
            min={-100} max={100} step={0.1}
            formatter={value => `${value}%`}
            parser={value => value.replace('%', '')}
            />
        </Form.Item>
      </Col>
      <Col style={{paddingTop: 8}}>
        <PauseOutlined rotate={90}/>
      </Col>
      <Col>
        <Form.Item label={props.t('generic.total')}>
            <InputNumber name="total" value={total} disabled
            formatter={value => `${value}€`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
            parser={value => value.replace(/€\s?|(,*)/g, '')}
            />
        </Form.Item>
      </Col>
    </Row>
    <Row>
      <Space>
        <span>{props.t('generic.enviarEmail')}</span>
        <Switch onChange={setEnviar_email} checkedChildren={props.t('generic.si')} unCheckedChildren={props.t('generic.no')}/>
      </Space>
    </Row>
    <Row type="flex" justify="start" style={{marginTop: 25, marginBottom: 25}}>
      <Col>
        <Button onClick={props.callback}>{props.t('generic.cancel')}</Button>
      </Col>
      <Col style={{marginLeft:'10px'}}>
        <Button type="primary" htmlType="submit" loading={guardant}>{props.t('generic.save')}</Button>
      </Col>
    </Row>
    </Form>
  );

}
export default translate(ComandesExcursions);
