import React from 'react';
import { translate } from 'react-multi-lang';
import { Table, Input, Button, Space, Row, Col, Tooltip, Popconfirm } from 'antd';
import { SearchOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { AllHtmlEntities } from 'html-entities';

import Moment from 'react-moment';
import moment from 'moment';
import 'moment/locale/ca';


import ContainerPagina from '../../../components/ContainerPagina';
import TitolPagina from '../../../components/TitolPagina';
import LlistaEditable from '../../../components/LlistaEditableModalNouEdit';
import Edit from '../../../components/Formularis/perfils/admin/Alumnes';
import { Api, InitDades, HtmlStrings } from '../../../helpers';

const { Column } = Table;
const entities = new AllHtmlEntities();
moment.locale('es');

class Alumnes extends React.Component{

  constructor(props){
    super(props);
    this.state = {
    filtresClasses: [],
    searchText: '',
    searchedColumn: '',
  };
  }

  async componentDidMount(){
    const cla = await Api.get('/classes');
    if(cla.result.success!==1){return [];}

    const filtreCl = cla.data.classes.map((item)=>{
      return {text: item.nom, value: item.id}
    });
    this.setState({filtresClasses: filtreCl});
  }

  buscarDades = async () => {
/*
    const cla = await Api.get('/classes');
    if(cla.result.success!==1){return [];}

    const classes = cla.data.classes.reduce((acumulat, item) => ({
      ...acumulat,
      [item.id]: item.nom,
    }),{});
*/
    const alum = await Api.get('/alumnes');
    if(alum.result.success===1){
      /*
      const alumnes = alum.data.alumnes.map((item)=>{
        item.classe = classes[item.id_classe];
        return item;
      });
      return alumnes;
      */

      const alumnes = alum.data.alumnes.map((item)=>{
        //item.alumne_nom = entities.decode(item.nom)+' '+entities.decode(item.cognom1);
        item.alumne_nom = this.props.t('menu.Alumnes'); //TITOL PER A POPUP
        return item;
      });

      alumnes.sort((a, b) =>  b.id - a.id)

      return alumnes;

      //return alum.data.alumnes;
    }
  }
  marcarEliminat = async (id) => {
    //const fam = await Api.myFetch('/alumnes/'+id, 'DELETE');
    const fam = await Api.myFetch(`/alumnes/baixa/${id}`, 'DELETE');
    if(fam.result.success===1){

    }
  }

  getColumnSearchProps = (dataIndex, nom) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            this.searchInput = node;
          }}
          placeholder={`${this.props.t('generic.cercar')} ${nom}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            {this.props.t('generic.cercar')}
          </Button>
          <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) =>
      //record[dataIndex] ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : '',
      record[dataIndex] ? this.arreglarTxt4Search(entities.decode(record[dataIndex].toString())).includes(this.arreglarTxt4Search(value)) : '',
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => this.searchInput.select(), 100);
      }
    },
    render: text => (entities.decode(text)),
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = clearFilters => {
    clearFilters();
    this.setState({ searchText: '' });
  };
  arreglarTxt4Search = text =>{
      // Poso en minuscules, normalitzo caracters treure accents i ñ , elimina els caracters diacrítics raros d'un text (ES6)
        return text.trim().toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g,"");
  }

render(){

const { filtresClasses } = this.state;
const filtreEstat = [
  {text: this.props.t('alumnes.estat.actiu'), value: 'actiu'},
  {text: this.props.t('alumnes.estat.reserva'), value: 'reserva'},
  {text: this.props.t('alumnes.estat.baixa'), value: 'baixa'}
];

const columns = [
{
  title:"Id",
  key:"id",
  sorter: {
    compare: (a, b) =>  a.id - b.id,
    multiple: 0
  },
  render: (text,record) => (<span>{record.id}</span>)
},{
  title: this.props.t('alumnes.classe'),
  key:"classe",
  dataIndex: 'classe',
  filters: filtresClasses,
  onFilter: (value, record) => record.id_classe === value,
  render: (text,record) =>(entities.decode(record.classe)),
  sorter: {
    compare: (a, b) => { return entities.decode(a.classe).localeCompare(entities.decode(b.classe))},
    multiple: 0
  },
  //...this.getColumnSearchProps('classe',this.props.t('alumnes.classe'))
},{
  title: this.props.t('alumnes.nom'),
  key:"nom",
  dataIndex: 'nom',
  render: (text,record) =>(entities.decode(record.nom)),
  sorter: {
    compare: (a, b) => HtmlStrings.normalize(entities.decode(a.nom)).localeCompare(HtmlStrings.normalize(entities.decode(b.nom))),
    multiple: 0,
  },
  ...this.getColumnSearchProps('nom',this.props.t('alumnes.nom'))
},{
  title: this.props.t('alumnes.cognom1'),
  key: "cognom1",
  dataIndex: 'cognom1',
  render: (text,record) =>(entities.decode(record.cognom1)),
  sorter: {
    compare: (a, b) => HtmlStrings.normalize(entities.decode(a.cognom1)).localeCompare(HtmlStrings.normalize(entities.decode(b.cognom1))),
    multiple: 0,
  },
  ...this.getColumnSearchProps('cognom1',this.props.t('alumnes.cognom1'))
},{
  title:this.props.t('alumnes.cognom2'),
  key:"cognom2",
  dataIndex: 'cognom2',
  render:(text,record) =>(entities.decode(record.cognom2)),
  sorter: {
    compare: (a, b) => HtmlStrings.normalize(entities.decode(a.cognom2)).localeCompare(HtmlStrings.normalize(entities.decode(b.cognom2))),
    multiple: 0,
  },
  ...this.getColumnSearchProps('cognom2',this.props.t('alumnes.cognom2'))
},{
  title:this.props.t('alumnes.naixement'),
  key:"naixement",
  responsive:['md'],
  render:(text,record) => (record.naixement ? <Moment date={new Date(record.naixement)} format="DD/MM/YYYY"/> : "" ),
  sorter: {
    compare: (a, b) => moment(a.naixement).diff(moment(b.naixement)),
    multiple: 0
  }
},{
  title: this.props.t('alumnes.estat.titol'),
  key:"estat",
  dataIndex: 'estat',
  width: '10px',
  filters: filtreEstat,
  defaultFilteredValue: ['actiu'],
  onFilter: (value, record) => record.estat === value,
  render: (text,record) =>(this.props.t(`alumnes.estat.${record.estat}`)),
  sorter: {
    compare: (a, b) => { return a.estat.localeCompare(b.estat)},
    multiple: 0,
  },
}];

  return(
    <ContainerPagina xl={24}>
      <TitolPagina txt={this.props.t('menu.Alumnes')}/>
      <div style={{marginTop:'1rem', marginLeft:'3rem'}}>
        <LlistaEditable
          txt_creats={this.props.t('alumnes.creados')}
          txt_nou={this.props.t('alumnes.nuevo')}
          buscarDades={this.buscarDades}
          edit={Edit}
          add={Edit}
          modal_width={1350}
          delete={this.marcarEliminat}
          itemBuit={InitDades.alumne()}
          columns={columns}
          />

      </div>
    </ContainerPagina>
  );
}
}

export default translate(Alumnes);
