import React, { useState, useEffect } from 'react';
import moment from 'moment';
import 'moment/locale/ca';
import 'moment/locale/es';

import locale_ca from 'antd/es/date-picker/locale/ca_ES';
import locale_en from 'antd/es/date-picker/locale/en_GB';
import locale_es from 'antd/es/date-picker/locale/es_ES';

import { translate } from 'react-multi-lang';
//import { Form } from '@ant-design/compatible';
//import '@ant-design/compatible/assets/index.css';
import { LeftOutlined } from '@ant-design/icons';
import { Form, Row, Col, DatePicker, Switch, Button, Input, InputNumber, Checkbox, message } from 'antd';

import { AllHtmlEntities } from 'html-entities';

import { Api } from '../../../../helpers';
import ImageCropper from '../../../ImageCropper';
import VisorImatges from "../../../visorImatges";
import NomDescripcioIdiomes from '../../../NomDescripcioIdiomes';
import TriaCursos from '../../../TriaCursos';

const entities = new AllHtmlEntities();
const dateFormat = 'DD/MM/YYYY HH:mm';

function Edit(props){

  moment.locale('es');

  const [id_curs_escolar, setId_curs_escolar] = useState(props.id_curs_escolar);

  const id = props.item.id;
  const [ordre, setOrdre] = useState(props.item.ordre);
  const [nom_ca, setNom_ca] = useState(entities.decode(props.item.nom['ca']));
  const [nom_en, setNom_en] = useState(entities.decode(props.item.nom['en']));
  const [nom_es, setNom_es] = useState(entities.decode(props.item.nom['es']));
  const [descripcio_ca, setDescripcio_ca] = useState(entities.decode(props.item.descripcio["ca"]));
  const [descripcio_en, setDescripcio_en] = useState(entities.decode(props.item.descripcio["en"]));
  const [descripcio_es, setDescripcio_es] = useState(entities.decode(props.item.descripcio["es"]));
  const [url_image, setUrl_image] = useState(props.item.url_image);
  const [horari_ca, setHorari_ca] = useState(entities.decode(props.item.horari['ca']));
  const [horari_en, setHorari_en] = useState(entities.decode(props.item.horari['en']));
  const [horari_es, setHorari_es] = useState(entities.decode(props.item.horari['es']));
  const [dilluns, setDilluns] = useState(props.item.dilluns);
  const [dimarts, setDimarts] = useState(props.item.dimarts);
  const [dimecres, setDimecres] = useState(props.item.dimecres);
  const [dijous, setDijous] = useState(props.item.dijous);
  const [divendres, setDivendres] = useState(props.item.divendres);
  const [dissabte, setDissabte] = useState(props.item.dissabte);
  const [diumenge, setDiumenge] = useState(props.item.diumenge);
  const [preu, setPreu] = useState(props.item.preu);
  const [descompte, setDescompte] = useState(props.item.descompte);
  const [mesos_facturacio, setMesos_facturacio] = useState(props.item.mesos_facturacio);
  const [fini, setFini] = useState(props.item.fini);
  const [ffin, setFfin] = useState(props.item.ffin);
  const [cursos, setCursos] = useState(props.item.cursos || []);

  const [noCaduca, setNoCaduca] = useState((fini!==null && ffin!=null) ? false : true);
  const [img_dataUrl, setImg_dataUrl] = useState();

  const [visor, setVisor] = useState({visible: false});

  const [guardant, setGuardant] = useState(false);

  const [errorNom_ca, setErrorNom_ca] = useState('');
  const [errorNom_en, setErrorNom_en] = useState('');
  const [errorNom_es, setErrorNom_es] = useState('');
  const [errorHorari_ca, setErrorHorari_ca] = useState('');
  const [errorHorari_en, setErrorHorari_en] = useState('');
  const [errorHorari_es, setErrorHorari_es] = useState('');
  const [errorTriaCursos, setErrorTriaCursos] = useState('');
  const [errorTriaCursosText, setErrorTriaCursosText] = useState('');
  const [errorMesos_facturacio, setErrorMesos_facturacio] = useState('');
  const [errorMesos_facturacioText, setErrorMesos_facturacioText] = useState('');


  const nou = (props.item.id===0);

  useEffect(() => {
    setId_curs_escolar(props.id_curs_escolar);
  },[props.id_curs_escolar]);

  const calendarChange = (rang_moments, rang_strings) => {
    /*
      setFini(rang_moments[0].toDate());
      setFfin(rang_moments[1].toDate());
      */
      setFini(rang_moments[0].toISOString());
      setFfin(rang_moments[1].toISOString());
    }

  const nomChange_ca = (e) =>{
    const n = e.target.value;
    setNom_ca(n);
    if(n.trim().length>0){ setErrorNom_ca('');}
  }
  const nomChange_en = (e) =>{
    const n = e.target.value;
    setNom_en(n);
    if(n.trim().length>0){ setErrorNom_en('');}
  }
  const nomChange_es = (e) =>{
    const n = e.target.value;
    setNom_es(n);
    if(n.trim().length>0){ setErrorNom_es('');}
  }
  const horariChange_ca = (e) =>{
    const n = e.target.value;
    setHorari_ca(n);
    if(n.trim().length>0){ setErrorHorari_ca('');}
  }
  const horariChange_en = (e) =>{
    const n = e.target.value;
    setHorari_en(n);
    if(n.trim().length>0){ setErrorHorari_en('');}
  }
  const horariChange_es = (e) =>{
    const n = e.target.value;
    setHorari_es(n);
    if(n.trim().length>0){ setErrorHorari_es('');}
  }
  const triaCursosChange = (value) =>{
    setCursos(value);
    if(value.length>0){
      setErrorTriaCursos('');
      setErrorTriaCursosText('');
    }
  }

  const retorn_img_dataUrl = (dataUrl) =>{
    setUrl_image('');
    setImg_dataUrl(dataUrl);
  }


  const submitForm = async (values) => {

      let correcte = true;

      if(nom_ca.trim().length === 0){
        setErrorNom_ca('error');
        correcte = false;
      }
      if(nom_en.trim().length === 0){
        setErrorNom_en('error');
        correcte = false;
      }
      if(nom_es.trim().length === 0){
        setErrorNom_es('error');
        correcte = false;
      }
      if(horari_ca.trim().length === 0){
        setErrorHorari_ca('error');
        correcte = false;
      }
      if(horari_en.trim().length === 0){
        setErrorHorari_en('error');
        correcte = false;
      }
      if(horari_es.trim().length === 0){
        setErrorHorari_es('error');
        correcte = false;
      }
      if(cursos.length === 0){
        setErrorTriaCursos('error');
        setErrorTriaCursosText(props.t('generic.tria_varis'));
        correcte = false;
      }
      if(!mesos_facturacio){
        setErrorMesos_facturacio('error');
        setErrorMesos_facturacioText(props.t('generic.tria_varis'));
        correcte = false;
      }


      if(!correcte){return false}

      setGuardant(true);

      let dades = {
        id_curs_escolar,
        id,
        ordre,
        nom_ca: entities.encode(nom_ca.trim()),
        nom_en: entities.encode(nom_en.trim()),
        nom_es: entities.encode(nom_es.trim()),
        descripcio_ca: entities.encode(descripcio_ca),
        descripcio_en: entities.encode(descripcio_en),
        descripcio_es: entities.encode(descripcio_es),
        url_image: url_image,
        horari_ca: entities.encode(horari_ca.trim()),
        horari_en: entities.encode(horari_en.trim()),
        horari_es: entities.encode(horari_es.trim()),
        dilluns,
        dimarts,
        dimecres,
        dijous,
        divendres,
        dissabte,
        diumenge,
        preu,
        descompte,
        fini: noCaduca === true ? '' : fini,
        ffin: noCaduca === true ? '' : ffin,
        cursos,
        mesos_facturacio
      }

      console.log(JSON.stringify(dades));

      let method = nou?'POST':'PUT';
      let url_api = nou? '/extraescolars' : '/extraescolars/'+id;

      try{
        const resposta = await Api.myFetch(url_api, method, JSON.stringify(dades));
        console.log("Extra form resposta: ", resposta)

        if(resposta.result.success === 1){

          if(img_dataUrl !== undefined){
            const blob = await (await fetch(img_dataUrl)).blob();
            var form_imatges = new FormData();
            form_imatges.append("imgFileField", blob, "image.jpg");
            let resp = await Api.myFetch('/extraescolars/image/'+resposta.data.id, 'POST', form_imatges);
              if(resp.result.success !== 1){
                console.error('Error POST file: Extraescolars edit: ',resp);
                message.config({top: 50});
                message.open({content: resposta.data.message, duration:5});
              }
          }

          setGuardant(false);
          console.log('resposta', resposta);
          message.success(props.t('generic.OK_saved'), 5);
          props.callback();
        }else{
          setGuardant(false);
          console.error('Error: submit Form extraescolars: ',resposta);
          message.config({top: 50});
          message.open({content: resposta.data.message, duration:10});
        }
      }catch(error){
        setGuardant(false);
        message.config({top: 50});
        message.open({content: "Error saving", duration:10});
        console.error('Error: extraescolars guardar: ', error);
      }

  }

  //const aspectRatio = ubicacio.aspectRatio? eval(ubicacio.aspectRatio) : 16/9;
  const aspectRatio = 16/9;
  let defaultDataRange = '';

  if(nou){
    /*
    defaultDataRange=[
      moment(new Date(), dateFormat),
      moment(new Date(), dateFormat)
    ];
    */
    defaultDataRange=[
      moment.utc(new Date()),
      moment.utc(new Date())
    ];
  }

  if (fini!=null && ffin!=null){
    /*
    defaultDataRange=[
      moment(new Date(fini), dateFormat),
      moment(new Date(ffin), dateFormat)
    ];*/
    defaultDataRange=[
      moment(fini),
      moment(ffin)
    ];
  }

  let localeDatePicker;
  switch (props.t('idioma')) {
    case 'ca':
      localeDatePicker = locale_ca;
      break;
    case 'en':
      localeDatePicker = locale_en;
      break;
    case 'es':
      localeDatePicker = locale_es;
      break;
    default:
      localeDatePicker = locale_ca;
  }


  const optionsMesos = [
    { label: props.t('mesos.1'), value: 1 },
    { label: props.t('mesos.2'), value: 2 },
    { label: props.t('mesos.3'), value: 3 },
    { label: props.t('mesos.4'), value: 4 },
    { label: props.t('mesos.5'), value: 5 },
    { label: props.t('mesos.6'), value: 6 },
    { label: props.t('mesos.7'), value: 7 },
    { label: props.t('mesos.8'), value: 8 },
    { label: props.t('mesos.9'), value: 9 },
    { label: props.t('mesos.10'), value: 10 },
    { label: props.t('mesos.11'), value: 11 },
    { label: props.t('mesos.12'), value: 12 },
  ];

  const onChangeMesos = (checkedValues) => {
    //console.log('mesos checkedValues: ', checkedValues);
    setMesos_facturacio(checkedValues);
    if(checkedValues){
      setErrorMesos_facturacio('');
      setErrorMesos_facturacioText('');
    }
  }


return (
<>
  <div style={{marginTop:'-2.8rem', marginLeft:'-2rem'}}>
    <LeftOutlined className="BotoBack" onClick={props.callback}/>
  </div>
  <div style={{padding:'10px 24px 35px 0px'}}>
    <Form onFinish={submitForm}
      layout="horizontal"
      labelCol={{span:2}}
      wrapperCol={{span:22}}
      colon={true}
      autoComplete="off"
    >
    <Form.Item
      label={props.t('transport.ordre')}
      required={true}
    >
        <InputNumber name="ordre" onChange={setOrdre} defaultValue={ordre}/>
    </Form.Item>
      <Form.Item style={{marginBottom: 0}}
        validateStatus={errorTriaCursos}
        help={errorTriaCursosText}
        label={props.t('generic.tria_curs')}
        required={true}
      >
        <TriaCursos defaultValue={cursos} onChange={triaCursosChange}/>
      </Form.Item>

      <Form.Item
        label={props.t('generic.fini_fin')}
        required={true}
      >
        <DatePicker.RangePicker
          defaultValue={defaultDataRange}
          //showTime={{ format: 'HH:mm' }}
          showTime={{ format: 'HH:mm', defaultValue: [moment('00:00:00', 'HH:mm:ss'), moment('23:59:59', 'HH:mm:ss')], }}
          placeholder={[props.t('generic.fini'), props.t('generic.ffin')]}
          format={dateFormat}
          locale={localeDatePicker}
          disabled={noCaduca}
          onChange={calendarChange}
          />
          <Switch checkedChildren={props.t('generic.nocaduca')} unCheckedChildren={props.t('generic.caduca')} onChange={setNoCaduca} defaultChecked={noCaduca} style={{marginLeft: '5px'}}/>
        </Form.Item>

        <NomDescripcioIdiomes
          maxLength={65535}
          errorNom_ca = {errorNom_ca}
          errorNom_en = {errorNom_en}
          errorNom_es = {errorNom_es}
          nomChange_ca = {nomChange_ca}
          nomChange_en = {nomChange_en}
          nomChange_es = {nomChange_es}
          nom_ca = {nom_ca}
          nom_en = {nom_en}
          nom_es = {nom_es}
          visibleDescripcio = {true}
          setDescripcio_ca = {setDescripcio_ca}
          setDescripcio_en = {setDescripcio_en}
          setDescripcio_es = {setDescripcio_es}
          descripcio_ca = {descripcio_ca}
          descripcio_en = {descripcio_en}
          descripcio_es= {descripcio_es}
        />
        <Row style={{marginLeft: '12px'}} gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
          <Col xs={24} sm={24} md={12} lg={12} xl={8} key='horari_ca'>
            <Form.Item
                validateStatus={errorHorari_ca}
                label={props.t('extraescolars.horari')+' CA'}
                required={true}
                labelCol={{span:5}}
                wrapperCol={{span:19}}
              >
                <Input name="horari_ca" placeholder={props.t('extraescolars.horari')} onChange={horariChange_ca} defaultValue={horari_ca}/>
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={8} key='horari_en'>
            <Form.Item
                validateStatus={errorHorari_en}
                label={props.t('extraescolars.horari')+' EN'}
                required={true}
                labelCol={{span:5}}
                wrapperCol={{span:19}}
              >
                <Input name="horari_en" placeholder={props.t('extraescolars.horari')} onChange={horariChange_en} defaultValue={horari_en}/>
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={8} key='horari_es'>
            <Form.Item
                validateStatus={errorHorari_es}
                label={props.t('extraescolars.horari')+' ES'}
                required={true}
                labelCol={{span:5}}
                wrapperCol={{span:19}}
              >
                <Input name="horari_es" placeholder={props.t('extraescolars.horari')} onChange={horariChange_es} defaultValue={horari_es}/>
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col span={3}><div className="ant-form-item-label" style={{marginTop: '-5px'}}><label>{props.t('generic.tria_dies')}</label></div></Col>
          <Col span={3}><Checkbox checked={dilluns} onChange={(e) => setDilluns(e.target.checked)}>{props.t('dies.dilluns')}</Checkbox></Col>
          <Col span={3}><Checkbox checked={dimarts} onChange={(e) => setDimarts(e.target.checked)}>{props.t('dies.dimarts')}</Checkbox></Col>
          <Col span={3}><Checkbox checked={dimecres} onChange={(e) => setDimecres(e.target.checked)}>{props.t('dies.dimecres')}</Checkbox></Col>
          <Col span={3}><Checkbox checked={dijous} onChange={(e) => setDijous(e.target.checked)}>{props.t('dies.dijous')}</Checkbox></Col>
          <Col span={3}><Checkbox checked={divendres} onChange={(e) => setDivendres(e.target.checked)}>{props.t('dies.divendres')}</Checkbox></Col>
          <Col span={3}><Checkbox checked={dissabte} onChange={(e) => setDissabte(e.target.checked)}>{props.t('dies.dissabte')}</Checkbox></Col>
          <Col span={3}><Checkbox checked={diumenge} onChange={(e) => setDiumenge(e.target.checked)}>{props.t('dies.diumenge')}</Checkbox></Col>
        </Row>
        <br/>

        <Form.Item label={props.t('extraescolars.url_image')}>
          { img_dataUrl === undefined
            ? <Input name="url_image" placeholder={props.t('extraescolars.url_image')} onChange={(e) => setUrl_image(e.target.value)} defaultValue={url_image}/>
            : null
          }
          { img_dataUrl === undefined
            ? (!url_image )
              ? null
              : <a onClick={() => setVisor({visible:true, image: url_image+"?"+Number(new Date()), title: props.t('menu.Extraescolars')+': '+nom_ca})}>
                  <img src={url_image+"?"+Number(new Date())} height="201" alt={props.t('extraescolars.url_image')}/>
                </a>
            : <a onClick={() => setVisor({visible:true, image: img_dataUrl, title: props.t('menu.Extraescolars')+': '+nom_ca})}>
                <img src={img_dataUrl} height="200" alt={props.t('extraescolars.url_image')}/>
              </a>
          }
          <ImageCropper
            aspectRatio={aspectRatio}
            style={{height: 400, width: '100%'}}
            guides={true}
            retorna_dataUrl={retorn_img_dataUrl}
          />
        </Form.Item>

            <Form.Item label={props.t('extraescolars.preu')}>
                <InputNumber name="preu" onChange={setPreu} defaultValue={preu}
                min={0.0} step={1.0}
                formatter={value => `${value}€`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                parser={value => value.replace(/€\s?|(,*)/g, '')}
                />
            </Form.Item>
            <Form.Item label={props.t('extraescolars.descompte')}>
                <InputNumber name="descompte" onChange={setDescompte} defaultValue={descompte}
                min={-100} max={100} step={0.1}
                formatter={value => `${value}%`}
                parser={value => value.replace('%', '')}
                />
            </Form.Item>

            <Form layout="vertical">
              <Form.Item label={props.t('generic.mesos_facturacio')+':'} required={true} validateStatus={errorMesos_facturacio} help={errorMesos_facturacioText}>
                <Checkbox.Group options={optionsMesos} defaultValue={mesos_facturacio} onChange={onChangeMesos} />
              </Form.Item>
            </Form>



        <Row type="flex" justify="start">
          <Col>
            <Button onClick={props.callback}>{props.t('generic.back')}</Button>
          </Col>
          <Col style={{marginLeft:'10px'}}>
            <Button type="primary" htmlType="submit" loading={guardant}>{props.t('generic.save')}</Button>
          </Col>
        </Row>
      </Form>
      { !visor.visible
        ? null
        : <VisorImatges image={visor.image} title={visor.title} onClose={() => setVisor({visible:false})} clickOutsideToExit={true}/>
      }
    </div>
  </>
  );
}
export default translate(Edit);
