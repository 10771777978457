import React, { useState, useEffect, useContext } from 'react';
import { translate } from 'react-multi-lang';
import { LeftOutlined,
         SyncOutlined,
         FolderOutlined,
         FolderTwoTone,
         FolderFilled,
         FilePdfTwoTone,
         FileExcelTwoTone,
         FileWordTwoTone,
         FilePptTwoTone,
         FileImageTwoTone,
         FileTextTwoTone,
         FolderOpenFilled,
         PlusOutlined,
         EllipsisOutlined,
         DeleteOutlined } from '@ant-design/icons';
import { Row, Col, Card, Empty, Badge, Tooltip, Modal, Space, Popconfirm } from 'antd';
import { AllHtmlEntities } from 'html-entities';
import { PerfilUsuariContext } from "../../../../context/perfil-usuari";
import { MenuTriatContext } from "../../../../context/menu-triat";
import { Api } from '../../../../helpers';
import AfegirDocument from './DocumentsAfegir';



const entities = new AllHtmlEntities();

function Documents(props) {


  const [perfilUsuari ] = useContext(PerfilUsuariContext);
  const [menuTriat, setMenuTriat] = useContext(MenuTriatContext);

  const [documents, setDocuments] = useState([]);
  const [permis, setPermis] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [refrescarFitxers, setRefrescarFitxers] = useState(0);

  const [modalAfegirVisible, setModalAfegirVisible] = useState(false);
  //const [docTriatVisor, setDocTriatVisor] = useState([]);


  const buscarDades = async () => {
    setIsLoading(true);

    let carp = [];
    let dades = {
      ids_classes: perfilUsuari.dades_profe.ids_classes,
      id_user_profe: perfilUsuari.account.perfil === 'Auxiliar' ? perfilUsuari.account.id_profe : perfilUsuari.account.id
    }

    if(perfilUsuari.nomes_bus){
     carp = await Api.get('/documents/fitxers/usuari/'+perfilUsuari.account.id+'/carpeta/'+props.id_carpeta);
    }else{
      carp = await Api.myFetch('/documents/profe/classes/carpeta/'+props.id_carpeta,'POST', JSON.stringify(dades));
    }
    if(carp.result.success===1){
      //setCarpetes(carp.data.carpetes);
      setPermis(carp.data.carpeta.permis);
      const llista_c = carp.data.carpeta.fitxers.map((item) =>{
        //<Card.Meta avatar={<FolderTwoTone twoToneColor="#F7B334" style={{fontSize: '3rem'}} />} title={entities.decode(item.titol)} description={entities.decode(item.subtitol)} />
        //<Badge dot={item.nou} offset={[0,5]}>

        let icona_fitxer = null;
        switch (item.ext.toLowerCase()) {
          case 'pdf':
            icona_fitxer = <FilePdfTwoTone twoToneColor='red' style={{fontSize: '3rem'}}/>;
            break;
          case 'csv':
          case 'xls':
          case 'xlsx':
            icona_fitxer = <FileExcelTwoTone twoToneColor='#4caf50' style={{fontSize: '3rem'}}/>;
            break;
          case 'doc':
          case 'docx':
          case 'odt':
            icona_fitxer = <FileWordTwoTone twoToneColor='#03a9f4' style={{fontSize: '3rem'}}/>;
            break;
          case 'ppt':
          case 'pptx':
            icona_fitxer = <FilePptTwoTone twoToneColor='#ff7043' style={{fontSize: '3rem'}}/>;
            break;
          case 'txt':
            icona_fitxer = <FileTextTwoTone twoToneColor='#bdbdbd' style={{fontSize: '3rem'}}/>;
            break;
          default:
            icona_fitxer = <FileImageTwoTone twoToneColor='#bdbdbd' style={{fontSize: '3rem'}} />;
        }

        let avatar = null;
        if(item.nou){
          avatar = (
            <Badge count={<span style={{color: '#52c41a', fontWeight :'bold'}}>NEW</span>} offset={[0,-5]}>
              {icona_fitxer}
            </Badge>
          )
        }else{
          avatar = (
            <>
              {icona_fitxer}
            </>
          )
        }

        let card = null;
        if(carp.data.carpeta.permis!=='write'){
          card = (
            <Card hoverable onClick={() => window.open(item.link)}>
              <Card.Meta avatar={avatar}
                title={entities.decode(item.titol)} description={entities.decode(item.subtitol)}
              />
            </Card>
          )
        }else{
          card = (
            <Card
              hoverable
              bodyStyle={{padding: 0}}
              actions ={[
                <Tooltip placement="bottom" title={props.t('generic.delete')}>
                  <Popconfirm placement="bottom" title={props.t('generic.confirm_delete')} okText={props.t('generic.si')} cancelText={props.t('generic.no')} onConfirm={()=>submitDelete(item.id)}>
                      <DeleteOutlined style={{fontSize: '1rem', paddingTop: '1rem'}}/>
                  </Popconfirm>
                </Tooltip>
              ]}
            >
            <Row>
              <Col flex="auto" style={{padding: '24px 0 24px 24px'}}>
                <Card.Meta avatar={avatar}
                  title={entities.decode(item.titol)} description={entities.decode(item.subtitol)}
                  onClick={() => window.open(item.link)}
                />
              </Col>

            </Row>
            </Card>
          )
        }

        //console.log(item)
          return(
            <Col xs={24} sm={12} md={12} lg={8} xl={8} key={item.id}>
              {card}
            </Col>
          );
      });
      if(llista_c.length>0){
        setDocuments(llista_c);
      }else{
        setDocuments(<Empty/>);
      }
    }
    setIsLoading(false);
  }
  useEffect(() => {
    buscarDades();
  },[refrescarFitxers, props.t('idioma')]);



  const respostaModalAfegir = () =>{
    setRefrescarFitxers(refrescarFitxers+1);
    setModalAfegirVisible(false);
  }

  const submitDelete = async (id) => {
    const doc = await Api.myFetch('/documents/usuari/'+perfilUsuari.account.id+'/fitxer/'+id, 'DELETE');

    if(doc.result.success===1){

    }
    setRefrescarFitxers(refrescarFitxers+1);
  }



  return(
    <>

        {isLoading
          ? <SyncOutlined spin title="Loading..." style={{fontSize: '3rem'}} />
          :
            <Row gutter={[16,16]} style={{marginTop:'2rem', marginLeft:'0rem'}}>
              <Col xs={24} sm={12} md={12} lg={8} xl={8}>
                <Card
                  hoverable
                  onClick={() => props.callback()}
                >
                  <Card.Meta avatar={
                    <Space align="end">
                      <EllipsisOutlined style={{fontSize: '3rem', position: 'absolute', bottom:'1rem'}}/>
                      <FolderOpenFilled style={{marginLeft:'2.2rem', fontSize: '3rem', color: '#F7B334'}} />
                    </Space>} title={entities.decode(props.titol)}
                  description={entities.decode(props.subtitol)}
                  />
                </Card>
              </Col>
              {documents}
              {permis!=='write' ? null :
              <Col>
                <Tooltip placement="bottom" title={props.t('generic.add')}>
                  <Card
                    hoverable
                    onClick={() => setModalAfegirVisible(true)}
                  >
                    <Card.Meta avatar={<PlusOutlined style={{fontSize: '3rem', paddingLeft: '15px'}} />}/>
                  </Card>
                </Tooltip>
              </Col>
              }
            </Row>
        }
        <Modal
          key="roba"
          visible={modalAfegirVisible}
          footer={null}
          width={450}
          style={{top:10}}
          bodyStyle={{ overflow: "auto"}}
          destroyOnClose={true}
          forceRender={true}
          maskClosable={true}
          closable={true}
          onCancel={() => setModalAfegirVisible(false)}
        >
          <AfegirDocument id_usuari={perfilUsuari.account.id} id_carpeta={props.id_carpeta} callback={respostaModalAfegir}/>
        </Modal>
    </>
  );

}

export default translate(Documents);
