import React, { useState, useEffect, useContext } from 'react';
import { translate, setLanguage, getLanguage } from 'react-multi-lang';
import { Row, Col, Form, Input, Button, Modal, Typography,Select, Radio, Divider, message } from 'antd';

import { PerfilUsuariContext } from "../../context/perfil-usuari";
import { Api, Encriptar } from '../../helpers';
import AutoritzarContrasenyaSignar from '../AutoritzarContrasenyaSignar';



function PermetreEstudiPsicologic(props){
  const [perfilUsuari, setPerfilUsuari] = useContext(PerfilUsuariContext);

  const [validat, setValidat] = useState(false);

  const [visible, setVisible] = useState(props.visible);
  const [guardant, setGuardant] = useState(false);

  const [acces, setAcces] = useState(null);
  const [consentiment, setConsentiment] = useState(null);
  const [autoritzar, setAutoritzar] = useState(null);

  const [errorAcces, setErrorAcces] = useState('');
  const [errorConsentiment, setErrorConsentiment] = useState('');
  const [errorAutoritzar, setErrorAutoritzar] = useState('');

  const contenidorRef = React.useRef();

  const optionsSINO = [
    { label: 'Sí', value: 1 },
    { label: 'No', value: 0 },
  ];
  const optionsSINO_ES = [
    { label: 'Si', value: 1 },
    { label: 'No', value: 0 },
  ];
  const optionsSINO_EN = [
    { label: 'Yes', value: 1 },
    { label: 'No', value: 0 },
  ];

  const onChangeAcces = ({ target: { value } }: RadioChangeEvent) => {
    console.log('radio1 checked', value);
    setAcces(value);
    if(value!==null){
      setErrorAcces('');
    }
  };

  const onChangeConsentiment = ({ target: { value } }: RadioChangeEvent) => {
    console.log('radio1 checked', value);
    setConsentiment(value);
    if(value!==null){
      setErrorConsentiment('');
    }
  };

  const onChangeAutoritzar = ({ target: { value } }: RadioChangeEvent) => {
    console.log('radio1 checked', value);
    setAutoritzar(value);
    if(value!==null){
      setErrorAutoritzar('');
    }
  };


  const submitForm = async () => {


    if(!validat){
      return false;
    }

    let correcte = true;
    if(acces==null){
      setErrorAcces('error');
      console.error('Acces is null')
      correcte = false;
    }
    if(consentiment==null){
      setErrorConsentiment('error');
      console.error('Consentiment is null')
      correcte = false;
    }
    if(autoritzar==null){
      setErrorAutoritzar('error');
      console.error('Autoritzar is null')
      correcte = false;
    }

    if(!correcte){
      contenidorRef.current.scrollIntoView({
        behavior: "smooth",
      });
      message.config({top: 50});
      message.error({content: props.t('generic.tria'), duration:10});

      contenidorRef.current.scrollIntoView({
        behavior: "smooth",
      });

      return false;
    }

    setGuardant(true);

    let dades = {
      pantalla: "obligar_respondre_permetre_estudi_psicologic",
      resposta: {
        acces,
        consentiment,
        autoritzar
      }

    }
    console.log(JSON.stringify(dades))

    try{
      const resposta = await Api.myFetch('/resposta/obligada/pantalla/'+perfilUsuari.account.id, 'PUT', JSON.stringify(dades));
      if(resposta.result.success === 1){
        setGuardant(false);
        message.success(props.t('generic.OK_saved'), 5);
        props.callback();
      }else{
        setGuardant(false);
        console.error('Error: guardant PermetreEstudiPsicologic: ',resposta);
        message.config({top: 50});
        message.open({content: resposta.data.message, duration:10});
      }
    }catch(error){
      setGuardant(false);
      message.config({top: 50});
      message.open({content: "Error saving", duration:10});
      console.error('Error: PermetreEstudiPsicologic guardar: ', error);
    }
  }

  const changeLang = (lang) =>{
      setLanguage(lang);
      sessionStorage.setItem('Language',lang);
    }

  const taula = {border: '1px solid black', borderCollapse: 'collapse',fontSize: '0.7rem'};
  const tr_td = {border: '1px solid black', borderCollapse: 'collapse', padding: '5px'};

  return(
<>
<div style={{paddingLeft:'24px'}}>
  <span>Language:&nbsp;&nbsp;</span>
  <Select defaultValue={getLanguage()} onChange={changeLang} style={{ width: 110}} size="small">
    <Select.Option value="es">Castellano</Select.Option>
    <Select.Option value="ca">Català</Select.Option>
    <Select.Option value="en">English</Select.Option>
    {/*idiomes*/}
  </Select>
</div>
<br/>
{ props.t('idioma') !== 'ca' ? null :
<>
    <div style={{textAlign: 'center', marginBottom:'2rem'}}>
      <Typography.Title level={3}>Full de consentiment informat</Typography.Title>
    </div>

  <span style={{fontSize: '0.9rem'}}>
    <b>Títol de l’estudi:</b>
    <p>La regulació emocional i factors relacionats: avaluació i intervenció en escolars de diferents etapes evolutives</p>

    <b>Dades de contacte de l’investigador principal:</b>
    <p>Sílvia Severino Fanero<br/>
    <a href="mailto:sseverino@copc.cat">sseverino@copc.cat</a> / <a href="mailto:silvia.severino@estudiants.urv.cat">silvia.severino@estudiants.urv.cat</a><br/>
    C/Sant Auguri, 3<br/>
    43002 Tarragona</p>

    <p><b>Dades de les Directores:</b><br/>
    Dra. Núria Voltas Moreso (Directora)<br/>
    Professora Lectora Serra Húnter<br/>
    <a href="mailto:nuria.voltas@urv.cat">nuria.voltas@urv.cat</a></p>

    <p>Dra. Josefina Canals Sans (Co-Directora)<br/>
    Catedràtica de Psicopatologia del nen i de l'adolescent<br/>
    <a href="mailto:josefa.canals@urv.cat">josefa.canals@urv.cat</a></p>
    <p><b>Grup de recerca:</b> Investigació en Nutrició i Salut Mental (NUTRISAM) - 2021 SGR 00632</p>
    <ul>
      <li>He llegit el full d’informació al participant sobre l’estudi del qual se m’ha entregat una còpia.</li>
      <li>He pogut fer preguntes i resoldre els meus dubtes sobre l’estudi i la meva participació.</li>
      <li>Comprenc la meva participació a l’estudi d’acord amb allò expressat al full d’informació al participant sobre l’estudi i de les respostes a les meves preguntes, així com els riscos i beneficis que comporta.</li>
      <li>Accepto que la meva participació és voluntària i dono lliurement la meva conformitat per participar a l’estudi.</li>
      <li>Conec que em puc retirar en qualsevol moment de la participació a l’estudi sense que això em pugui causar cap perjudici.</li>
      <li>Estic informat sobre el tractament que es realitzarà de les meves dades personals.</li>
      <div ref={contenidorRef}>
      <li>Dono el meu consentiment per a l’accés i utilització de les meves dades en les condicions detallades al full d’informació al participant sobre l’estudi.</li>
      <Radio.Group options={optionsSINO} onChange={onChangeAcces} value={acces} style={errorAcces=='' ? null : {border: '1px solid red'}}/>
      </div>
      <li><sup>1</sup>Dono el meu consentiment per a la difusió de les meves dades personals junt amb la publicació dels resultats de l’estudi.</li>
      <Radio.Group options={optionsSINO} onChange={onChangeConsentiment} value={consentiment}  style={errorConsentiment=='' ? null : {border: '1px solid red'}}/>
      <li>Un cop finalitzada la investigació, és possible que les dades obtingudes siguin d’interès per a altres estudis relacionats. En relació amb això, s’ofereixen les següents opcions:</li>
      <Radio.Group onChange={onChangeAutoritzar} value={autoritzar} style={errorAutoritzar=='' ? null : {border: '1px solid red'}}>
        <Radio value={0}>
          <b>NO autoritzar</b> l’ús de les seves dades en altres<br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; projectes d’investigació relacionats.
        </Radio>
        <Radio value={1}>
          <b>SÍ autoritzar</b> l’ús de les seves dades en altres<br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; projectes d’investigació relacionats.
        </Radio>
      </Radio.Group>
    </ul>
  </span>


  <Divider />
  <span style={{fontSize: '0.7rem'}}><sup>1</sup>Només si en els resultats de l’estudi que es publicaran apareix el nom de les persones o dades que permetin identificar la persona, així com la seva imatge o la seva veu sense tècniques d’anonimització.</span>
  <Divider />


  <table style={taula}>
  <thead>
    <tr style={tr_td}>
      <th colSpan="2">INFORMACIÓ DE PROTECCIÓ DE DADES PERSONALS</th>
    </tr>
  </thead>
  <tbody>
    <tr style={tr_td}>
      <td style={tr_td}>Responsable</td>
      <td style={tr_td}>El responsable del tractament de les seves dades personals és la Universitat Rovira i Virgili amb CIF Q9350003A i amb domicili fiscal al carrer de l’Escorxador, s/n, 43003 de Tarragona.</td>
    </tr>
    <tr style={tr_td}>
      <td style={tr_td}>Finalitat</td>
      <td style={tr_td}>Participar en l’estudi de la Tesis Doctoral en els termes que es descriuen al full d’informació al participant. En el cas que l’estudi prevegi la publicació, difusió i reutilització dels resultats obtinguts incloent dades personals, les dades personals seran utilitzades per a aquesta finalitat sempre que l’interessat hagi atorgat el seu consentiment.</td>
    </tr>
    <tr style={tr_td}>
      <td style={tr_td}>Drets</td>
      <td style={tr_td}>Pot exercir els drets d’accés, rectificació, supressió, portabilitat, limitació o oposició al tractament, mitjançant un escrit adreçat al Registre General de la URV a la mateixa adreça del domicili fiscal o mitjançant la seva presentació al Registre General de la Universitat, presencialment o telemàtica, segons s’indica a <a href="https://seuelectronica.urv.cat/registre.html" target="_blank">https://seuelectronica.urv.cat/registre.html</a>.</td>
    </tr>
    <tr style={tr_td}>
      <td style={tr_td}>Informació addicional</td>
      <td style={tr_td}>Pot consultar informació addicional sobre aquest tractament de dades personals denominat Tesis Doctorals de la URV  i els seus drets al Registre d’Activitats del Tractament de la URV publicat a <a href="https://seuelectronica.urv.cat/rgpd" target="_blank">https://seuelectronica.urv.cat/rgpd</a> on també s’hi pot consultar la Política de Privacitat de la URV. Així mateix, pot consultar aquesta informació al Full d’informació al participant sobre l’estudi.  Addicionalment, pot adreçar als nostres delegats de protecció de dades qualsevol consulta sobre protecció de dades personals a la direcció de correu electrònic del <a href="mailto:dpd@urv.cat">dpd@urv.cat</a>.</td>
    </tr>
    </tbody>
  </table>
</>
}
{ props.t('idioma') !== 'es' ? null :
<>
    <div style={{textAlign: 'center', marginBottom:'2rem'}}>
      <Typography.Title level={3}>Hoja de consentimiento informado</Typography.Title>
    </div>

  <span style={{fontSize: '0.9rem'}}>
    <b>Título del estudio:</b>
    <p>La regulación emocional y factores relacionados: evaluación e intervención en escolares de diferentes etapas evolutivas</p>

    <b>Datos de contacto del investigador principal:</b>
    <p>Sílvia Severino Fanero<br/>
    <a href="mailto:sseverino@copc.cat">sseverino@copc.cat</a> / <a href="mailto:silvia.severino@estudiants.urv.cat">silvia.severino@estudiants.urv.cat</a><br/>
    C/Sant Auguri, 3<br/>
    43002 Tarragona</p>

    <p><b>Datos de las Directoras:</b><br/>
    Dra. Núria Voltas Moreso (Directora)<br/>
    Profesora Lectora Serra Húnter<br/>
    <a href="mailto:nuria.voltas@urv.cat">nuria.voltas@urv.cat</a></p>

    <p>Dra. Josefina Canals Sans (Co-Directora)<br/>
    Catedrática de Psicopatología del niño y del adolescente<br/>
    <a href="mailto:josefa.canals@urv.cat">josefa.canals@urv.cat</a></p>
    <p><b>Grupo de investigación:</b> Investigación en Nutrición y Salud Mental (NUTRISAM) - 2021 SGR 00632</p>
    <ul>
      <li>He leído la hoja de información al participante sobre cuyo estudio se me ha entregado una copia.</li>
      <li>He podido preguntar y resolver mis dudas sobre el estudio y mi participación.</li>
      <li>Comprendo mi participación en el estudio de acuerdo con lo expresado en la hoja de información al participante sobre el estudio y de las respuestas a mis preguntas, así como los riesgos y beneficios que comporta.</li>
      <li>Acepto que mi participación es voluntaria y doy libremente mi conformidad para participar en el estudio.</li>
      <li>Conozco que me puedo retirar en cualquier momento de la participación en el estudio sin que esto pueda causarme ningún perjuicio.</li>
      <li>Estoy informado sobre el tratamiento que se va a realizar de mis datos personales.</li>
      <div ref={contenidorRef}>
      <li>Doy mi consentimiento para el acceso y utilización de mis datos en las condiciones detalladas en la hoja de información al participante sobre el estudio.</li>
      <Radio.Group options={optionsSINO_ES} onChange={onChangeAcces} value={acces} style={errorAcces=='' ? null : {border: '1px solid red'}}/>
      </div>
      <li><sup>1</sup>Doy mi consentimiento para la difusión de mis datos personales junto con la publicación de los resultados del estudio.</li>
      <Radio.Group options={optionsSINO_ES} onChange={onChangeConsentiment} value={consentiment}  style={errorConsentiment=='' ? null : {border: '1px solid red'}}/>
      <li>Una vez finalizada la investigación, es posible que los datos obtenidos sean de interés para otros estudios relacionados. A este respecto, se ofrecen las siguientes opciones:</li>
      <Radio.Group onChange={onChangeAutoritzar} value={autoritzar} style={errorAutoritzar=='' ? null : {border: '1px solid red'}}>
        <Radio value={0}>
          <b>NO autorizar</b> el uso de sus datos en otros<br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; proyectos de investigación relacionados.
        </Radio>
        <Radio value={1}>
          <b>SÍ autoritzar</b> el uso de sus datos en otros<br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; proyectos de investigación relacionados.
        </Radio>
      </Radio.Group>
    </ul>
  </span>


  <Divider />
  <span style={{fontSize: '0.7rem'}}><sup>1</sup>Sólo si en los resultados del estudio que se publicarán aparece el nombre de las personas o datos que permitan identificar a la persona, así como su imagen o su voz sin técnicas de anonimización.</span>
  <Divider />


  <table style={taula}>
  <thead>
    <tr style={tr_td}>
      <th colSpan="2">INFORMACIÓN DE PROTECCIÓN DE DATOS PERSONALES</th>
    </tr>
  </thead>
  <tbody>
    <tr style={tr_td}>
      <td style={tr_td}>Responsable</td>
      <td style={tr_td}>El responsable del tratamiento de sus datos personales es la Universidad Rovira i Virgili con CIF Q9350003A y con domicilio fiscal en la calle Escorxador, s/n, 43003 de Tarragona.</td>
    </tr>
    <tr style={tr_td}>
      <td style={tr_td}>Finalidad</td>
      <td style={tr_td}>Participar en el estudio de la Tesis Doctoral en los términos que se describen en la hoja de información al participante. En caso de que el estudio prevea la publicación, difusión y reutilización de los resultados obtenidos incluyendo datos personales, los datos personales serán utilizados para este fin siempre que el interesado haya otorgado su consentimiento.</td>
    </tr>
    <tr style={tr_td}>
      <td style={tr_td}>Derechos</td>
      <td style={tr_td}>Puede ejercer los derechos de acceso, rectificación, supresión, portabilidad, limitación u oposición al tratamiento, mediante escrito dirigido al Registro General de la URV en la misma dirección del domicilio fiscal o mediante su presentación en el Registro General de la Universidad, presencialmente o telemática, según se indica en <a href="https://seuelectronica.urv.cat/registre.html" target="_blank">https://seuelectronica.urv.cat/registre.html</a>.</td>
    </tr>
    <tr style={tr_td}>
      <td style={tr_td}>Información addicional</td>
      <td style={tr_td}>Puede consultar información adicional sobre este tratamiento de datos personales denominado Tesis Doctorales de la URV y sus derechos en el Registro de Actividades del Tratamiento de la URV publicado en <a href="https://seuelectronica.urv.cat/rgpd" target="_blank">https://seuelectronica.urv.cat/rgpd</a> donde también se puede consultar la Política de Privacidad de la URV. Asimismo, puede consultar esta información en la Hoja de información al participante sobre el estudio. Adicionalmente, puede dirigir a nuestros delegados de protección de datos cualquier consulta sobre protección de datos personales en la dirección de correo electrónico del <a href="mailto:dpd@urv.cat">dpd@urv.cat</a>.</td>
    </tr>
    </tbody>
  </table>
</>
}
{ props.t('idioma') !== 'en' ? null :
<>
    <div style={{textAlign: 'center', marginBottom:'2rem'}}>
      <Typography.Title level={3}>Informed consent form</Typography.Title>
    </div>

  <span style={{fontSize: '0.9rem'}}>
    <b>Title of the study:</b>
    <p>Emotional regulation and related factors: evaluation and intervention in school children of different developmental stages</p>

    <b>Contact details of the principal investigator:</b>
    <p>Sílvia Severino Fanero<br/>
    <a href="mailto:sseverino@copc.cat">sseverino@copc.cat</a> / <a href="mailto:silvia.severino@estudiants.urv.cat">silvia.severino@estudiants.urv.cat</a><br/>
    C/Sant Auguri, 3<br/>
    43002 Tarragona</p>

    <p><b>Data of the Directors:</b><br/>
    Dra. Núria Voltas Moreso (Director)<br/>
    Lecturer Serra Húnter<br/>
    <a href="mailto:nuria.voltas@urv.cat">nuria.voltas@urv.cat</a></p>

    <p>Dra. Josefina Canals Sans (Co-Director)<br/>
    Professor of Child and Adolescent Psychopathology<br/>
    <a href="mailto:josefa.canals@urv.cat">josefa.canals@urv.cat</a></p>
    <p><b>Research group:</b> Nutrition and Mental Health Research (NUTRISAM) - 2021 SGR 00632</p>
    <ul>
      <li>I have read the informative document about the study of which I have been given a copy.</li>
      <li>I was able to ask questions and resolve my doubts about the study and my participation.</li>
      <li>I understand my participation in the study in accordance with what is expressed in the participant informative document about the study and I also understand the answers to my questions, as well as the risks and benefits it entails.</li>
      <li>I accept that my participation is voluntary and I freely give my consent to participate in the study.</li>
      <li>I know that I can withdraw from participating in the study at any time without any further obligation.</li>
      <li>I am informed about the processing that will be carried out of my personal data.</li>
      <div ref={contenidorRef}>
      <li>I give my consent for the access and use of my data under the conditions detailed in the informative document about the study.</li>
      <Radio.Group options={optionsSINO_EN} onChange={onChangeAcces} value={acces} style={errorAcces=='' ? null : {border: '1px solid red'}}/>
      </div>
      <li><sup>1</sup>I give my consent for the dissemination of my personal data together with the publication of the study results.</li>
      <Radio.Group options={optionsSINO_EN} onChange={onChangeConsentiment} value={consentiment}  style={errorConsentiment=='' ? null : {border: '1px solid red'}}/>
      <li>Once the research has been completed, it is possible that the data obtained will be of interest for other related studies. In relation to this, the following options are offered:</li>
      <Radio.Group onChange={onChangeAutoritzar} value={autoritzar} style={errorAutoritzar=='' ? null : {border: '1px solid red'}}>
        <Radio value={0}>
          <b>DO NOT authorize</b> the use of your data in other<br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; related research projects.
        </Radio>
        <Radio value={1}>
          <b>YES authorize</b> the use of your data in other<br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; related research projects.
        </Radio>
      </Radio.Group>
    </ul>
  </span>


  <Divider />
  <span style={{fontSize: '0.7rem'}}><sup>1</sup>Only if the results of the study to be published show the names of the people or any other data that allow the identification of the person, as well as their image or their voice without being anonymous.</span>
  <Divider />


  <table style={taula}>
  <thead>
    <tr style={tr_td}>
      <th colSpan="2">PERSONAL DATA PROTECTION INFORMATION</th>
    </tr>
  </thead>
  <tbody>
    <tr style={tr_td}>
      <td style={tr_td}>Responsible</td>
      <td style={tr_td}>The entity responsible for the treatment of your personal data is the Universitat Rovira i Virgili with CIF Q9350003A and  address at Carrer de l'Escorxador, s/n, 43003 Tarragona.</td>
    </tr>
    <tr style={tr_td}>
      <td style={tr_td}>Purpose</td>
      <td style={tr_td}>To participate in the study of the Doctorate Thesis in the terms described in the informative document. In the event that the study envisages the publication, dissemination and/or reuse of the results obtained, including personal data, the personal data will be used for this purpose as long as the interested party has given their consent.</td>
    </tr>
    <tr style={tr_td}>
      <td style={tr_td}>Rights</td>
      <td style={tr_td}>You can exercise your rights of access, rectification, deletion, portability, limitation or opposition to the treatment, by means of a letter addressed to the General Registry of the URV at the same address as the fiscal address or by presenting it to the General Registry of the University, in person or electronically, as indicated at <a href="https://seuelectronica.urv.cat/registre.html" target="_blank">https://seuelectronica.urv.cat/registre.html</a>.</td>
    </tr>
    <tr style={tr_td}>
      <td style={tr_td}>Additional information</td>
      <td style={tr_td}>You can consult additional information about this treatment of personal data called URV Doctoral Theses and your rights in the URV Processing Activities Register published at <a href="https://seuelectronica.urv.cat/rgpd" target="_blank">https://seuelectronica.urv.cat/rgpd</a> where you can also consult the Privacy Policy of the URV. You can also consult this information in the Informative Document about the study. Additionally, you can address any query about personal data protection to our data protection representatives at the email address <a href="mailto:dpd@urv.cat">dpd@urv.cat</a>.</td>
    </tr>
    </tbody>
  </table>
</>
}

<br/>

    <AutoritzarContrasenyaSignar resposta={setValidat}/>

    <Row type="flex" justify="center" style={{marginTop: 25, marginBottom: 25}}>
      <Button disabled={!validat} type="primary" onClick={() => submitForm()} loading={guardant}>{props.t('generic.save')}</Button>
    </Row>

</>
  );
}


export default translate(PermetreEstudiPsicologic);
