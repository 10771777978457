import React, { useState, useEffect } from 'react';
import { translate } from 'react-multi-lang';
import { SyncOutlined, LeftOutlined } from '@ant-design/icons';

import {Form, Row, Col, Button, Input, InputNumber, DatePicker, Select, Divider, Switch, Space, Card, Radio, message } from 'antd';
import moment from 'moment';
import 'moment/locale/ca';
import 'moment/locale/es';

import locale_ca from 'antd/es/date-picker/locale/ca_ES';
import locale_en from 'antd/es/date-picker/locale/en_GB';
import locale_es from 'antd/es/date-picker/locale/es_ES';

import { AllHtmlEntities } from 'html-entities';

import { Api, HtmlStrings } from '../../../../helpers';

const entities = new AllHtmlEntities();

function ComandesExtraescolarsEdit(props){

  const [id, setId] = useState(props.item.id);
  const [enviar_email, setEnviar_email] = useState(false);
  const [id_familia, setId_familia] = useState(props.item.id_familia);
  const [familia_nom, setFamilia_nom] = useState(props.item.familia_nom);
  const [id_pare, setId_pare] = useState(props.item.id_pare);
  const [id_alumne, setId_alumne] = useState(props.item.id_alumne);
  const [id_extraescolar, setId_extraescolar] = useState(props.item.id_extraescolar);
  const [id_extraescolar2, setId_extraescolar2] = useState(props.item.id_extraescolar);
  const [nom, setNom] = useState(props.item.nom);
  const [horari, setHorari] = useState(props.item.horari);
  const [subtotal, setSubtotal] = useState(props.item.subtotal);
  const [descompte, setDescompte] = useState(props.item.descompte);
  const [total, setTotal] = useState(props.item.total);
  const [date, setDate] = useState(props.item.date);
  const [fcrea, setFcrea ] = useState(props.item.date ? moment(new Date(props.item.date)).format(moment.HTML5_FMT.DATE) : '');
  const [dia_horari_especial, setDia_horari_especial] = useState(props.item.dia_horari_especial);
  const [h_ini, setH_ini] = useState(props.item.h_ini);
  const [m_ini, setM_ini] = useState(props.item.m_ini);
  const [h_fin, setH_fin] = useState(props.item.h_fin);
  const [m_fin, setM_fin] = useState(props.item.m_fin);

  const [guardant, setGuardant] = useState(false);

  const [alumnesOptions, setAlumnesOptions] = useState([]);

  const [extraescolarOptions, setExtraescolarOptions] = useState([]);
  const [extraescolarsLlistaIndexada, setExtraescolarsLlistaIndexada] = useState([]);

  const [horari_especial, setHorari_especial] = useState(false);

  const [errorId_extraescolar, setErrorId_extraescolar] = useState('');
  const [errorId_extraescolarText, setErrorId_extraescolarText] = useState('');
  const [errorId_Alumne, setErrorId_Alumne] = useState('');
  const [errorId_AlumneText, setErrorId_AlumneText] = useState('');
  const [errorH_ini, setErrorH_ini] = useState('');
  const [errorH_fin, setErrorH_fin] = useState('');


  useEffect(() => {
    buscarDadesFills();
  },[id_familia]);


  useEffect(() => {
    setExtraescolarOptions([])
    setExtraescolarsLlistaIndexada([])
    buscarDadesExtraescolar();
  },[id_alumne]);

  useEffect(() => {
    calcularTotal(subtotal, descompte);
  },[subtotal, descompte]);

  const buscarDadesFills = async () => {
    if(id_familia > 0){
      const fills = await Api.get(`/alumnes/familia/${id_familia}`);
      if(fills.result.success===1){
        const llista_fills =fills.data.alumnes.map((item) =>{
              return(
                <Select.Option key={item.id} value={item.id}>
                  {entities.decode(item.nom) + ' ' + entities.decode(item.cognom1) + ' ' + entities.decode(item.cognom2)}
                </Select.Option>
              );
        });
        setAlumnesOptions(llista_fills);
      }
    }
  }

  const buscarDadesExtraescolar = async () => {
    if(id_alumne>0){
      //const ext = await Api.get(`/extraescolars/alumne/${id_alumne}`);
      const ext = await Api.get(`/extraescolars/admin/alumne/${id_alumne}/${props.id_curs_escolar}`);
      if(ext.result.success===1){
        console.log(ext.data.extraescolars)
        const llista_ext = ext.data.extraescolars.map((item) =>{
              const color = (item.caducat && !item.comprat)? {color:'red'} : {};
              return(
                <Select.Option key={item.id_extraescolar} value={item.id_extraescolar} disabled={item.comprat}>
                  <span style={color}><b>{entities.decode(item.nom[props.t('idioma')])}</b> ({entities.decode(item.horari[props.t('idioma')])}){item.caducat ? <span style={{color:'red'}}> - (Caducat)</span> : null }</span>
                </Select.Option>
              );
        });
        setExtraescolarOptions(llista_ext);

        const llistaIndexada = ext.data.extraescolars.reduce((acumulat, item) => ({
          ...acumulat,
          [item.id_extraescolar]: item,
        }),{});
        setExtraescolarsLlistaIndexada(llistaIndexada);

        if(!llistaIndexada[id_extraescolar]){
          setId_extraescolar2(0)
          setHorari_especial(false)
        }else{
          setId_extraescolar2(id_extraescolar)
          setHorari_especial(llistaIndexada[id_extraescolar].horari_especial===1)
        }
      }
    }
  }

  const triaExtraescolar = (value) =>{
    setId_extraescolar(value);
    setId_extraescolar2(value);
    setSubtotal(extraescolarsLlistaIndexada[value].preu);
    setDescompte(extraescolarsLlistaIndexada[value].descompte);
    if(value>0){
      setErrorId_extraescolar('');
      setErrorId_extraescolarText('');
    }
  }
  const triaAlumne = (value) =>{
    setId_alumne(value);
    if(value>0){
      setErrorId_Alumne('');
      setErrorId_AlumneText('');
    }
  }

  const calcularTotal = (sub, desc) =>{
    if(sub !== 0.0){
      if(desc!==0.0){
        const tot =  sub - (sub * desc / 100);
        setTotal(tot);
      }else{
        setTotal(sub);
      }
    }else{
      setTotal(0);
    }
  }

  const onChangeSubtotal = (sub) =>{
    setSubtotal(sub);
    //calcularTotal(sub, descompte);
  }

  const onChangeDescompte = (desc) =>{
    setDescompte(desc);
    //calcularTotal(subtotal, desc);
  }

  const changeFcrea = (date, dateString) => {
    if(date){
      setFcrea(date.toISOString());
      //Trec l'hora
      //setFcrea(date.format(moment.HTML5_FMT.DATE));
    }else{
      setFcrea(null);
    }
  }


  const submitForm = async (values) => {
    let correcte = true;

    if(id_extraescolar===0){
      setErrorId_extraescolar('error');
      setErrorId_extraescolarText(props.t('generic.tria'));
      correcte = false;
    }

    if(id_alumne===0){
      setErrorId_Alumne('error');
      setErrorId_AlumneText(props.t('generic.tria'));
      correcte = false;
    }

    if(horari_especial===1){

      if(dia_horari_especial===0){
        correcte = false;
        message.error({content: 'Tria un dia de la setmana.', duration:20});
      }

      if(h_ini===0){
        setErrorH_ini('error');
        correcte = false;
      }
      if(h_fin===0){
        setErrorH_fin('error');
        correcte = false;
      }
    }

    if(!correcte){
      return false;
    }
    setGuardant(true);

    let dades = {
      id,
      id_extraescolar,
      id_pare,
      id_alumne,
      subtotal ,
      descompte,
      total,
      fcrea,
      enviar_email
    }

    console.log(JSON.stringify(dades))

    try{
      const resposta = await Api.myFetch(`/extraescolars/comanda/${id}`, 'PUT', JSON.stringify(dades));
      console.log(resposta)
      if(resposta.result.success === 1){
        setGuardant(false);
        message.success(props.t('generic.OK_saved'), 5);
        props.callback();
      }else{
        setGuardant(false);
        console.error('Error: submit ComandesExtraescolarsEdit: ',resposta);
        message.config({top: 50});
        message.open({content: resposta.data.message, duration:10});
      }
    }catch(error){
      setGuardant(false);
      message.config({top: 50});
      message.open({content: "Error saving", duration:10});
      console.error('Error: ComandesExtraescolarsEdit: ', error);
    }

  }

let localeDatePicker;
switch (props.t('idioma')) {
  case 'ca':
    localeDatePicker = locale_ca;
    break;
  case 'en':
    localeDatePicker = locale_en;
    break;
  case 'es':
    localeDatePicker = locale_es;
    break;
  default:
    localeDatePicker = locale_ca;
}
const dateFormat = 'DD/MM/YYYY';

return(
  <Form onFinish={submitForm} style={props.item.id_alumne == 0 ? {marginTop: 20} : {marginTop: 0}}
    layout="horizontal"
    autoComplete="off"
  >
  <Form.Item label={props.t('generic.alumne')} required={id_alumne===0 ? true : false} validateStatus={errorId_Alumne} help={errorId_AlumneText} style={{marginBottom: 0}}>
    <Select value={id_alumne===0 ? '' : id_alumne} onChange={(value) => triaAlumne(value)}
      //disabled={props.item.id_alumne===0 ? false : true}
      showSearch
      filterOption={(input, option) =>
        HtmlStrings.normalize(option.children).includes(HtmlStrings.normalize(input))
      }
    >
      {alumnesOptions}
    </Select>
  </Form.Item>
  <Form.Item label={props.t('generic.responsable')}>
    <Input name="pare_nom" defaultValue={entities.decode(props.item.pare_nom)} disabled/>
  </Form.Item>
  <Form.Item label={props.t('menu.Extraescolars')}>
    <Input name="nom" defaultValue={entities.decode(nom[props.t('idioma')])} disabled/>
  </Form.Item>

  <Form.Item label={props.t('menu.Extraescolars')} required={true} validateStatus={errorId_extraescolar} help={errorId_extraescolarText} style={{marginBottom: 0}}>
    <Select id="extra_select" value={id_extraescolar2===0 ? '' : id_extraescolar} onChange={(value) => triaExtraescolar(value)}
      showSearch
      filterOption={(input, option) =>
        HtmlStrings.normalize(option.children.props.children[0].props.children).includes(HtmlStrings.normalize(input))
      }
    >
      {extraescolarOptions}
    </Select>
  </Form.Item>

  <Form.Item label={props.t('generic.created_at')} required={true}>
    <DatePicker
      onChange={changeFcrea}
      defaultValue={fcrea != null ? moment(fcrea) : ''}
      format={dateFormat}
      locale={localeDatePicker}
      />
  </Form.Item>

  <Form.Item label={props.t('generic.subtotal')}>
      <InputNumber name="subtotal" onChange={onChangeSubtotal} value={subtotal}
      step={1.0}
      formatter={value => `${value}€`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
      parser={value => value.replace(/€\s?|(,*)/g, '')}
      />
  </Form.Item>
  <Form.Item label={props.t('generic.descompte')}>
      <InputNumber name="descompte" onChange={onChangeDescompte} value={descompte}
      min={-100} max={100} step={0.1}
      formatter={value => `${value}%`}
      parser={value => value.replace('%', '')}
      />
  </Form.Item>
  <Form.Item label={props.t('generic.total')}>
      <InputNumber name="total" value={total} disabled
      formatter={value => `${value}€`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
      parser={value => value.replace(/€\s?|(,*)/g, '')}
      />
  </Form.Item>

  {
    horari_especial
    ? <Card
        headStyle={{backgroundColor: "#fbe9e7"}}
        title="Horari Especial"
        hoverable={true}
        bordered={true}
        style={{marginBottom: '15px', borderColor: "#fbe9e7"}}
      >

        <Row justify="space-between" align="middle">
          <Col span={24} style={{marginBottom: '20px'}}>
            <Radio.Group onChange={(e) => setDia_horari_especial(e.target.value)} value={dia_horari_especial}>
              <Radio value={1}>{props.t('dies.dilluns')}</Radio>
              <Radio value={2}>{props.t('dies.dimarts')}</Radio>
              <Radio value={3}>{props.t('dies.dimecres')}</Radio>
              <Radio value={4}>{props.t('dies.dijous')}</Radio>
              <Radio value={5}>{props.t('dies.divendres')}</Radio>
              {/*<Radio value={6}>{props.t('dies.dissabte')}</Radio>
              <Radio value={7}>{props.t('dies.diumenge')}</Radio>*/}
            </Radio.Group>
          </Col>
          <Col span={12}>
            <Form.Item label={props.t('generic.hini')} required={true} validateStatus={errorH_ini}>
                <InputNumber name="hora" style={{width:"65px"}} onChange={setH_ini} value={h_ini} min={0} max={23} step={1} formatter={value => `${value}h`} parser={value => value.replace('h', '')}/>
                <span>&nbsp;:&nbsp;</span>
                <InputNumber name="minut" style={{width:"65px"}} onChange={setM_ini} value={m_ini} min={0} max={59} step={1} formatter={value => `${value}m`} parser={value => value.replace('m', '')}/>
            </Form.Item>
            </Col>
            <Col span={12}>
            <Form.Item label={props.t('generic.hfin')} required={true} validateStatus={errorH_fin}>
                <InputNumber name="hora" style={{width:"65px"}} onChange={setH_fin} value={h_fin} min={0} max={23} step={1} formatter={value => `${value}h`} parser={value => value.replace('h', '')}/>
                <span>&nbsp;:&nbsp;</span>
                <InputNumber name="minut" style={{width:"65px"}} onChange={setM_fin} value={m_fin} min={0} max={59} step={1} formatter={value => `${value}m`} parser={value => value.replace('m', '')}/>
            </Form.Item>
          </Col>
      </Row>
    </Card>
    : null
  }
  <Row>
    <Space>
      <span>{props.t('generic.enviarEmail')}</span>
      <Switch onChange={setEnviar_email} checkedChildren={props.t('generic.si')} unCheckedChildren={props.t('generic.no')}/>
    </Space>
  </Row>
  <Row type="flex" justify="start" style={{marginTop: 25, marginBottom: 25}}>
    <Col>
      <Button onClick={props.callback}>{props.t('generic.cancel')}</Button>
    </Col>
    <Col style={{marginLeft:'10px'}}>
      <Button type="primary" htmlType="submit" loading={guardant}>{props.t('generic.save')}</Button>
    </Col>
  </Row>
  </Form>
);

}
export default translate(ComandesExtraescolarsEdit);
