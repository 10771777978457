import React, { useState, useEffect, useContext } from 'react';
import moment from 'moment';
import 'moment/locale/ca';
import 'moment/locale/es';

import locale_ca from 'antd/es/date-picker/locale/ca_ES';
import locale_en from 'antd/es/date-picker/locale/en_GB';
import locale_es from 'antd/es/date-picker/locale/es_ES';

import { translate } from 'react-multi-lang';
import { Row, Col, Typography, Button, Form, Space, Upload, message } from 'antd';
import { MinusOutlined, PlusOutlined, CarOutlined, ExperimentOutlined, CloseOutlined, PauseOutlined, AlertFilled, UploadOutlined} from '@ant-design/icons';
import { AllHtmlEntities } from 'html-entities';

import rabbit from 'crypto-js/rabbit';
import encUtf8 from 'crypto-js/enc-utf8';

import { Api, HtmlStrings } from '../../../../helpers';
import TextAreaCount from '../../../TextAreaCount';
import ImatgeDNI from '../../../ImatgeDNI';
import VisorImatges from '../../../visorImatges';

const entities = new AllHtmlEntities();

function MantenimentEditImatges(props){
  const token = rabbit.decrypt(sessionStorage.getItem('jwtToken'),process.env.REACT_APP_SECRET_KEY).toString(encUtf8);
  const id = props.item.id;

  const [visor, setVisor] = useState({visible: false});
  const [guardant, setGuardant] = useState(false);
  const [llista, setLlista] = useState([]);

  const nou = (props.item.id===0);

  useEffect(() => {
    buscarImatges(id);
  },[props.item.id, id]);

  const buscarImatges = async () => {
    if(!nou){
      const com = await Api.get(`/tic/documents/${id}`);
      if(com.result.success===1){
        console.log(com.data)
        setLlista(com.data);
      }
    }

  }

  const uploadConfig = {
    multiple: false,
    name: 'fitxer',
    method: 'POST',
    action: global.api_url+`/tic/document/${id}`,
    headers: {'Authorization': token},
    accept: 'application/pdf,image/png,image/jpeg,image/jpg,image/gif',
    onChange: buscarImatges
  };

  return(
    <>
        <Row  style={{marginBottom: 10}}>
          <Upload {...uploadConfig} showUploadList={false}>
            <Button icon={<UploadOutlined />}>Click to Upload</Button>
          </Upload>
        </Row>
        <Row>
        {
          llista.map((imatge, index)=>{
            return(
              <Col xs={24} sm={12} md={12} lg={8} xl={6} key={index}>
                <img src={imatge.url} width={200} onClick={()=>setVisor({visible: true, image: imatge.url, title: imatge.nom})}/>
              </Col>
            )
          })
        }
        </Row>

      { !visor.visible
        ? null
        : <VisorImatges image={visor.image} title={visor.title} onClose={() => setVisor({visible:false})} clickOutsideToExit={true}/>
      }
    </>
  );
}
export default translate(MantenimentEditImatges);
