import React, { useState, useEffect, useContext } from 'react';
import moment from 'moment';
import 'moment/locale/ca';
import 'moment/locale/es';

import locale_ca from 'antd/es/date-picker/locale/ca_ES';
import locale_en from 'antd/es/date-picker/locale/en_GB';
import locale_es from 'antd/es/date-picker/locale/es_ES';

import { translate } from 'react-multi-lang';
import { Row, Col, Typography, Button, Card, Radio, InputNumber, Form, Select, Table, DatePicker, Space, Switch, message } from 'antd';
import { MinusOutlined, PlusOutlined, CarOutlined, ExperimentOutlined } from '@ant-design/icons';
import { AllHtmlEntities } from 'html-entities';

import { PerfilUsuariContext } from "../../../../context/perfil-usuari";

import { Api, HtmlStrings } from '../../../../helpers';
import AutoritzarContrasenya from '../../../AutoritzarContrasenya';
import TextAreaCount from '../../../TextAreaCount';

const entities = new AllHtmlEntities();

function AcollidaEditFamilia(props){

  const [perfilUsuari, setPerfilUsuari] = useContext(PerfilUsuariContext);

  const id = props.item.id;
  const [id_alumne, setId_alumne] = useState(props.item.id_alumne);
  const [id_pare, setId_pare] = useState(props.item.id_pare);
  const [id_familia, setId_familia] = useState(props.item.id_familia);
  const [tipus, setTipus] = useState(props.item.tipus);
  //const [puntual_data, setPuntual_data] = useState(props.item.puntual_data ? props.item.puntual_data : moment.utc(new Date()));
  const [puntual_data, setPuntual_data] = useState(() => {
    let resultat
    console.log('props.diaTriat', props)
    if(props.item.puntual_data){
      resultat = props.item.puntual_data
    } else{
      if(props.diaTriat){
        resultat = moment.utc(props.diaTriat)
      }else{
        resultat = moment.utc(new Date())
      }
    }
    return resultat;
  });
  const [h_ini, setH_ini] = useState(props.item.h_ini);
  const [m_ini, setM_ini] = useState(props.item.m_ini);
  const [h_fin, setH_fin] = useState(props.item.h_fin);
  const [m_fin, setM_fin] = useState(props.item.m_fin);
  const [h_limit, setH_limit] = useState(0);
  const [m_limit, setM_limit] = useState(0);
  const [id_classe, setId_classe] = useState(props.item.id_classe);
  const [id_curs_escolar, setId_curs_escolar] = useState(props.id_curs_escolar > 0 ? props.id_curs_escolar :  perfilUsuari.curs_escolar_actual.id);
  const [descompte, setDescompte] = useState(props.item.descompte);


  //Al final borrable
  const [classes, setClasses] = useState([]);
  const [classesOptions, setClassesOptions] = useState([]);
  //FI Al final borrable

  const [familiesOptions, setFamiliesOptions] = useState([]);
  const [paresOptions, setParesOptions] = useState([]);

  const [triats, setTriats] = useState([]);


  const [isLoadingClasse, setIsLoadingClasse] = useState(false);

  const [llistaAlumnes, setLlistaAlumnes] = useState([]);
  const [validat, setValidat] = useState(false);
  const [guardant, setGuardant] = useState(false);

  const [errorId_alumne, setErrorId_alumne] = useState('');
  const [errorId_alumneText, setErrorId_alumneText] = useState('');
  const [errorId_Pare, setErrorId_Pare] = useState('');
  const [errorId_PareText, setErrorId_PareText] = useState('');
  const [errorH_ini, setErrorH_ini] = useState('');
  const [errorH_fin, setErrorH_fin] = useState('');
  const [errorId_familia, setErrorId_familia] = useState('');
  const [errorId_familiaText, setErrorId_familiaText] = useState('');

  const nou = (props.item.id===0);


  const inicialitzaHores = () => {
    if(nou){
      const now = new Date();
      if(tipus==='mati'){
        setH_ini(now.getHours())
        setM_ini(now.getMinutes())
      }
      if(tipus==='tarda'){
        setH_fin(now.getHours())
        setM_fin(now.getMinutes())
      }
    }
  }

  const inicialitzaEdicio = () =>{
    if(id_alumne > 0){
      setTriats([id_alumne])
    }
  }


  useEffect(() => {
    inicialitzaHores();
  },[props.t('idioma')]);


  useEffect(() => {
    if(id_familia > 0){
      buscarDadesPares();
      buscarDadesAlumnes();
    }
    buscarDadesFamilies();
  },[]);


  useEffect(() => {
    buscarDadesAlumnes();
    if(!nou){
      inicialitzaEdicio();
    }
  },[id_familia]);


  useEffect(() => {
    buscarDadesPares();
  },[id_familia]);




  const buscarDadesPares = async () => {
    if(id_familia > 0){
      const pares = await Api.get(`/pares/familia/${id_familia}`);
      if(pares.result.success===1){
        let pare = 0;
        const llista_pares = pares.data.map((item) =>{
              if(pare == 0 && props.item.id_familia !== id_familia){
                pare = item.id;
                if(nou){
                  setId_pare(pare);
                  setErrorId_Pare('');
                  setErrorId_PareText('');
                }
              }
              return(
                <Select.Option key={item.id} value={item.id}>
                  {entities.decode(item.nom) + ' ' + entities.decode(item.cognom1) + ' ' + entities.decode(item.cognom2)}
                </Select.Option>
              );
        });
        setParesOptions(llista_pares);
      }
    }
  }


  const buscarDadesFamilies = async () => {
    const fam = await Api.get('/families');
    if(fam.result.success===1){
      const llista_fam = fam.data.families.map((item) =>{
            return(
              <Select.Option key={item.id} value={item.id}>
                {item.id + ' - ' + entities.decode(item.cognom1) + ' ' + entities.decode(item.cognom2)}
              </Select.Option>
            );
      });
      setFamiliesOptions(llista_fam);
    }
  }


  const buscarDadesAlumnes = async () => {
    setIsLoadingClasse(true);
    if(id_familia > 0){
      const f = await Api.get(`/alumnes/familia/${id_familia}`);
      if(f.result.success===1){
        setLlistaAlumnes(f.data.alumnes);
      }else{
        setLlistaAlumnes([]);
      }
    }
    setIsLoadingClasse(false);
  }



  const submitForm = async (valors) => {

    let correcte = true;

    if(triats.length < 1){
      setErrorId_alumne('error');
      setErrorId_alumneText(props.t('generic.tria'));
      correcte = false;
    }
    if(id_pare===0){
      setErrorId_Pare('error');
      setErrorId_PareText(props.t('generic.tria'));
      correcte = false;
    }
    if(tipus==='mati'){
      if(h_ini===0){
        setErrorH_ini('error');
        correcte = false;
      }
      if(h_ini>9){
        setErrorH_ini('error');
        correcte = false;
      }
      if(h_ini===9 && m_ini > 0){
        setErrorH_ini('error');
        correcte = false;
      }
    }
    if(tipus==='tarda'){
      if(h_fin===0){
        setErrorH_fin('error');
        correcte = false;
      }
      if(h_fin<15){
        setErrorH_fin('error');
        correcte = false;
      }
      if(h_fin>20){
        setErrorH_fin('error');
        correcte = false;
      }
    }

    if(!correcte){
      return false;
    }


    let method = nou?'POST':'PUT';
    let url_api = nou? '/acollidaminuts/comanda' : '/acollidaminuts/comanda/'+id;

    setGuardant(true);

    correcte = true;

    await Promise.all(triats.map(async(alumne) =>{


      const cl = llistaAlumnes.find((element,index)=> element.id === alumne);

      let dades = {
        id_alumne: alumne,
        id_pare,
        tipus,
        puntual_data,
        h_ini: tipus === 'tarda' ? cl.h_fin : h_ini,
        m_ini: tipus === 'tarda' ? cl.m_fin : m_ini,
        h_fin: tipus ===  'mati' ? h_limit : h_fin,
        m_fin: tipus ===  'mati' ? m_limit : m_fin,
        id_curs_escolar,
        descompte
      }

      //console.log(dades);
      //console.log(JSON.stringify(dades));

      try{
        const resposta = await Api.myFetch(url_api, method, JSON.stringify(dades));
        if(resposta.result.success === 1){

          //message.success(props.t('generic.OK_saved'), 5);

        }else{
          correcte = false;
          console.error('Error: guardant acollida: ',resposta);
          message.config({top: 50});
          message.open({content: resposta.data.message, duration:10});
        }
      }catch(error){
        correcte = false;
        console.error('Error: guardant acollida.: ', error);
      }

    }));

    setGuardant(false);

    if(correcte){
        message.success(props.t('generic.OK_saved'), 5);
        props.callback();
    }else{
      message.config({top: 50});
      message.open({content: "Error saving", duration:10});
    }

  }


  const assignaTriats = (ids_alumnes) =>{


    setTriats(ids_alumnes);
    let limit = [];

    const alumns = llistaAlumnes.filter((element, index, arr) => ids_alumnes.includes(element.id));

    alumns.map(a =>{
      limit[a.id] = {id: a.id, h_ini: a.h_ini, m_ini: a.m_ini, num: ((a.h_ini*60)+a.m_ini)}
    });

    const minim = limit.reduce((prev, current) => {
			return (prev.num < current.num) ? prev : current
    })

    setH_limit(minim.h_ini);
    setM_limit(minim.m_ini);

  }


  const changePuntual_data = (date, dateString) => {
    if(date){
      //Trec l'hora
      setPuntual_data(date.format(moment.HTML5_FMT.DATE));
    }else{
      setPuntual_data(null);
    }
  }


  const triaPare = (value) =>{
    setId_pare(value);
    if(value>0){
      setErrorId_Pare('');
      setErrorId_PareText('');
    }
  }

  const triaFamilia = (value) =>{
    setId_familia(value);
    if(value>0){
      setErrorId_familia('');
      setErrorId_familiaText('');
      setId_pare(0);
      setId_alumne(0);
    }
  }

  const origenExtraescolar = (value) =>{
    setDescompte(value ? 100 : 0);
  }

  const arreglarTxt4Search = text =>{
      // Poso en minuscules, normalitzo caracters treure accents i ñ , elimina els caracters diacrítics raros d'un text (ES6)
        return text.trim().toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g,"");
  }

  let localeDatePicker;
  switch (props.t('idioma')) {
    case 'ca':
      localeDatePicker = locale_ca;
      break;
    case 'en':
      localeDatePicker = locale_en;
      break;
    case 'es':
      localeDatePicker = locale_es;
      break;
    default:
      localeDatePicker = locale_ca;
  }
  const dateFormat = 'DD/MM/YYYY';

  return(
    <>
      <Form onFinish={submitForm} layout="vertical" autoComplete="off">
      <Row justify="space-between" align="middle">
        <Col>
          <Form.Item label={props.t('generic.data')} required={true}>
            <DatePicker
              onChange={changePuntual_data}
              value={puntual_data ? moment(new Date(puntual_data), dateFormat) : ''}
              format={dateFormat}
              locale={localeDatePicker}
              picker="date"
              />
          </Form.Item>
        </Col>
        <Col>
          { tipus === 'mati'
          ?
            <Form.Item label={props.t('generic.hini')} required={true} validateStatus={errorH_ini}>
                <InputNumber name="hora" onChange={setH_ini} value={h_ini} min={0} max={23} step={1} formatter={value => `${value}h`} parser={value => value.replace('h', '')}/>
                <span>&nbsp;:&nbsp;</span>
                <InputNumber name="minut" onChange={setM_ini} value={m_ini} min={0} max={59} step={1} formatter={value => `${value}m`} parser={value => value.replace('m', '')}/>
            </Form.Item>
          :
            <Form.Item label={props.t('generic.hfin')} required={true} validateStatus={errorH_fin}>
                <InputNumber name="hora" onChange={setH_fin} value={h_fin} min={0} max={23} step={1} formatter={value => `${value}h`} parser={value => value.replace('h', '')}/>
                <span>&nbsp;:&nbsp;</span>
                <InputNumber name="minut" onChange={setM_fin} value={m_fin} min={0} max={59} step={1} formatter={value => `${value}m`} parser={value => value.replace('m', '')}/>
            </Form.Item>
          }

        </Col>
      </Row>
      { tipus === 'mati' ? null :
        <Row style={{marginBottom: 10}}>
          <Space>
            <span>{props.t('acollida.origenExtraescolar')}</span>
            <Switch checked={descompte===100} onChange={origenExtraescolar} checkedChildren={props.t('generic.si')} unCheckedChildren={props.t('generic.no')}/>
          </Space>
        </Row>
    }


        <Form.Item label={props.t('alumnes.familia')} required={true} validateStatus={errorId_familia} help={errorId_familiaText} style={{marginBottom:0}}>
          <Select
            defaultValue={id_familia===0 ? '' : id_familia} onChange={(value) => triaFamilia(value)}
            showSearch
            filterOption={(input, option) =>
              HtmlStrings.normalize(option.children).includes(HtmlStrings.normalize(input))
            }
          >
            {familiesOptions}
          </Select>
        </Form.Item>
        <Form.Item
          validateStatus={errorId_alumne}
          help={errorId_alumneText}
          label={props.t('notificacions.alumne')}
          required={true}
          style={{marginBottom: 0}}

        >
        <div style={errorId_alumne ==='' ? null : {border: '1px solid red'}}>
          <Table
            style={{marginBottom: '1rem'}}
            size="small"
            loading={isLoadingClasse}
            rowKey="id"
            dataSource={llistaAlumnes}
            columns={[
              {
                title: props.t('menu.Alumnes'),
                key: "nom",
                dataIndex: "nom",
                render: (text,record) => (entities.decode(record.nom)+' '+entities.decode(record.cognom1)+' '+entities.decode(record.cognom2))
              }
            ]}
            pagination={false}
            rowSelection={{
            selectedRowKeys: triats,
            hideSelectAll: false,
            type: 'checkbox',
            onChange: (selectedRowKeys, selectedRows) => {
              if(selectedRowKeys.length > 0){
                setErrorId_alumne('');
                setErrorId_alumneText('');
              }
              assignaTriats(selectedRowKeys);
            }
          }}/>
        </div>
        </Form.Item>


        {id_familia <=0 ? null :
          <>
            <Form.Item label={props.t('generic.responsable')} required={true} validateStatus={errorId_Pare} help={errorId_PareText}>
              <Select defaultActiveFirstOption="true"
                value={id_pare===0 ? '' : id_pare} onChange={(value) => triaPare(value)}
                showSearch
                filterOption={(input, option) =>
                  HtmlStrings.normalize(option.children).includes(HtmlStrings.normalize(input))
                }
              >
                {paresOptions}
              </Select>
            </Form.Item>
          </>
        }

        <Row type="flex" justify="start" style={{marginTop: 25, marginBottom: 25}}>
          <Col>
            <Button onClick={()=>props.cancel ? props.cancel() : props.callback()}>{props.t('generic.cancel')}</Button>
          </Col>
          <Col style={{marginLeft:'10px'}}>
            <Button type="primary" htmlType="submit" loading={guardant}>{props.t('generic.save')}</Button>
          </Col>
        </Row>
      </Form>
    </>
  );
}
export default translate(AcollidaEditFamilia);
