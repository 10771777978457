import React, { useContext, useState, useEffect } from 'react';
import { translate } from 'react-multi-lang';
import { Row, Col, Alert, Button, List, Card, Tabs, Modal, Typography } from 'antd';
import { AlertFilled, DatabaseOutlined, InboxOutlined } from '@ant-design/icons';
import locale_ca from 'antd/es/date-picker/locale/ca_ES';
import { AllHtmlEntities } from 'html-entities';
import { MenuTriatContext } from "../context/menu-triat";
import { PerfilUsuariContext } from "../context/perfil-usuari";
import {ReactComponent as Carrito} from '../icons/icones_app_BSCD-01.svg';
import DashboardClasse from '../components/Formularis/perfils/profe/DashboardClasse';
import NewPassword from '../components/PantallesObligadesInici/NewPassword';
import {useInterval} from '../helpers/UseInterval';

const entities = new AllHtmlEntities();

function DashboardCuina(props){
  const [perfilUsuari, setPerfilUsuari] = useContext(PerfilUsuariContext);
  const [menuTriat, setMenuTriat] = useContext(MenuTriatContext);
  const [tabs, setTabs] = useState([]);
  const [refrescar, setRefrescar] = useState(0);
  const [mostrarNewPassword, setMostrarNewPassword] = useState(false);

  useEffect(() => {
    setMostrarNewPassword(perfilUsuari.account.obligar_modificar_password === 1);
  },[perfilUsuari]);

  const mides = {width: '100%',height: '100px'};
  const span_botons = 'ant-col-xs-24 ant-col-sm-24 ant-col-md-12 ant-col-lg-8 ant-col-xl-6 ant-col-xxl-6';
  const color1 = {padding: 0, cursor: 'pointer', width: mides.width, height: mides.height, backgroundColor: '#00e5ff', borderColor: '#00e5ff', color: 'rgba(0,0,0,0.87)'};
  const color2 = {padding: 0, cursor: 'pointer', width: mides.width, height: mides.height, backgroundColor: '#eeff41', borderColor: '#eeff41', color: 'rgba(0,0,0,0.87)'};
  const color3 = {padding: 0, cursor: 'pointer', width: mides.width, height: mides.height, backgroundColor: '#42a5f5', borderColor: '#42a5f5', color: 'rgba(0,0,0,0.87)'};
  const color4 = {padding: 0, cursor: 'pointer', width: mides.width, height: mides.height, backgroundColor: '#448aff', borderColor: '#448aff', color: 'rgba(0,0,0,0.87)'};
  const color5 = {padding: 0, cursor: 'pointer', width: mides.width, height: mides.height, backgroundColor: '#ff5252', borderColor: '#ff5252', color: 'rgba(0,0,0,0.87)'};

  return(
    <>


        <Row type="flex" justify="start" style={{padding: '25px'}} gutter={[16,16]}>
          <Col className={span_botons}>
            <div style={color5} onClick={(e) => setMenuTriat("ServeiMedic")} className="BotoDashboard">
              <Row justify="center" align="middle" style={{height: '100%'}}>
                <Col><Typography.Title level={4}>{props.t('menu.ServeiMedic')}</Typography.Title></Col>
              </Row>
            </div>
          </Col>
          <Col className={span_botons}>
            <div style={color1} onClick={(e) => setMenuTriat("Profe_MealTimeReport")} className="BotoDashboard">
              <Row justify="center" align="middle" style={{height: '100%'}}>
                <Col><Typography.Title level={4}>{props.t('menjador.report.titol')}</Typography.Title></Col>
              </Row>
            </div>
          </Col>
          <Col className={span_botons}>
            <div style={color3} onClick={(e) => setMenuTriat("Manteniment")} className="BotoDashboard">
              <Row justify="center" align="middle" style={{height: '100%'}}>
                <Col><Typography.Title level={4}>{props.t('menu.Manteniment')}</Typography.Title></Col>
              </Row>
            </div>
          </Col>
          <Col className={span_botons}>
            <div style={color4} onClick={(e) => setMenuTriat("Profe_Acollida")} className="BotoDashboard">
              <Row justify="center" align="middle" style={{height: '100%'}}>
                <Col><Typography.Title level={4}>{props.t('menu.Acollida')}</Typography.Title></Col>
              </Row>
            </div>
          </Col>
        </Row>


        {mostrarNewPassword
          ?
            <Modal
            key="NewPassword"
            visible={mostrarNewPassword}
            footer={null}
            width={550}
            style={{top:20}}
            bodyStyle={{ height: "350px"}}
            destroyOnClose={true}
            //forceRender={true}
            maskClosable={false}
            closable={false}
            >
              <NewPassword/>
            </Modal>
          :
          null
        }



    </>
  );
}

export default translate(DashboardCuina);
