import React, { useState, useContext } from 'react';
import moment from 'moment-timezone';

import { translate } from 'react-multi-lang';
import { Row, Col, Typography, Button, Card, Radio, InputNumber, Form, message } from 'antd';
import { MinusOutlined, PlusOutlined } from '@ant-design/icons';
import { AllHtmlEntities } from 'html-entities';

import { PerfilUsuariContext } from "../../../../context/perfil-usuari";

import { Api } from '../../../../helpers';
import AutoritzarContrasenya from '../../../AutoritzarContrasenya';

const entities = new AllHtmlEntities();

function Esdeveniment(props){

  console.log(props)

  const [perfilUsuari, setPerfilUsuari] = useContext(PerfilUsuariContext);

  const [producte, setProducte] = useState(props.item);
  const [imatge, setImatge] = useState(props.item.url_image || "https://via.placeholder.com/443x249?text=NO+IMAGE+ASSIGNED!!");
  const [validat, setValidat] = useState(false);
  const [guardant, setGuardant] = useState(false);
  const [propietats, setPropietats] = useState(
    props.item.propietats.map((p) => ({id_propietat: p.id_propietat, unitats: p.comprat, preu: p.preu}))
  );

  const [errorUnitats, setErrorUnitats] = useState('');

const sumaUnitat = (id_propietat) =>{
  const new_propietats = propietats.map((p) =>{
    if (p.id_propietat === id_propietat){
      p.unitats++;
    }
    return p;
  });

  setPropietats(new_propietats);
  setErrorUnitats('');
}

const restaUnitat = (id_propietat) =>{
  const new_propietats = propietats.map((p) =>{
    if (p.id_propietat === id_propietat){
      p.unitats--;
    }
    return p;
  });
  setPropietats(new_propietats);
}

const onChangeUnitats = (id_propietat, value) =>{
  const new_propietats = propietats.map((p) =>{
    if (p.id_propietat === id_propietat){
      p.unitats=value;
    }
    return p;
  });
  setPropietats(new_propietats);
  if(value>0){setErrorUnitats('')}
}



  const submitForm = async (valors) => {

    if(!validat){
      return false;
    }

    const greaterZero = propietats.find(p => p.unitats > 0);
    if(!greaterZero){
      setErrorUnitats('error');
      return false;
    }

    setGuardant(true);

    let dades = {
      id_producte: producte.id_producte,
      id_pare: perfilUsuari.account.id,
      propietats,
    }

    //console.log(dades);

    try{
      const resposta = await Api.myFetch('/botiga', 'POST', JSON.stringify(dades));
      if(resposta.result.success === 1){
        setGuardant(false);
        message.success(props.t('generic.OK_saved'), 5);
        props.tancarModal();
      }else{
        setGuardant(false);
        console.error('Error: guardant Roba: ',resposta);
        message.config({top: 50});
        message.open({content: resposta.data.message, duration:10});
      }
    }catch(error){
      setGuardant(false);
      message.config({top: 50});
      message.open({content: "Error saving", duration:10});
      console.error('Error: Roba guardar: ', error);
    }

  }

  console.log(propietats)

  const propietats_botons = props.item.propietats.map((p) =>{

    const radioStyle = {
          width:'373px',
          marginTop: '8px',
        };
    //const text = p.text[props.t('idioma')].padStart(12,'_');
    const text = entities.decode(p.text[props.t('idioma')]);

    return(
      <Row justify="end" style={{marginBottom: '10px'}} key={'Esdeveniment_'+p.id_propietat}>
        <span style={{fontSize: '16px', fontWeight: 'bold', marginTop: '2px',marginRight: '5px'}}>{text}{p.preu > 0 ? `(${p.preu}€)` : ''}</span>
        <Button type="primary" shape="circle" icon={<MinusOutlined />} style={{marginRight:'4px'}} onClick={() => restaUnitat(p.id_propietat)} disabled={producte.comprat}/>
        <Form.Item validateStatus={errorUnitats} style={{marginBottom: 0}}><InputNumber min={0} onChange={(value) => onChangeUnitats(p.id_propietat, value)} value={propietats.find((element, index) => element.id_propietat === p.id_propietat).unitats} style={{width:'55px'}} disabled={producte.comprat}/></Form.Item>
        <Button type="primary" shape="circle" icon={<PlusOutlined />} style={{marginLeft:'4px'}} onClick={() => sumaUnitat(p.id_propietat)} disabled={producte.comprat}/>
      </Row>
    );
  });

  const data_hora = moment(producte.data_hora_esdeveniment).tz('Europe/Madrid').format('DD/MM/YYYY HH:mm');
// hidden={producte.comprat}
  return(
    <>
    <Row justify="center">
      <Card
        bordered={false}
        style={{ marginTop: '1.5rem', padding: 0}}
        cover={<img alt="Picture" src={imatge} onError={() => setImatge("https://via.placeholder.com/443x249?text=URL+IMAGE+NOT+FOUND!!")}/>}
      >
        <Card.Meta title={<div style={{textAlign:'left'}}>{data_hora}</div>}/>
        <p style={{fontSize: '16px'}}><b>{entities.decode(producte.nom[props.t('idioma')])}</b></p>
        <Card.Meta style={{whiteSpace: 'pre-line'}} title={''} description={entities.decode(producte.descripcio[props.t('idioma')])} />

        <div style={{marginTop: '30px', marginRight:'114px'}}>
          {propietats_botons}
        </div>
      </Card>
    </Row>
    {!producte.comprat
      ?
        <>
          <Row justify="center" style={{margin: '20px 0px 10px 0px'}}>
            <AutoritzarContrasenya resposta={setValidat}/>
          </Row>
          <Row justify="center" style={{marginTop: '10px'}}>
            <Button type="primary" onClick={() => submitForm()} loading={guardant} disabled={!validat}>
              {props.t('botiga.pagar_autoritzar')}
            </Button>
          </Row>
        </>
      :
        <>
          <Row justify="center" style={{margin: '20px 0px 10px 0px'}}>
            <span style={{color: 'red'}}>*{props.t('generic.serveiJaContractat')}</span>
          </Row>
          <Row justify="center" style={{marginTop: '10px'}}>
            <Button type="primary" onClick={() =>props.tancarModal()}>
              {props.t('generic.back')}
            </Button>
          </Row>
        </>
      }
    </>
  );
}
export default translate(Esdeveniment);
