import React, { useState, useEffect } from 'react';
import { translate } from 'react-multi-lang';
import { SyncOutlined, LeftOutlined } from '@ant-design/icons';

import {Form, Row, Col, Button, Input, InputNumber, DatePicker, Select, Divider, Switch, Space, message } from 'antd';
import moment from 'moment';
import 'moment/locale/ca';
import 'moment/locale/es';

import locale_ca from 'antd/es/date-picker/locale/ca_ES';
import locale_en from 'antd/es/date-picker/locale/en_GB';
import locale_es from 'antd/es/date-picker/locale/es_ES';

import { AllHtmlEntities } from 'html-entities';

import { Api, HtmlStrings } from '../../../../helpers';

const entities = new AllHtmlEntities();

function ComandesMenjadorEdit(props){

  const id = props.item.id;
  const [enviar_email, setEnviar_email] = useState(false);
  const [id_menjador, setId_menjador] = useState(props.item.id_menjador);
  const [id_alumne, setId_alumne] = useState(props.item.id_alumne);
  const [alumnesOptions, setAlumnesOptions] = useState([]);
  const [id_familia, setId_familia] = useState(props.item.id_familia | 0);
  const [id_pare, setId_pare] = useState(props.item.id_pare);
  const [tipus, setTipus] = useState(props.item.tipus);
  const [puntual_data, setPuntual_data ] = useState(props.item.puntual_data);
  const [subtotal, setSubtotal] = useState(props.item.subtotal);
  const [descompte, setDescompte] = useState(props.item.descompte);
  const [total, setTotal] = useState(props.item.total);
  const [fcrea, setFcrea ] = useState(props.item.creat);

  const [guardant, setGuardant] = useState(false);

  const [errorrFcrea, setErrorFcrea] = useState('');


  useEffect(() => {
    buscarDadesFills();
  },[props.item.id_alumne]);

  const buscarDadesFills = async () => {
    if(id_familia > 0){
      const fills = await Api.get(`/alumnes/familia/${id_familia}`);
      if(fills.result.success===1){
        let array_id_fills = [];
        const llista_fills =fills.data.alumnes.map((item) =>{
              array_id_fills.push(item.id);
              return(
                <Select.Option key={item.id} value={item.id}>
                  {entities.decode(item.nom) + ' ' + entities.decode(item.cognom1) + ' ' + entities.decode(item.cognom2)}
                </Select.Option>
              );
        });
        setAlumnesOptions(llista_fills);
        //setIds_fills(array_id_fills);
      }
    }
  }


  const calcularTotal = (sub, desc) =>{
    if(sub !== 0.0){
      if(desc!==0.0){
        const tot =  sub - (sub * desc / 100);
        setTotal(tot);
      }else{
        setTotal(sub);
      }
    }else{
      setTotal(0);
    }
  }

  const onChangeSubtotal = (sub) =>{
    setSubtotal(sub);
    calcularTotal(sub, descompte);
  }

  const onChangeDescompte = (desc) =>{
    setDescompte(desc);
    calcularTotal(subtotal, desc);
  }

  const changePuntual_data = (date, dateString) => {
    if(date){
      //Trec l'hora
      setPuntual_data(date.format(moment.HTML5_FMT.DATE));
    }else{
      setPuntual_data(null);
    }
  }
  const changeFcrea = (date, dateString) => {
    if(date){
      setFcrea(date.toISOString());
    }else{
      setFcrea(null);
    }
  }
  const triaAlumne = (value) =>{
    setId_alumne(value);
  }


  const submitForm = async (values) => {

    let correcte = true;

    if(!fcrea){
      setErrorFcrea('error');
      correcte = false;
    }

    if(!correcte){
      return false;
    }

    setGuardant(true);
    let dades = {
      id,
      id_menjador,
      id_alumne,
      id_pare,
      tipus,
      puntual_data,
      subtotal,
      descompte,
      total,
      fcrea,
      enviar_email
    }

    try{
      console.log(JSON.stringify(dades))
      const resposta = await Api.myFetch(`/menjador/comanda/${id}`, 'PUT', JSON.stringify(dades));
      if(resposta.result.success === 1){
        setGuardant(false);
        message.success(props.t('generic.OK_saved'), 5);
        props.callback();
      }else{
        setGuardant(false);
        console.error('Error: submit ComandesMenjadorEdit: ',resposta);
        message.config({top: 50});
        message.open({content: resposta.data.message, duration:10});
      }
    }catch(error){
      setGuardant(false);
      message.config({top: 50});
      message.open({content: "Error saving", duration:10});
      console.error('Error: ComandesMenjadorEdit: ', error);
    }

  }

  let localeDatePicker;
  switch (props.t('idioma')) {
    case 'ca':
      localeDatePicker = locale_ca;
      break;
    case 'en':
      localeDatePicker = locale_en;
      break;
    case 'es':
      localeDatePicker = locale_es;
      break;
    default:
      localeDatePicker = locale_ca;
  }
  const dateFormat = 'DD/MM/YYYY';

  return(
    <Form onFinish={submitForm}
      layout="horizontal"
      autoComplete="off"
    >
    <Form.Item label={props.t('generic.created_at')} required={true} validateStatus={errorrFcrea}>
      <DatePicker
        onChange={changeFcrea}
        defaultValue={fcrea != null ? moment(fcrea) : ''}
        format={'DD/MM/YYYY HH:mm'}
        locale={localeDatePicker}
        showTime
        />
    </Form.Item>
    <Form.Item label={props.t('generic.alumne')} required={true}>
      <Select value={id_alumne===0 ? '' : id_alumne} onChange={(value) => triaAlumne(value)}
        showSearch
        filterOption={(input, option) =>
          HtmlStrings.normalize(option.children).includes(HtmlStrings.normalize(input))
        }
      >
        {alumnesOptions}
      </Select>
    </Form.Item>
    <Form.Item label={props.t('generic.responsable')}>
      <Input name="pare_nom" defaultValue={entities.decode(props.item.pare_nom)} disabled/>
    </Form.Item>
    <Form.Item label={props.t('menu.Menjador')}>
      <Input name="nom" defaultValue={entities.decode(props.item.menjador[props.t('idioma')])} disabled/>
    </Form.Item>

    { tipus==='mensual' ? null :
    <Form.Item label={props.t('generic.data')} required={true}>
      <DatePicker
        onChange={changePuntual_data}
        value={puntual_data ? moment(new Date(puntual_data), dateFormat) : ''}
        format={dateFormat}
        locale={localeDatePicker}
        picker="date"
        />
    </Form.Item>
  }

    <Form.Item label={props.t('generic.subtotal')}>
        <InputNumber name="subtotal" onChange={onChangeSubtotal} defaultValue={subtotal}
        step={1.0}
        formatter={value => `${value}€`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
        parser={value => value.replace(/€\s?|(,*)/g, '')}
        />
    </Form.Item>
    <Form.Item label={props.t('generic.descompte')}>
        <InputNumber name="descompte" onChange={onChangeDescompte} defaultValue={descompte}
        min={-100} max={100} step={0.1}
        formatter={value => `${value}%`}
        parser={value => value.replace('%', '')}
        />
    </Form.Item>
    <Form.Item label={props.t('generic.total')}>
        <InputNumber name="total" value={total} disabled
        formatter={value => `${value}€`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
        parser={value => value.replace(/€\s?|(,*)/g, '')}
        />
    </Form.Item>
    <Row>
      <Space>
        <span>{props.t('generic.enviarEmail')}</span>
        <Switch onChange={setEnviar_email} checkedChildren={props.t('generic.si')} unCheckedChildren={props.t('generic.no')}/>
      </Space>
    </Row>
    <Row type="flex" justify="start" style={{marginTop: 25, marginBottom: 25}}>
      <Col>
        <Button onClick={props.callback}>{props.t('generic.cancel')}</Button>
      </Col>
      <Col style={{marginLeft:'10px'}}>
        <Button type="primary" htmlType="submit" loading={guardant}>{props.t('generic.save')}</Button>
      </Col>
    </Row>
    </Form>
  );

}
export default translate(ComandesMenjadorEdit);
