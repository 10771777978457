import React, { useState, useEffect, useContext } from 'react';
import { translate } from 'react-multi-lang';
import { Table, Input, Button, Space, Row, Col, Tooltip, Popconfirm, Modal, Form, Select, message } from 'antd';
import Highlighter from 'react-highlight-words';
import { SearchOutlined, ClearOutlined, EditOutlined, DeleteOutlined, InboxOutlined, AlertFilled, AlertTwoTone } from '@ant-design/icons';
import { AllHtmlEntities } from 'html-entities';

import Moment from 'react-moment';
import moment from 'moment';

import ContainerPagina from '../../../components/ContainerPagina';
import TitolPagina from '../../../components/TitolPagina';
import TriaCursEscolar from '../../../components/TriaCursEscolar';
import LlistaEditableModalNouEdit from '../../../components/LlistaEditableModalNouEdit';
import Edit from '../../../components/Formularis/perfils/admin/ComandesSummerExterns';
import ComandesSummerExternsExportaExcel from '../../../components/Formularis/perfils/admin/ComandesSummerExternsExportaExcel';
import { Api, InitDades } from '../../../helpers';
import {PerfilUsuariContext} from "../../../context/perfil-usuari";

const { Column } = Table;
const entities = new AllHtmlEntities();

function ComandesSummer(props){
  moment.locale('es');
  const [ perfilUsuari ] = useContext(PerfilUsuariContext);


  const [refrescar, setRefrescar] = useState(0);

  //CERCA
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const [searchInput, setSearchInput] = useState([]);
  //FI CERCA



  const buscarDades = async () => {
    const com = await Api.get(`/summer/extern/comandes`);
    if(com.result.success===1){
      //console.log(com.data)
      return com.data;
    }

  }


  const marcarEliminat = async (id) => {
  const c = await Api.myFetch(`/summer/${id}`, 'DELETE');
    if(c.result.success===1){
      setRefrescar(refrescar+1)
    }
  }


  //CERCA
    const getColumnSearchProps = (dataIndex, nom) => ({
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <div style={{ padding: 8 }}>
          <Input
            ref={node => {
              let search = searchInput;
              search[dataIndex] = node;
              setSearchInput(search);
            }}
            placeholder={`${props.t('generic.cercar')} ${nom}`}
            value={selectedKeys[0]}
            onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
            style={{ width: 188, marginBottom: 8, display: 'block' }}
            autoFocus="focus"
          />
          <Space>
            <Button
              type="primary"
              onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
              icon={<SearchOutlined />}
              size="small"
              style={{ width: 90 }}
            >
              {props.t('generic.cercar')}
            </Button>
            <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
              Reset
            </Button>
          </Space>
        </div>
      ),
      filterIcon: filtered => <SearchOutlined style={{color: filtered ? '#1890ff' : undefined }} />,
      onFilter: (value, record) =>
        record[dataIndex] ? arreglarTxt4Search(entities.decode(record[dataIndex].toString())).includes(arreglarTxt4Search(value)) : '',
      onFilterDropdownVisibleChange: visible => {
        if (visible) {
          setTimeout(() => searchInput[dataIndex].select(),100);
        }
      },
     render: text => (entities.decode(text)),
    });

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
      confirm();
      setSearchText(selectedKeys[0]);
      setSearchedColumn(dataIndex);
    };

    const handleReset = clearFilters => {
      clearFilters();
      setSearchText('');
    };

    const arreglarTxt4Search = text =>{
        // Poso en minuscules, normalitzo caracters treure accents i ñ , elimina els caracters diacrítics raros d'un text (ES6)
        //return text.trim().split(' ')[0].toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g,"");
        return text.trim().toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g,"");
    }
  //FI CERCA



  const filtreEstat =[
  {text: <span><AlertFilled style={{color: `${props.t('botiga.estatColor.Pendent')}`}}/>&nbsp;PENDIENTE PAGO</span>, value: 'PENDIENTE PAGO'},
  {text: <span><AlertFilled style={{color: `${props.t('botiga.estatColor.Preparat')}`}}/>&nbsp;TPV revisar</span>, value: 'TPV revisar'},
  {text: <span><AlertFilled style={{color: `${props.t('botiga.estatColor.Facturat')}`}}/>&nbsp;{props.t('botiga.estat.Facturat')}</span>, value: 'Facturat'},
  {text: <span><AlertFilled style={{color: `${props.t('botiga.estatColor.Anulat')}`}}/>&nbsp;{props.t('botiga.estat.Anulat')}</span>, value: 'Anulat'}
  ];


const columns = [
{
  title:"Id",
  key:"id",
  sorter: (a, b) =>  a.id - b.id,
  render: (text,record) => (<span>{record.id}</span>),
  width: 10
},{
  title: props.t('botiga.titolEstat'),
  key:"estado",
  dataIndex: 'estado',
  filters: filtreEstat,
  defaultFilteredValue: ['PENDIENTE PAGO','TPV revisar'],
  onFilter: (value, record) => record.estado === value,
  render: (text,record) =>{
    return (<span>{record.estado}</span>);
  },
  sorter: (a, b) => { return a.estado.localeCompare(b.estado)}
},{
  title: props.t('generic.alumne'),
  key:"json.nens[0].nom",
  dataIndex: 'json.nens[0].nom',
  //render: (text,record) =>(entities.decode(record.json.pare.nom+' '+record.json.pare.cognoms))
  //render: (text,record) =>(<span>{record.json.nens[0].nom+' '+record.json.nens[0].cognoms}</span>),
  sorter: (a, b) => {
    console.log('sorter a: ', a.json.nens[0].alumne_complet)
    return (a.json.nens[0].nom+' '+a.json.nens[0].cognoms).localeCompare(b.json.nens[0].nom+' '+b.json.nens[0].cognoms)
  },
  render: (text,record) =>(<span>{record.json.nens[0].nom+' '+record.json.nens[0].cognoms}</span>)
},{
  title:"Num Alumnes",
  key:"json.nens.length",
  sorter: (a, b) =>  a.json.nens.length - b.json.nens.length,
  render: (text,record) => (<span>{record.json.nens.length}</span>)
},{
  title: "Pare",
  key:"json.pare.nom",
  dataIndex: 'json.pare.nom',
  //render: (text,record) =>(entities.decode(record.json.pare.nom+' '+record.json.pare.cognoms))
  render: (text,record) =>(<span>{record.json.pare.nom+' '+record.json.pare.cognoms}</span>)
},{
  title:props.t('generic.total'),
  key:"total_absol",
  dataIndex: 'total_absol',
  render: (text,record) => (<span>{record.total_absol}€</span>),
  sorter: (a, b) =>  a.total - b.total
},{
  title: 'Pagat',
  key:"ds_amount",
  dataIndex: 'ds_amount',
  render: (text,record) => (<span>{record.ds_amount/100}€</span>),
  sorter: (a, b) =>  a.ds_amount - b.ds_amount
},{
  title:props.t('generic.created_at'),
  key:"fcrea",
  sorter: (a, b) => moment(a.creat).diff(moment(b.creat)),
  //render:(text,record) => (record.creat ? <Moment date={moment(record.creat)} format="DD/MM/YYYY HH:mm"/> : "" )
  render:(text,record) => (record.fcrea ? <Moment date={moment(record.fcrea).tz('Europe/Madrid')} format="DD/MM/YYYY HH:mm"/> : "" )
},{
  title:'Email enviat',
  key:"email_enviat",
  sorter: (a, b) => moment(a.email_enviat).diff(moment(b.email_enviat)),
  //render:(text,record) => (record.creat ? <Moment date={moment(record.creat)} format="DD/MM/YYYY HH:mm"/> : "" )
  render:(text,record) => (record.email_enviat ? <Moment date={moment(record.email_enviat).tz('Europe/Madrid')} format="DD/MM/YYYY HH:mm"/> : "" )
}];


  return(
    <ContainerPagina xl={24}>
      <TitolPagina txt={props.t('menu.ComandesSummerCamp')+' EXT'}/>
      <div style={{marginTop:'1rem', marginLeft:'3rem'}}>
        <LlistaEditableModalNouEdit
          scroll={{ x: 1500}}
          txt_creats={props.t('comandes.creados')}
          txt_nou={props.t('comandes.nuevo')}
          modal_width={650}
          buscarDades={buscarDades}
          dadesMostradesCallback={(dadesMostrades) => {
            return(
              <div style={{marginTop: '1rem'}}>
                <ComandesSummerExternsExportaExcel dades={dadesMostrades} btn_txt="Export"/>
              </div>
            )
          }}
          refrescar={refrescar}
          edit={Edit}
          //add={Edit}
          duplica={false}
          delete={marcarEliminat}
          //itemBuit={InitDades.comandes_roba()}
          columns={columns}
          icon_creats={InboxOutlined}
        />

      </div>

    </ContainerPagina>
  );
}


export default translate(ComandesSummer);
