import React, { useState, useEffect, useContext } from 'react';
import moment from 'moment';
import 'moment/locale/ca';
import 'moment/locale/es';

import locale_ca from 'antd/es/date-picker/locale/ca_ES';
import locale_en from 'antd/es/date-picker/locale/en_GB';
import locale_es from 'antd/es/date-picker/locale/es_ES';

import { translate } from 'react-multi-lang';
import { Row, Col, Typography, Button, Card, Radio, InputNumber, Form, Select, Table, DatePicker, Space, Switch, message } from 'antd';
import { MinusOutlined, PlusOutlined, CarOutlined, ExperimentOutlined } from '@ant-design/icons';
import { AllHtmlEntities } from 'html-entities';

import { PerfilUsuariContext } from "../../../../context/perfil-usuari";

import { Api, HtmlStrings } from '../../../../helpers';
import AutoritzarContrasenya from '../../../AutoritzarContrasenya';
import TextAreaCount from '../../../TextAreaCount';

const entities = new AllHtmlEntities();

function AcollidaEditClasse(props){

  const [perfilUsuari, setPerfilUsuari] = useContext(PerfilUsuariContext);

  const id = props.item.id;
  const [id_alumne, setId_alumne] = useState(props.item.id_alumne);
  const [id_pare, setId_pare] = useState(props.item.id_pare);
  const [id_familia, setId_familia] = useState(props.item.id_familia);
  const [tipus, setTipus] = useState(props.item.tipus);
  //const [puntual_data, setPuntual_data] = useState(props.item.puntual_data ? props.item.puntual_data : moment.utc(new Date()));
  const [puntual_data, setPuntual_data] = useState(() => {
    let resultat
    console.log('props.diaTriat', props)
    if(props.item.puntual_data){
      resultat = props.item.puntual_data
    } else{
      if(props.diaTriat){
        resultat = moment.utc(props.diaTriat)
      }else{
        resultat = moment.utc(new Date())
      }
    }
    return resultat;
  });
  const [h_ini, setH_ini] = useState(props.item.h_ini);
  const [m_ini, setM_ini] = useState(props.item.m_ini);
  const [h_fin, setH_fin] = useState(props.item.h_fin);
  const [m_fin, setM_fin] = useState(props.item.m_fin);
  const [id_classe, setId_classe] = useState(props.item.id_classe);
  const [id_curs_escolar, setId_curs_escolar] = useState(props.id_curs_escolar > 0 ? props.id_curs_escolar :  perfilUsuari.curs_escolar_actual.id);
  const [descompte, setDescompte] = useState(props.item.descompte);

  const [classes, setClasses] = useState([]);
  const [classesOptions, setClassesOptions] = useState([]);
  const [paresOptions, setParesOptions] = useState([]);

  const [triats, setTriats] = useState([]);


  const [isLoadingClasse, setIsLoadingClasse] = useState(false);

  const [llistaAlumnes, setLlistaAlumnes] = useState([]);
  const [validat, setValidat] = useState(false);
  const [guardant, setGuardant] = useState(false);

  const [errorId_alumne, setErrorId_alumne] = useState('');
  const [errorId_alumneText, setErrorId_alumneText] = useState('');
  const [errorId_Pare, setErrorId_Pare] = useState('');
  const [errorId_PareText, setErrorId_PareText] = useState('');
  const [errorH_ini, setErrorH_ini] = useState('');
  const [errorH_fin, setErrorH_fin] = useState('');

  const nou = (props.item.id===0);


  const inicialitzaHores = () => {
    if(nou){
      const now = new Date();
      if(tipus==='mati'){
        setH_ini(now.getHours())
        setM_ini(now.getMinutes())
      }
      if(tipus==='tarda'){
        setH_fin(now.getHours())
        setM_fin(now.getMinutes())
      }
    }
  }

  const inicialitzaEdicio = () =>{
    if(id_alumne > 0){
      setTriats([id_alumne])
    }
  }


  useEffect(() => {
    inicialitzaHores();
    buscarClasses();
  },[props.t('idioma')]);


  useEffect(() => {
    buscarDadesAlumnes();
    if(!nou){
      inicialitzaEdicio();
    }
  },[id_classe]);

/*
  useEffect(() => {
    buscarDadesFamilia(id_alumne);
  },[id_alumne]);
*/
  useEffect(() => {
    buscarDadesPares();
  },[id_familia]);



  const buscarDadesAlumnes = async () => {
    setIsLoadingClasse(true);
    if(id_classe > 0){
      const f = await Api.get(`/alumnes/classe/${id_classe}`);
      if(f.result.success===1){
        if(nou){
          setId_pare(0);
          setId_familia(0);
          setParesOptions([]);
        }
        setLlistaAlumnes(f.data.alumnes);
      }else{
        setLlistaAlumnes([]);
        setParesOptions([]);
      }
    }
    setIsLoadingClasse(false);
  }

  const buscarDadesPares = async () => {
    if(id_familia > 0){
      const pares = await Api.get(`/pares/familia/${id_familia}`);
      if(pares.result.success===1){
        let pare = 0;
        const llista_pares = pares.data.map((item) =>{
              if(pare == 0 && props.item.id_familia !== id_familia){
                pare = item.id;
                if(nou){
                  setId_pare(pare);
                  setErrorId_Pare('');
                  setErrorId_PareText('');
                }
              }
              return(
                <Select.Option key={item.id} value={item.id}>
                  {entities.decode(item.nom) + ' ' + entities.decode(item.cognom1) + ' ' + entities.decode(item.cognom2)}
                </Select.Option>
              );
        });
        setParesOptions(llista_pares);
      }
    }
  }

  const buscarDadesFamilia = (alumne_id) =>{
    if(alumne_id > 0 && llistaAlumnes.length > 0){
      llistaAlumnes.map((alumne) =>{
        if(alumne.id == alumne_id){
          if(nou){
            setId_pare(0);
          }
          setId_familia(alumne.id_familia);
        }
      });
    }
  }

  const buscarClasses = async () => {
    let llista_classes = [];
    const clas = await Api.get('/classes');
    if(clas.result.success===1){
      //console.log(clas.data.classes)
      const llista_opt = clas.data.classes.map((item) =>{
        llista_classes.push(item);
        return(
          <Select.Option key={item.id} value={item.id}>
            {entities.decode(item.nom)}
          </Select.Option>
        );
      });
      setClassesOptions(llista_opt);
    }
    setClasses(llista_classes);
  }




  const submitForm = async (valors) => {

    let correcte = true;

    if(id_alumne===0){
      setErrorId_alumne('error');
      setErrorId_alumneText(props.t('generic.tria'));
      correcte = false;
    }
    if(id_pare===0){
      setErrorId_Pare('error');
      setErrorId_PareText(props.t('generic.tria'));
      correcte = false;
    }
    if(tipus==='mati'){
      if(h_ini===0){
        setErrorH_ini('error');
        correcte = false;
      }
      if(h_ini>9){
        setErrorH_ini('error');
        correcte = false;
      }
      if(h_ini===9 && m_ini > 0){
        setErrorH_ini('error');
        correcte = false;
      }
    }
    if(tipus==='tarda'){
      if(h_fin===0){
        setErrorH_fin('error');
        correcte = false;
      }
      if(h_fin<15){
        setErrorH_fin('error');
        correcte = false;
      }
      if(h_fin>20){
        setErrorH_fin('error');
        correcte = false;
      }
    }

    if(!correcte){
      return false;
    }

    setGuardant(true);

    let dades = {
      id_alumne,
      id_pare,
      tipus,
      puntual_data,
      h_ini,
      m_ini,
      h_fin,
      m_fin,
      id_curs_escolar,
      descompte
    }

    //console.log(dades);
    //console.log(JSON.stringify(dades));

    let method = nou?'POST':'PUT';
    let url_api = nou? '/acollidaminuts/comanda' : '/acollidaminuts/comanda/'+id;


    try{
      const resposta = await Api.myFetch(url_api, method, JSON.stringify(dades));
      if(resposta.result.success === 1){
        setGuardant(false);
        message.success(props.t('generic.OK_saved'), 5);
        props.callback();
      }else{
        setGuardant(false);
        console.error('Error: enviant notificacio: ',resposta);
        message.config({top: 50});
        message.open({content: resposta.data.message, duration:10});
      }
    }catch(error){
      setGuardant(false);
      message.config({top: 50});
      message.open({content: "Error saving", duration:10});
      console.error('Error: Enviant Notificacio: ', error);
    }

  }



  const changePuntual_data = (date, dateString) => {
    if(date){
      //Trec l'hora
      setPuntual_data(date.format(moment.HTML5_FMT.DATE));
    }else{
      setPuntual_data(null);
    }
  }
  const changeClasse = (value) => {
    setId_classe(value);
    const cl = classes.find((element,index)=> element.id === value);
    if(tipus === 'mati'){
      setH_fin(cl.h_ini);
      setM_fin(cl.m_ini);
    }
    if(tipus === 'tarda'){
      setH_ini(cl.h_fin);
      setM_ini(cl.m_fin);
    }
  }

  const triaPare = (value) =>{
    setId_pare(value);
    if(value>0){
      setErrorId_Pare('');
      setErrorId_PareText('');
    }
  }
  const origenExtraescolar = (value) =>{
    setDescompte(value ? 100 : 0);
  }

  const arreglarTxt4Search = text =>{
      // Poso en minuscules, normalitzo caracters treure accents i ñ , elimina els caracters diacrítics raros d'un text (ES6)
        return text.trim().toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g,"");
  }

  let localeDatePicker;
  switch (props.t('idioma')) {
    case 'ca':
      localeDatePicker = locale_ca;
      break;
    case 'en':
      localeDatePicker = locale_en;
      break;
    case 'es':
      localeDatePicker = locale_es;
      break;
    default:
      localeDatePicker = locale_ca;
  }
  const dateFormat = 'DD/MM/YYYY';

  return(
    <>
      <Form onFinish={submitForm} layout="vertical" autoComplete="off">
      <Row justify="space-between" align="middle">
        <Col>
          <Form.Item label={props.t('generic.data')} required={true}>
            <DatePicker
              onChange={changePuntual_data}
              value={puntual_data ? moment(new Date(puntual_data), dateFormat) : ''}
              format={dateFormat}
              locale={localeDatePicker}
              picker="date"
              />
          </Form.Item>
        </Col>
        <Col>
          { tipus === 'mati'
          ?
            <Form.Item label={props.t('generic.hini')} required={true} validateStatus={errorH_ini}>
                <InputNumber name="hora" onChange={setH_ini} value={h_ini} min={0} max={23} step={1} formatter={value => `${value}h`} parser={value => value.replace('h', '')}/>
                <span>&nbsp;:&nbsp;</span>
                <InputNumber name="minut" onChange={setM_ini} value={m_ini} min={0} max={59} step={1} formatter={value => `${value}m`} parser={value => value.replace('m', '')}/>
            </Form.Item>
          :
            <Form.Item label={props.t('generic.hfin')} required={true} validateStatus={errorH_fin}>
                <InputNumber name="hora" onChange={setH_fin} value={h_fin} min={0} max={23} step={1} formatter={value => `${value}h`} parser={value => value.replace('h', '')}/>
                <span>&nbsp;:&nbsp;</span>
                <InputNumber name="minut" onChange={setM_fin} value={m_fin} min={0} max={59} step={1} formatter={value => `${value}m`} parser={value => value.replace('m', '')}/>
            </Form.Item>
          }

        </Col>
      </Row>
      { tipus === 'mati' ? null :
        <Row style={{marginBottom: 10}}>
          <Space>
            <span>{props.t('acollida.origenExtraescolar')}</span>
            <Switch checked={descompte===100} onChange={origenExtraescolar} checkedChildren={props.t('generic.si')} unCheckedChildren={props.t('generic.no')}/>
          </Space>
        </Row>
    }
        <Form.Item label={props.t('alumnes.classe')}>
          <Select onChange={(value) => changeClasse(value)}
            value={id_classe===0 ? '' : id_classe}
            showSearch
            filterOption={(input, option) =>
              HtmlStrings.normalize(option.children).includes(HtmlStrings.normalize(input))
            }
          >
            {classesOptions}
          </Select>
        </Form.Item>
        <Form.Item
          validateStatus={errorId_alumne}
          help={errorId_alumneText}
          label={props.t('notificacions.alumne')}
          required={true}
          style={{marginBottom: 0}}

        >
        <div style={errorId_alumne ==='' ? null : {border: '1px solid red'}}>        
          <Table
            style={{marginBottom: '1rem'}}
            size="small"
            loading={isLoadingClasse}
            rowKey="id"
            dataSource={llistaAlumnes}
            columns={[
              {
                title: props.t('menu.Alumnes'),
                key: "nom",
                dataIndex: "nom",
                render: (text,record) => (entities.decode(record.nom)+' '+entities.decode(record.cognom1)+' '+entities.decode(record.cognom2))
              }
            ]}
            pagination={false}
            rowSelection={{
            selectedRowKeys: triats,
            hideSelectAll: false,
            type: 'radio',
            onChange: (selectedRowKeys, selectedRows) => {
              console.log(selectedRowKeys)
              if(selectedRowKeys.length > 0){
                setErrorId_alumne('');
                setErrorId_alumneText('');
              }
              setTriats(selectedRowKeys);
              setId_alumne(selectedRowKeys[0]);
              buscarDadesFamilia(selectedRowKeys[0]);
            }
          }}/>
        </div>
        </Form.Item>


        {id_familia <=0 ? null :
          <>
            <Form.Item label={props.t('generic.responsable')} required={true} validateStatus={errorId_Pare} help={errorId_PareText}>
              <Select defaultActiveFirstOption="true"
                value={id_pare===0 ? '' : id_pare} onChange={(value) => triaPare(value)}
                showSearch
                filterOption={(input, option) =>
                  HtmlStrings.normalize(option.children).includes(HtmlStrings.normalize(input))
                }
              >
                {paresOptions}
              </Select>
            </Form.Item>
          </>
        }

        <Row type="flex" justify="start" style={{marginTop: 25, marginBottom: 25}}>
          <Col>
            <Button onClick={()=>props.cancel ? props.cancel() : props.callback()}>{props.t('generic.cancel')}</Button>
          </Col>
          <Col style={{marginLeft:'10px'}}>
            <Button type="primary" htmlType="submit" loading={guardant}>{props.t('generic.save')}</Button>
          </Col>
        </Row>
      </Form>
    </>
  );
}
export default translate(AcollidaEditClasse);
