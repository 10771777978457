import React, { useState, useEffect } from "react";
import ReactExport from "react-export-excel";
import { translate } from 'react-multi-lang';
import { SyncOutlined, PercentageOutlined, LeftOutlined, EditOutlined, DeleteOutlined, SearchOutlined, FileExcelOutlined } from '@ant-design/icons';
import {Table, Tooltip, Popconfirm, Modal, Form, Row, Col, Select, Button, Input, InputNumber, Divider, Switch, DatePicker, Space, message } from 'antd';

import { AllHtmlEntities } from 'html-entities';
import moment from 'moment';
import 'moment/locale/ca';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const entities = new AllHtmlEntities();

function ComandesExcursionsExportaExcel(props){

  const [dades, setDades] = useState(props.dades);
  const nom_fulla = props.nom_fulla ? props.nom_fulla : props.t('menu.ComandesExcursions');
  const nom_fitxer = props.nom_fitxer ? props.nom_fitxer : props.t('menu.ComandesExcursions');

  useEffect(() => {

    const dades_temp = props.dades.map(item =>{
      let tel = '';
      let email = '';
      let pare = '';
      if(item.responsables){
        item.responsables = item.responsables.sort((a,b) => a.id_relacio_familiar - b.id_relacio_familiar);

        if(item.responsables.length > 0){
          tel = item.responsables[0].tel1 ? item.responsables[0].tel1 : item.responsables[0].tel2;
          tel = tel ? tel : '';
          email = item.responsables[0].email ? item.responsables[0].email : '';
          pare = item.responsables[0].nom ? item.responsables[0].nom : '';
        }
      }
      item.telefon1 = `${tel}`;
      item.email1 = email;
      item.responsable1 = entities.decode(pare);
      tel = '';
      email = '';
      pare = '';
      if(item.responsables){
        if(item.responsables.length > 1){
          tel = item.responsables[1].tel1 ? item.responsables[1].tel1 : item.responsables[1].tel2;
          tel = tel ? tel : '';
          email = item.responsables[1].email ? item.responsables[1].email : '';
          pare = item.responsables[1].nom ? item.responsables[1].nom: '';
        }
      }
      item.telefon2 = `${tel}`;
      item.email2 = email;
      item.responsable2 = entities.decode(pare);

      return item;
    });


    setDades(props.dades)
  },[props.dades]);

  console.log("dins exporta: ", props.dades)

  return (
      <ExcelFile
        filename={nom_fitxer}
        element={<Button type="primary" style={{backgroundColor: '#217346', borderColor: '#217346', marginBottom: '0.5rem', marginLeft: '0.5rem', marginRight: '0.5rem'}} shape="round" icon={<FileExcelOutlined />}>{props.btn_txt}</Button>}>
          <ExcelSheet data={dades} name={nom_fulla}>
            <ExcelColumn label="Id" value="id"/>
            <ExcelColumn label={props.t('botiga.titolEstat')} value={(col) => props.t(`botiga.estat.${col.estat}`)}/>
            <ExcelColumn label={props.t('botiga.producte')} value={(col) => entities.decode(col.nom[props.t('idioma')])}/>
            <ExcelColumn label={props.t('botiga.data')} value={(col) => moment(col.data_hora_esdeveniment).tz('Europe/Madrid').format('DD/MM/YYYY HH:mm')}/>
            <ExcelColumn label={props.t('alumnes.classe')} value={(col) => entities.decode(col.classe)}/>
            <ExcelColumn label={props.t('generic.alumne')} value={(col) => entities.decode(col.alumne_complet)}/>
            <ExcelColumn label={props.t('generic.responsable')+'1'} value={"responsable1"}/>
            <ExcelColumn label={props.t('pares.tel1')} value={"telefon1"}/>
            <ExcelColumn label={props.t('pares.email')+'1'} value={"email1"}/>
            <ExcelColumn label={props.t('generic.responsable')+'2'} value={"responsable2"}/>
            <ExcelColumn label={props.t('pares.tel2')} value={"telefon2"}/>
            <ExcelColumn label={props.t('pares.email')+'2'} value={"email2"}/>
            <ExcelColumn label={props.t('generic.total')} value={(col) => parseFloat(col.total)}/>
            <ExcelColumn label={props.t('generic.created_at')} value={(col) => moment(col.creat).tz('Europe/Madrid').format('DD/MM/YYYY')}/>
          </ExcelSheet>
      </ExcelFile>
  );

}

export default translate(ComandesExcursionsExportaExcel);
