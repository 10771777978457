import React, { useState, useEffect, useContext } from 'react';
import { translate } from 'react-multi-lang';
import { Row, Col, Alert, Calendar, Button, Modal } from 'antd';
import locale_ca from 'antd/es/date-picker/locale/ca_ES';

import { MenuTriatContext } from "../context/menu-triat";
import { PerfilUsuariContext } from "../context/perfil-usuari";
import Bus from '../icons/bus.png';
import {ReactComponent as Botiga} from '../icons/icones_app_BSCD-01.svg';
import {ReactComponent as Serveis} from '../icons/icones_app_BSCD-02.svg';
import {ReactComponent as Dades} from '../icons/icones_app_BSCD-03.svg';
import {ReactComponent as Metge} from '../icons/icones_app_BSCD-07.svg';
import {ReactComponent as Documents} from '../icons/icones_app_BSCD-05.svg';
import {ReactComponent as Notificar} from '../icons/icones_app_BSCD-04.svg';
import {ReactComponent as Expedient} from '../icons/icones_app_BSCD-06.svg';
import {ReactComponent as Rebudes} from '../icons/icones_app_BSCD-08.svg';
import { Api } from '../helpers';
import NewPassword from '../components/PantallesObligadesInici/NewPassword';
import ProteccioDades from '../components/PantallesObligadesInici/ProteccioDades';
import PermetrePublicitat from '../components/PantallesObligadesInici/PermetrePublicitat';
import UsImatges from '../components/PantallesObligadesInici/UsImatges';
import Vacunacio from '../components/PantallesObligadesInici/Vacunacio';
import ServeiMedic from '../components/PantallesObligadesInici/ServeiMedic';

import PermetreEstudiPsicologic from '../components/PantallesObligadesInici/PermetreEstudiPsicologic';

function DashboardPares(props){


  const [perfilUsuari, setPerfilUsuari] = useContext(PerfilUsuariContext);
  const [menuTriat, setMenuTriat] = useContext(MenuTriatContext);
  const [modalVisible, setModalVisible] = useState(false);

  const [mostrar, setMostrar] = useState(1);
  const [obligar_modificar_password, setObligar_modificar_password] = useState(false);
  const [obligar_acceptar_proteccio_dades, setObligar_acceptar_proteccio_dades] = useState(false);
  const [obligar_respondre_permetre_publicitat, setObligar_respondre_permetre_publicitat] = useState(false);
  const [obligar_respondre_us_imatges, setObligar_respondre_us_imatges] = useState(false);
  const [obligar_respondre_vacunacio, setObligar_respondre_vacunacio] = useState(false);
  const [recordar_omplir_medics, setRecordar_omplir_medics] = useState(false);
  const [obligar_respondre_permetre_estudi_psicologic, setObligar_respondre_permetre_estudi_psicologic] = useState(false);




  const buscarDades = async () => {
    const resp = await Api.get('/resposta/pendents/pantalla/'+perfilUsuari.account.id);
    if(resp.result.success===1){

      console.log('resp.data.pantalles_inici: ', resp.data.pantalles_inici)
      setObligar_modificar_password(resp.data.pantalles_inici.obligar_modificar_password);
      //setObligar_modificar_password(true);
      setObligar_acceptar_proteccio_dades(resp.data.pantalles_inici.obligar_acceptar_proteccio_dades);
      //setObligar_acceptar_proteccio_dades(true);
      setObligar_respondre_permetre_publicitat(resp.data.pantalles_inici.obligar_respondre_permetre_publicitat);
      //setObligar_respondre_permetre_publicitat(true);
      setObligar_respondre_us_imatges(resp.data.pantalles_inici.obligar_respondre_us_imatges);
      //setObligar_respondre_us_imatges(true);
      setObligar_respondre_vacunacio(resp.data.pantalles_inici.obligar_respondre_vacunacio);
      //setObligar_respondre_vacunacio(true);
      setRecordar_omplir_medics(resp.data.pantalles_inici.recordar_omplir_medics);
      //setRecordar_omplir_medics(true);
      setObligar_respondre_permetre_estudi_psicologic(resp.data.pantalles_inici.obligar_respondre_permetre_estudi_psicologic);
    }
  }

  useEffect(() => {
    buscarDades();
  },[]);


  useEffect(() =>{
    if(obligar_modificar_password){
      setMostrar(1);
    }else{
      if(obligar_acceptar_proteccio_dades){
        setMostrar(2);
      }else{
        if(obligar_respondre_permetre_publicitat){
          setMostrar(3);
        }else{
          if(obligar_respondre_us_imatges){
            setMostrar(4);
          }else{
            if(obligar_respondre_vacunacio){
              setMostrar(5);
            }else{
              if(recordar_omplir_medics){
                setMostrar(6);
              }else{
                if(obligar_respondre_permetre_estudi_psicologic){
                  console.log('Estudi mostra')
                  setMostrar(7);
                }else{
                  console.log('Estudi NO mostra')
                  setMostrar(0);
                }
              }
            }
          }
        }
      }
    }
  },[obligar_modificar_password, obligar_acceptar_proteccio_dades, obligar_respondre_permetre_publicitat, obligar_respondre_us_imatges, obligar_respondre_vacunacio,recordar_omplir_medics, obligar_respondre_permetre_estudi_psicologic])


  const newPasswordOK = () =>{
    setObligar_modificar_password(false);
  }
  const proteccioDadesOK = () =>{
    setObligar_acceptar_proteccio_dades(false);
  }
  const respostaPermetrePublicitat = () =>{
    setObligar_respondre_permetre_publicitat(false);
  }
  const respostaUsImatges = () =>{
    setObligar_respondre_us_imatges(false);
  }

  const respostaVacunacio = () =>{
    setObligar_respondre_vacunacio(false);
  }
  const respostaMedic = () =>{
    setRecordar_omplir_medics(false);
  }

  const respostaPermetreEstudiPsicologic = () =>{
    setObligar_respondre_permetre_estudi_psicologic(false);
  }


  const tancarModal = e =>{
    setModalVisible(false);
  }


  const mides = {width: '100%',height: '100px'};
  const color1 = {width: mides.width, height: mides.height, backgroundColor: '#bbdefb', borderColor: '#bbdefb', color: 'rgba(0,0,0,0.87)'};
  const color2 = {width: mides.width, height: mides.height, backgroundColor: '#c5cae9', borderColor: '#c5cae9', color: 'rgba(0,0,0,0.87)'};
  const color3 = {width: mides.width, height: mides.height, backgroundColor: '#b2ebf2', borderColor: '#b2ebf2', color: 'rgba(0,0,0,0.87)'};
  const color4 = {width: mides.width, height: mides.height, backgroundColor: '#448aff', borderColor: '#448aff', color: 'rgba(0,0,0,0.87)'};
  const color5 = {width: mides.width, height: mides.height, backgroundColor: '#ef9a9a', borderColor: '#ef9a9a', color: 'rgba(0,0,0,0.87)'};
  const color6 = {width: mides.width, height: mides.height, backgroundColor: '#eeff41', borderColor: '#eeff41', color: 'rgba(0,0,0,0.87)'};
  const color7 = {width: mides.width, height: mides.height, backgroundColor: '#76FF03', borderColor: '#76FF03', color: 'rgba(0,0,0,0.87)'};

return(
  <div>

    <Modal
    key="NewPassword"
    visible={obligar_modificar_password && mostrar == 1}
    footer={null}
    width={550}
    style={{top:20}}
    bodyStyle={{ height: "350px"}}
    destroyOnClose={true}
    forceRender={true}
    maskClosable={false}
    closable={false}
    >
      <NewPassword callback={newPasswordOK}/>
    </Modal>

    <Modal
    key="ProteccioDades"
    visible={obligar_acceptar_proteccio_dades && mostrar == 2}
    footer={null}
    width={600}
    style={{top:20}}
    bodyStyle={{ overflow: "auto"}}
    destroyOnClose={true}
    forceRender={true}
    maskClosable={false}
    closable={false}
    >
      <ProteccioDades callback={proteccioDadesOK}/>
    </Modal>
    <Modal
    key="PermetrePublicitat"
    visible={obligar_respondre_permetre_publicitat && mostrar == 3}
    footer={null}
    width={600}
    style={{top:20}}
    bodyStyle={{ overflow: "auto"}}
    destroyOnClose={true}
    forceRender={true}
    maskClosable={false}
    closable={false}
    >
      <PermetrePublicitat callback={respostaPermetrePublicitat}/>
    </Modal>
    <Modal
    key="UsImatges"
    visible={obligar_respondre_us_imatges && mostrar == 4}
    footer={null}
    width={600}
    style={{top:20}}
    bodyStyle={{ overflow: "auto"}}
    destroyOnClose={true}
    forceRender={true}
    maskClosable={false}
    closable={false}
    >
      <UsImatges callback={respostaUsImatges}/>
    </Modal>
    <Modal
    key="ObligVacunacio"
    visible={obligar_respondre_vacunacio && mostrar == 5}
    footer={null}
    width={650}
    style={{top:20}}
    bodyStyle={{ overflow: "auto"}}
    destroyOnClose={true}
    forceRender={true}
    maskClosable={false}
    closable={false}
    >
      <Vacunacio callback={respostaVacunacio}/>
    </Modal>
    <Modal
    key="RecordaMedics"
    visible={recordar_omplir_medics && mostrar == 6}
    footer={null}
    width={850}
    style={{top:10}}
    bodyStyle={{ overflow: "auto"}}
    destroyOnClose={true}
    forceRender={true}
    maskClosable={false}
    closable={true}
    onCancel={respostaMedic}
    >
      <ServeiMedic callback={respostaMedic}/>
    </Modal>
    <Modal
    key="PermetreEstudiPsicologic"
    visible={obligar_respondre_permetre_estudi_psicologic && mostrar == 7}
    footer={null}
    width={600}
    style={{top:20}}
    bodyStyle={{ overflow: "auto"}}
    destroyOnClose={true}
    forceRender={true}
    maskClosable={false}
    closable={false}
    >
      <PermetreEstudiPsicologic callback={respostaPermetreEstudiPsicologic}/>
    </Modal>



          <Row type="flex" justify="start" style={{padding: '25px'}} gutter={[16,16]}>
          <Col xs={24} sm={12} md={8} lg={6} xl={6}>
            <Button type="primary" size="large" style={color4} onClick={(e) => setMenuTriat("Pares_Botiga")}>{props.t('menu.Botiga')}</Button>
          </Col>
            <Col xs={24} sm={12} md={8} lg={6} xl={6}>
              <Button type="primary" size="large" style={color1} onClick={(e) => setMenuTriat("Pares_DadesPersonals")}>{props.t('menu.DadesPersonals')}</Button>
            </Col>
            <Col xs={24} sm={12} md={8} lg={6} xl={6}>
              <Button type="primary" size="large" style={color2} onClick={(e) => setMenuTriat("Pares_DadesFills")}>{props.t('menu.DadesFills')}</Button>
            </Col>
            <Col xs={24} sm={12} md={8} lg={6} xl={6}>
              <Button type="primary" size="large" style={color3} onClick={(e) => setMenuTriat("Pares_DadesFamilia")}>{props.t('menu.DadesFamilia')}</Button>
            </Col>
            {/*<Col xs={24} sm={12} md={8} lg={6} xl={6}>
              <Button type="primary" size="large" style={color4} onClick={(e) => setMenuTriat("Pares_DadesFacturacio")}>{props.t('menu.DadesFacturacio')}</Button>
            </Col>*/}
            <Col xs={24} sm={12} md={8} lg={6} xl={6}>
              <Button type="primary" size="large" style={color5} onClick={(e) => setMenuTriat("Pares_ServeiMedic")}>{props.t('menu.ServeiMedic')}</Button>
            </Col>
            <Col xs={24} sm={12} md={8} lg={6} xl={6}>
              <Button type="primary" size="large" style={color6} onClick={(e) => setMenuTriat("Pares_ElsRebuts")}>{props.t('menu.ElsMeusRebuts')}</Button>
            </Col>
            <Col xs={24} sm={12} md={8} lg={6} xl={6}>
              <Button type="primary" size="large" style={color7} onClick={(e) => setMenuTriat("Pares_Documents")}>{props.t('menu.Documents')}</Button>
            </Col>
          </Row>

  </div>
);
}

export default translate(DashboardPares);
