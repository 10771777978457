import React, { useState, useEffect, useContext } from 'react';
import { translate } from 'react-multi-lang';
import { Table, Radio, Row, Col } from 'antd';
import { SyncOutlined } from '@ant-design/icons';
import { AllHtmlEntities } from 'html-entities';
import { PerfilUsuariContext } from "../../../context/perfil-usuari";
import ContainerPagina from '../../../components/ContainerPagina';
import TitolPagina from '../../../components/TitolPagina';

import { Api, InitDades } from '../../../helpers';

const { Column } = Table;
const entities = new AllHtmlEntities();

function RebutsLlista(props) {

  const [linies, setLinies]=useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const buscarDades = async () => {
    setIsLoading(true);
    const reb = await Api.get(`/rebuts/${props.id_alumne}`);
    //console.log(reb)
    if(reb.result.success===1){
      setLinies(reb.data.rebuts);
    }
    setIsLoading(false);
  }

  useEffect(() => {
    buscarDades();
  },[props.id_alumne, props.idioma]);

  const columnesTaulaDetall = [
      /*{
        title: props.t('facturacio.origen'),
        dataIndex: "taula",
        key: "taula",
        render: (text,record) => {
          let taula = record.taula ? props.t(`facturacio.taula.${record.taula}`) : 'Manual';
          return <span>{taula}</span>
        }
      },*/
      { title: props.t('generic.concepte'), dataIndex: "concepte", key: "concepte",
        render: (text,record) =><span>{entities.decode(record.concepte)}</span>
      },
      { title: props.t('generic.preu'), dataIndex: "preu", key: "preu",
        render: (text,record) =><span>{record.preu}€</span>
      },
      { title: props.t('generic.unitats'), dataIndex: "unitats", key: "unitats",
        render: (text,record) =><span>{record.unitats}</span>
      },
      { title: props.t('generic.descompte'), dataIndex: "descompte", key: "descompte",
        render: (text,record) =><span>{record.descompte}%</span>
      },
      { title: props.t('generic.subtotal'), key: "subtotal",
        render: (text,record) =><span>{record.subtotal}€</span>
      }
    ];
  const subTaulaDetall = row => {
    return (
      <Row style={{margin: '1rem'}}>
        <Col span={22}>
          <Table columns={columnesTaulaDetall} dataSource={row.linies} pagination={false} size={'small'} bordered={false} />
          </Col>
      </Row>
    );
  };





  return(
    <ContainerPagina xl={24}>
      <div style={{marginTop:'1rem', marginLeft:'3rem'}}>
        <Table
          rowKey="id_rebut"
          pagination={false}
          loading={isLoading}
          dataSource={linies}
          expandedRowRender={subTaulaDetall}
          expandRowByClick={true}
          defaultExpandAllRows={false}
          size="small"
          rowClassName={(record, index) => (["rebutjat", "impagat"].includes(record.estat) ? "taula-fila-vermella" : null)}
          >
          <Column
          title={props.t('generic.alumne')}
          key="alumne"
          render={(text,record) =>(
            entities.decode(record.alumne)
          )}
          />
          <Column
          title={props.t('banc.iban')}
          key="iban"
          render={(text,record) => (
            record.iban
          )}
          />
          <Column
          title={props.t('banc.titular')}
          key="titular"
          render={(text,record) =>(
            entities.decode(record.titular)
          )}
          />
          <Column
          title={props.t('generic.curs_escolar')}
          key="curs"
          render={(text,record) =>(
            entities.decode(record.curs)
          )}
          />
          <Column
          title={props.t('menu.Rebuts')}
          render={(text,record) =>(
            <span>{record.id_pressupost.toString()+'-'+record.id_rebut.toString()}</span>
          )}
          />
          <Column
          title={props.t('generic.mes')}
          key="mes"
          render={(text,record) =>(
            props.t(`mesos.${record.mes}`)
          )}
          />
          <Column
          title={props.t('generic.total')}
          key="import"
          render={(text,record) =>(
            <span>{record.import}€</span>
          )}
          />

        </Table>
      </div>
    </ContainerPagina>
  );

}

function Rebuts(props){
  const [isLoading, setIsLoading] = useState(true);
  const [fillActual, setFillActual] = useState(1);
  const [llistaFills, setLlistaFills] = useState([]);
  const [idsFills, setIdsFills] = useState([]);
  const [perfilUsuari, setPerfilUsuari] = useContext(PerfilUsuariContext);

  const buscarDades = async () => {
    setIsLoading(true);
    let llista_f = [];
    const fills = await Api.get(`/alumnes/familia/${perfilUsuari.account.id_familia}`);
    //console.log('dins: buscarDadesFills',fills)
    if(fills.result.success===1){
      setFillActual(fills.data.alumnes[0].id);
      let array_id_fills = [];
      llista_f = fills.data.alumnes.map((item) =>{
        //console.log(item)
        array_id_fills.push(item.id);
        return(
          <Radio.Button key={item.id} value={item.id}>{entities.decode(item.nom)}</Radio.Button>
        );
      });
      setLlistaFills(llista_f);
      setIdsFills(array_id_fills);
    }
    setIsLoading(false);
  }
  useEffect(() => {
    buscarDades();
  },[]);

  return (
    <ContainerPagina xl={24}>

      <TitolPagina txt={props.t('menu.ElsMeusRebuts')}/>
      <div style={{marginTop:'1rem', marginLeft:'3rem'}}>
      {
        isLoading ? <SyncOutlined spin title="Loading..." style={{fontSize: '3rem'}} /> :
        <>
          <Radio.Group defaultValue={fillActual} buttonStyle="solid" onChange={(e) => setFillActual(e.target.value)}>
            {llistaFills}
          </Radio.Group>
          <RebutsLlista id_alumne={fillActual} ids_fills={idsFills} t={props.t} idioma={props.t('idioma')}/>

        </>
      }
      </div>
    </ContainerPagina>
  );
}
export default translate(Rebuts);
