import React from "react";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
//import Favicon from 'react-favicon';
import './App.css';
import Login from './pages/Login';
import Logout from './pages/Logout';
import LoginRecover from './pages/LoginRecover';
import LoginRecoverEmail from './pages/LoginRecoverEmail';
import Index from './pages/Index';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';

import { getLanguage } from 'react-multi-lang'
import { ConfigProvider } from 'antd';
import ca from 'antd/es/locale-provider/ca_ES';
import es from 'antd/es/locale-provider/es_ES';
import en from 'antd/es/locale-provider/en_GB';
import moment from 'moment';
import 'moment/locale/es';
import 'moment/locale/ca';
import 'moment/locale/en-gb';

import { PerfilUsuariProvider } from "./context/perfil-usuari";
import { MenuTriatProvider } from "./context/menu-triat";


const FormLogin = Form.create({ name: 'normal_login' })(Login);


class App extends React.Component{
    constructor() {
      super();
      if (process.env.NODE_ENV !== "production"){
        this.state = {
          login: false
        }
      }else{
        this.state = {
          login: false
        }
      }

    }

// prevenir enrera button
    handleWindowClose = (ev) => {
            ev.preventDefault();
            return ev.returnValue = 'Leaving this page will loose data';
    }

    onBackButtonEvent = (e) => {
        e.preventDefault();
        window.history.forward();
    }
    componentDidMount = () => {
      if (process.env.NODE_ENV === "production"){
        window.addEventListener('beforeunload',this.handleWindowClose);
        window.addEventListener('popstate',this.onBackButtonEvent);
      }
    }
    componentWillUnmount = () => {
      if (process.env.NODE_ENV === "production"){
        window.removeEventListener('beforeunload',this.handleWindowClose);
        window.removeEventListener('popstate',this.onBackButtonEvent);
      }
    }
// FI prevenir enrera button
    changeLogin = () =>{
      console.log("app.js change login")
      this.setState({login: !this.state.login});

    }
    logOut = () =>{
      this.setState({login: false});
    }

    render(){
      moment.locale('ca');
      let language = getLanguage();
      const lang = sessionStorage.getItem('Language');
      if(lang){
        //language = lang==="ca"?ca:es;
        switch (lang) {
          case "ca":
            language=ca;
            break;
          case "es":
            language=es;
            break;
          case "en":
            language=en;
            break;
          default:
            language=es;
        }
      }

/*
      let url_favicon = "";

      switch (global.app_id) {
        case "british":
            url_favicon='https://britishcostadaurada.com/img/favicon.png';
          break;
        default:
          url_favicon ='/favicon.ico';
      }
*/

      return (
        <BrowserRouter>
          <PerfilUsuariProvider>
            <MenuTriatProvider>
              <ConfigProvider locale={language}>
              {/*<Favicon url={url_favicon} />*/}
                <Switch>
                <Route exact path="/">
                { this.state.login ?
                  <Index logout={this.logOut}/>
                  :
                  <Redirect to='/login' />
                }
                </Route>
                <Route path="/login">
                  { this.state.login ?
                    <Redirect to='/' />
                    :
                    <FormLogin callback={this.changeLogin} className="App"/>
                  }
                </Route>
                <Route path="/logout" children={<Logout callback={this.logOut}/>} />
                <Route path="/email" children={<LoginRecoverEmail />} />
                <Route path="/recover/:idsesion" render={(props) => <LoginRecover {...props}/>} />
                <Route path="/:idsesion" render={(props) => <LoginRecover {...props}/>} />
                <Redirect to="/login" />

                  {/* this.state.login ? <Index logout={this.logOut}/> : <FormLogin callback={this.changeLogin} className="App"/>*/}
                </Switch>
              </ConfigProvider>
            </MenuTriatProvider>
          </PerfilUsuariProvider>
        </BrowserRouter>
    );
  }
}

export default App;
