import React, { useState } from 'react';
import moment from 'moment';
import 'moment/locale/ca';
import 'moment/locale/es';

import locale_ca from 'antd/es/date-picker/locale/ca_ES';
import locale_en from 'antd/es/date-picker/locale/en_GB';
import locale_es from 'antd/es/date-picker/locale/es_ES';

import { translate } from 'react-multi-lang';
import { SyncOutlined, PercentageOutlined, LeftOutlined } from '@ant-design/icons';
//import { Form } from '@ant-design/compatible';
//import '@ant-design/compatible/assets/index.css';
import { Form, Row, Col, DatePicker, Switch, Button, Input, InputNumber, message } from 'antd';

import { AllHtmlEntities } from 'html-entities';

import { Api } from '../../helpers';
import ImageCropper from '../ImageCropper';
import VisorImatges from "../visorImatges";
import NomDescripcioIdiomes from '../NomDescripcioIdiomes';
import TriaCursos from '../TriaCursos';
import PropietatsLlista from '../../pages/botiga/Propietats';
import PropietatsTallesLlista from '../../pages/botiga/PropietatsTalles';

const entities = new AllHtmlEntities();
const dateFormat = 'DD/MM/YYYY HH:mm';
//const dateFormatTornada = 'YYYY-MM-DD HH:mm';

function Edit(props){

  moment.locale('es');

  const [id_curs_escolar, setId_curs_escolar] = useState(props.id_curs_escolar);
  const [id, setId] = useState(props.item.id);
  const [id_categoria] = useState(props.item.id_categoria);
  const [producte_visible, setProducte_visible] = useState(props.item.visible);
  const [mode_test, setMode_test] = useState(props.item.mode_test);
  //const [ref, setRef] = useState(props.item.ref ? entities.decode(props.item.ref) : null);
  const [ref, setRef] = useState(entities.decode(props.item.ref));
  const [nom_ca, setNom_ca] = useState(entities.decode(props.item.nom['ca']));
  const [nom_en, setNom_en] = useState(entities.decode(props.item.nom['en']));
  const [nom_es, setNom_es] = useState(entities.decode(props.item.nom['es']));
  const [descripcio_ca, setDescripcio_ca] = useState(entities.decode(props.item.descripcio['ca']));
  const [descripcio_en, setDescripcio_en] = useState(entities.decode(props.item.descripcio['en']));
  const [descripcio_es, setDescripcio_es] = useState(entities.decode(props.item.descripcio['es']));
  const [url_image, setUrl_image] = useState(props.item.url_image);
  const [preu, setPreu] = useState(props.item.preu);
  const [descompte, setDescompte] = useState(props.item.descompte);
  const [descompte_x_unitats_num, setDescompte_x_unitats_num] = useState(props.item.descompte_x_unitats_num);
  const [descompte_x_unitats_percent, setDescompte_x_unitats_percent] = useState(props.item.descompte_x_unitats_percent);
  const [id_tipus_propietats, setId_tipus_propietats] = useState(props.item.id_tipus_propietats);
  const [data_hora_esdeveniment, setData_hora_esdeveniment] = useState(props.item.data_hora_esdeveniment);
  const [fini, setFini] = useState(props.item.fini);
  const [ffin, setFfin] = useState(props.item.ffin);
  const [cursos, setCursos] = useState(props.item.cursos || []);

  const [noCaduca, setNoCaduca] = useState((fini!==null && ffin!=null) ? false : true);
  const [img_dataUrl, setImg_dataUrl] = useState();

  //const [tipusPropietats, setTipusPropietats] = useState([]);

  const [visor, setVisor] = useState({visible: false});
  const [guardant, setGuardant] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [errorNom_ca, setErrorNom_ca] = useState('');
  const [errorNom_en, setErrorNom_en] = useState('');
  const [errorNom_es, setErrorNom_es] = useState('');
  const [errorSelect, setErrorSelect] = useState('');
  const [errorSelectText, setErrorSelectText] = useState('');
  const [errorTriaCursos, setErrorTriaCursos] = useState('');
  const [errorTriaCursosText, setErrorTriaCursosText] = useState('');
  const [errorDataHoraEsdeveniment, setErrorDataHoraEsdeveniment] = useState('');

  const [nou, setNou] = useState(props.item.id===0);

  let visible = {
    descripcio: true,
    preu: true,
    descompte: true,
    descompte_x_unitats: true,
    propietats: true,
    data_hora_esdeveniment: true
  }

  switch (id_tipus_propietats) {
    case 0: //Sense propietats - Excursions
      visible = {
        ref: false,
        descripcio: true,
        preu: true,
        descompte: false,
        descompte_x_unitats: false,
        propietats: false,
        data_hora_esdeveniment: true
      }
      break;
    case 1: //Tria un - Roba / Uniformes
      visible = {
        ref: true,
        descripcio: false,
        preu: false,
        descompte: false,
        descompte_x_unitats: false,
        propietats: true,
        data_hora_esdeveniment: false
      }
      break;
    case 2: //Tria Varis - Summer Camp
      visible = {
        ref: false,
        descripcio: true,
        preu: false,
        descompte: false,
        descompte_x_unitats: true,
        propietats: true,
        data_hora_esdeveniment: false
      }
      break;
    case 3: //Unitats per propietat - Esdeveniments
      visible = {
        ref: false,
        descripcio: true,
        preu: false,
        descompte: false,
        descompte_x_unitats: false,
        propietats: true,
        data_hora_esdeveniment: true
      }
      break;
    default:
      visible = {
        ref: true,
        descripcio: true,
        preu: true,
        descompte: true,
        descompte_x_unitats: true,
        propietats: true,
        data_hora_esdeveniment: true
      }
  }


/*
  const buscarDades = async () => {
    setIsLoading(true);

    const tipus = await Api.get('/botiga/propietats/tipus');
    if(tipus.result.success===1){
      const llista_tipus = tipus.data.tipus_propietats.map((item) =>{
            return(
              <Select.Option key={item.id} value={item.id}>
                {entities.decode(item.text[props.t('idioma')])}
              </Select.Option>
            );
      });

      llista_tipus.unshift(
        <Select.Option key={0} value={0}>
          {props.t('botiga.sense_proietats')}
        </Select.Option>
      );
      setTipusPropietats(llista_tipus);
    }
    setIsLoading(false);
  }

  useEffect(() => {
    buscarDades();
  },[]);
*/

  const calendarChange = (rang_moments, rang_strings) => {
      /*setFini(rang_moments[0].toDate());
      setFfin(rang_moments[1].toDate());*/
      /*setFini(rang_moments[0].utc().format(dateFormatTornada));
      setFfin(rang_moments[1].utc().format(dateFormatTornada));*/
      /*setFini(rang_moments[0].unix());
      setFfin(rang_moments[1].unix());*/
      setFini(rang_moments[0].toISOString());
      setFfin(rang_moments[1].toISOString());

    }

  const dataHoraEsdevenimentChange = (date, dateString) => {
    //setData_hora_esdeveniment(date.utc().format(dateFormatTornada));
    //setData_hora_esdeveniment(date.unix());
    setData_hora_esdeveniment(date.toISOString());
    setErrorDataHoraEsdeveniment('');
  }

  const nomChange_ca = (e) =>{
    //const n = e.target.value.trim();
    const n = e.target.value;
    setNom_ca(n);
    if(n.length>0){ setErrorNom_ca('');}
  }
  const nomChange_en = (e) =>{
    //const n = e.target.value.trim();
    const n = e.target.value;
    setNom_en(n);
    if(n.length>0){ setErrorNom_en('');}
  }
  const nomChange_es = (e) =>{
    //const n = e.target.value.trim();
    const n = e.target.value;
    setNom_es(n);
    if(n.length>0){ setErrorNom_en('');}
  }

  const selectChange = (value) =>{
    setId_tipus_propietats(value);
    if(value!==0){
      setErrorSelect('');
      setErrorSelectText('');
    }
  }
  const triaCursosChange = (value) =>{
    setCursos(value);
    if(value.length>0){
      setErrorTriaCursos('');
      setErrorTriaCursosText('');
    }
  }


  const retorn_img_dataUrl = (dataUrl) =>{
    setUrl_image('');
    setImg_dataUrl(dataUrl);
  }


  const submitForm = async (values) => {

      let correcte = true;

      let n_ca = nom_ca.trim();
      let n_en = nom_en.trim();
      let n_es = nom_es.trim();


      if(n_ca.length === 0){
        setErrorNom_ca('error');
        correcte = false;
      }
      if(n_en.length === 0){
        setErrorNom_en('error');
        correcte = false;
      }
      if(n_es.length === 0){
        setErrorNom_es('error');
        correcte = false;
      }

      if(id_tipus_propietats === null){
        setErrorSelect('error');
        setErrorSelectText(props.t('generic.tria'));
        correcte = false;
      }

      if(cursos.length === 0){
        setErrorTriaCursos('error');
        setErrorTriaCursosText(props.t('generic.tria_varis'));
        correcte = false;
      }

      if(visible.data_hora_esdeveniment===true){
        if(!data_hora_esdeveniment){
          setErrorDataHoraEsdeveniment('error');
          correcte=false;
        }
      }

      if(!correcte){return false}

      setGuardant(true);

      let dades = {
        id_curs_escolar,
        id,
        id_categoria,
        ref: entities.encode(ref.trim()),
        nom_ca: entities.encode(nom_ca.trim()),
        nom_en: entities.encode(nom_en.trim()),
        nom_es: entities.encode(nom_es.trim()),
        descripcio_ca: entities.encode(descripcio_ca),
        descripcio_en: entities.encode(descripcio_en),
        descripcio_es: entities.encode(descripcio_es),
        url_image,
        preu,
        descompte,
        descompte_x_unitats_num,
        descompte_x_unitats_percent,
        id_tipus_propietats,
        data_hora_esdeveniment,
        fini: noCaduca === true ? '' : fini,
        ffin: noCaduca === true ? '' : ffin,
        cursos,
        visible: producte_visible,
        mode_test
      }
      console.log(JSON.stringify(dades));

      let method = nou?'POST':'PUT';
      let url_api = nou? '/botiga/producte' : '/botiga/producte/'+id;

      try{
        const resposta = await Api.myFetch(url_api, method, JSON.stringify(dades));

        if(resposta.result.success === 1){
          const era_nou = nou;
          setId(resposta.data.id);
          setNou(false);

          if(img_dataUrl !== undefined){
            const blob = await (await fetch(img_dataUrl)).blob();
            var form_imatges = new FormData();
            form_imatges.append("imgFileField", blob, "image.jpg");
            let resp = await Api.myFetch('/botiga/producte/image/'+resposta.data.id, 'POST', form_imatges);
              if(resp.result.success !== 1){
                console.error('Error POST file: botiga edit: ',resp);
                message.config({top: 50});
                message.open({content: resposta.data.message, duration:5});
              }
          }

          setGuardant(false);
          console.log('resposta', resposta);
          message.success(props.t('generic.OK_saved'), 5);
          if(!era_nou){
            props.callback();
          }

        }else{
          setGuardant(false);
          console.error('Error: submit Form botigaProducte: ',resposta);
          message.config({top: 50});
          message.open({content: resposta.data.message, duration:10});
        }
      }catch(error){
        setGuardant(false);
        message.config({top: 50});
        message.open({content: "Error saving", duration:10});
        console.error('Error: botigaProducte guardar: ', error);
      }

  }



  //const aspectRatio = ubicacio.aspectRatio? eval(ubicacio.aspectRatio) : 16/9;
  const aspectRatio = 16/9;
  let defaultDataRange = '';

  if(nou){
      /*defaultDataRange=[
        moment(new Date(), dateFormat),
        moment(new Date(), dateFormat)
      ];*/
      defaultDataRange=[
        moment.utc(new Date()),
        moment.utc(new Date())
      ];
      /*
      defaultDataRange=[
        moment.unix(),
        moment.unix()
      ];
      */
  }

  if (fini!=null && ffin!=null){
    /*defaultDataRange=[
      moment(new Date(fini), dateFormat),
      moment(new Date(ffin), dateFormat)
    ];*/
    /*defaultDataRange=[
      moment.utc(new Date(fini)),
      moment.utc(new Date(ffin))
    ];*/
    /*
    defaultDataRange=[
      moment.unix(fini),
      moment.unix(ffin)
    ];
    */
    defaultDataRange=[
      moment(fini),
      moment(ffin)
    ];

  }



  let defaultDataHoraEsdeveniment = '';
  if(data_hora_esdeveniment!=null){
    //defaultDataHoraEsdeveniment = moment(new Date(data_hora_esdeveniment), dateFormat);
    //defaultDataHoraEsdeveniment = moment.utc(new Date(data_hora_esdeveniment));
    //defaultDataHoraEsdeveniment = moment.unix(data_hora_esdeveniment);
    defaultDataHoraEsdeveniment = moment(data_hora_esdeveniment);
  }


  let localeDatePicker;
  switch (props.t('idioma')) {
    case 'ca':
      localeDatePicker = locale_ca;
      break;
    case 'en':
      localeDatePicker = locale_en;
      break;
    case 'es':
      localeDatePicker = locale_es;
      break;
    default:
      localeDatePicker = locale_ca;
  }
  if(isLoading){
    return <SyncOutlined spin title="Loading..." style={{fontSize: '3rem'}} />;
  }

  return (
  <>
    <div style={{marginTop:'-2.3rem', marginLeft:'-0.75rem'}}>
      <LeftOutlined className="BotoBack" style={{fontSize: '1.2rem'}} onClick={props.callback}/>
    </div>
    <div style={{padding:'10px 24px 35px 0px'}}>
    <Form onFinish={submitForm}
      layout="horizontal"
      labelCol={{span:2}}
      wrapperCol={{span:22}}
      colon={true}
      autoComplete="off"
    >
    <Form.Item style={{marginBottom: 0}}
      label="Mode Test"
    >
      <Switch checkedChildren={props.t('generic.si')} unCheckedChildren={props.t('generic.no')} onChange={setMode_test} defaultChecked={mode_test} style={{marginLeft: '5px'}}/>
    </Form.Item>

    <Form.Item style={{marginBottom: 0}}
      label={props.t('generic.visible')}
    >
      <Switch checkedChildren={props.t('generic.visible')} unCheckedChildren={props.t('generic.no_visible')} onChange={setProducte_visible} defaultChecked={producte_visible} style={{marginLeft: '5px'}}/>
    </Form.Item>

{!visible.ref ? null :
      <Form.Item label={props.t('botiga.ref')}>
        <Col xs={24} sm={24} md={12} lg={12} xl={8} key='ref'>
          <Input name="ref" placeholder={props.t('botiga.ref')} onChange={(e) => setRef(e.target.value)} defaultValue={ref} autoFocus="focus"/>
        </Col>
      </Form.Item>
}

      <Form.Item style={{marginBottom: 0}}
        validateStatus={errorTriaCursos}
        help={errorTriaCursosText}
        label={props.t('botiga.tria_curs')}
        required={true}
      >
        <TriaCursos defaultValue={cursos} onChange={triaCursosChange}/>
      </Form.Item>

      <Form.Item
        label={props.t('botiga.fini_fin')}
        required={true}
      >
        <DatePicker.RangePicker
          defaultValue={defaultDataRange}
          //showTime={{ format: 'HH:mm' }}
          showTime={{ format: 'HH:mm', defaultValue: [moment('00:00:00', 'HH:mm:ss'), moment('23:59:59', 'HH:mm:ss')], }}
          placeholder={[props.t('botiga.fini'), props.t('botiga.ffin')]}
          format={dateFormat}
          locale={localeDatePicker}
          disabled={noCaduca}
          onChange={calendarChange}
          />
          <Switch checkedChildren={props.t('botiga.nocaduca')} unCheckedChildren={props.t('botiga.caduca')} onChange={setNoCaduca} defaultChecked={noCaduca} style={{marginLeft: '5px'}}/>
        </Form.Item>

{!visible.data_hora_esdeveniment ? null :
      <Form.Item
        validateStatus={errorDataHoraEsdeveniment}
        label={props.t('botiga.data')}
        required={true}
      >
        <DatePicker
          defaultValue={defaultDataHoraEsdeveniment}
          format={dateFormat}
          locale={localeDatePicker}
          showTime={{ format: 'HH:mm' }}
          onChange={dataHoraEsdevenimentChange}
        />
      </Form.Item>
}
        <NomDescripcioIdiomes
          maxLength={65535}
          errorNom_ca = {errorNom_ca}
          errorNom_en = {errorNom_en}
          errorNom_es = {errorNom_es}
          nomChange_ca = {nomChange_ca}
          nomChange_en = {nomChange_en}
          nomChange_es = {nomChange_es}
          nom_ca = {nom_ca}
          nom_en = {nom_en}
          nom_es = {nom_es}
          visibleDescripcio = {visible.descripcio}
          setDescripcio_ca = {setDescripcio_ca}
          setDescripcio_en = {setDescripcio_en}
          setDescripcio_es = {setDescripcio_es}
          descripcio_ca = {descripcio_ca}
          descripcio_en = {descripcio_en}
          descripcio_es= {descripcio_es}
        />


        <Form.Item label={props.t('botiga.url_image')}>
          { img_dataUrl === undefined
            ? <Input name="url_image" placeholder={props.t('botiga.url_image')} onChange={(e) => setUrl_image(e.target.value)} defaultValue={url_image}/>
            : null
          }
          { img_dataUrl === undefined
            ? (!url_image || url_image==='')
              ? null
              : <a onClick={() => setVisor({visible:true, image: url_image+"?"+Number(new Date()), title: props.t('menu.Botiga')+': '+nom_ca})}>
                  <img src={url_image+"?"+Number(new Date())} height="201" alt={props.t('botiga.url_image')}/>
                </a>
            : <a onClick={() => setVisor({visible:true, image: img_dataUrl, title: props.t('menu.Botiga')+': '+nom_ca})}>
                <img src={img_dataUrl} height="200" alt={props.t('botiga.url_image')}/>
              </a>
          }

          <ImageCropper
            aspectRatio={aspectRatio}
            style={{height: 400, width: '100%'}}
            guides={true}
            retorna_dataUrl={retorn_img_dataUrl}
          />

        </Form.Item>
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
{!visible.preu ? null :
          <Col span={4} key='preu'>
            <Form.Item
              colon={true}
              label={props.t('botiga.preu')}
              labelCol={{span:8}}
              wrapperCol={{span:16}}
            >
                <InputNumber name="preu" onChange={setPreu} defaultValue={preu}
                min={0.0} step={1.0}
                formatter={value => `${value}€`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                parser={value => value.replace(/€\s?|(,*)/g, '')}
                />
            </Form.Item>
          </Col>
}
{!visible.descompte ? null :
          <Col span={4} key='descompte'>
            <Form.Item
              colon={true}
              label={props.t('botiga.descompte')}
              labelCol={{span:10}}
              wrapperCol={{span:14}}
            >
                <InputNumber name="descompte" onChange={setDescompte} defaultValue={descompte}
                min={-100} max={100} step={0.1}
                /><PercentageOutlined />
            </Form.Item>
          </Col>
}
{!visible.descompte_x_unitats ? null :
<>
          <Col span={8} key='descompte_x_unitats_percent'>
            <Form.Item
              colon={true}
              label={props.t('botiga.descompte_x_unitats_percent')}
              labelCol={{span:18}}
              wrapperCol={{span:6}}
            >
                <InputNumber name="descompte_x_unitats_percent" onChange={setDescompte_x_unitats_percent} defaultValue={descompte_x_unitats_percent}
                min={-100} max={100} step={0.1}
                /><PercentageOutlined />
            </Form.Item>
          </Col>
          <Col span={8} key='descompte_x_unitats_num'>
            <Form.Item
              colon={true}
              label={props.t('botiga.descompte_x_unitats_num')}
              labelCol={{span:18}}
              wrapperCol={{span:6}}
            >
                <InputNumber name="descompte_x_unitats_num" onChange={setDescompte_x_unitats_num} defaultValue={descompte_x_unitats_num}
                min={0} step={1.0}
                />
            </Form.Item>
          </Col>
</>
}
        </Row>

        <Row type="flex" justify="start">
          <Col>
            <Button onClick={props.callback}>{props.t('generic.back')}</Button>
          </Col>
          <Col style={{marginLeft:'10px'}}>
            <Button type="primary" htmlType="submit" loading={guardant}>{props.t('generic.save')}</Button>
          </Col>
        </Row>
      </Form>
{!visible.propietats || nou ? null :
      <Col xs={24} md={20} lg={16}>
        {id_categoria===1
          ? <PropietatsTallesLlista id_producte={id} id_categoria={id_categoria}/>
          : <PropietatsLlista id_producte={id} id_categoria={id_categoria}/>
        }
      </Col>
}
{ !visor.visible
  ? null
  : <VisorImatges image={visor.image} title={visor.title} onClose={() => setVisor({visible:false})} clickOutsideToExit={true}/>
}
    </div>
  </>
  );
}
export default translate(Edit);
