import React, { useState } from 'react';
import { Tabs } from 'antd';

import MantenimentEditDades from './MantenimentTicEditDades';
import MantenimentEditImatges from './MantenimentTicEditImatges';

function  MantenimentEdit(props){

  const [propietats, setPropietats] = useState({...props});

  const actualitzarId = (i) =>{
    setPropietats({...propietats, item:{id: i}});
  }
return (
  <Tabs defaultActiveKey="1">
    <Tabs.TabPane tab="Data" key="1">
      <MantenimentEditDades {...propietats} actualitzarId={actualitzarId}/>
    </Tabs.TabPane>
    <Tabs.TabPane tab="Images" key="2" disabled={propietats.item.id===0}>
      <MantenimentEditImatges {...propietats}/>
    </Tabs.TabPane>
  </Tabs>
);}
export default MantenimentEdit;
