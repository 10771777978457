import React, { useContext, useEffect, useState } from 'react';
import { translate } from 'react-multi-lang';
import { Row, Col, Alert, Calendar, Button } from 'antd';
import locale_ca from 'antd/es/date-picker/locale/ca_ES';

import DashboardAdmin from './DashboardAdmin';
import DashboardAuxiliar from './DashboardAuxiliar';
import DashboardPares from './DashboardPares';
import DashboardProfe from './DashboardProfe';
import DashboardMonitorBus from './perfils/profe/PassarLlista';
import DashboardAcollida from './perfils/profe/Acollida';
import DashboardManteniment from './DashboardManteniment';
import DashboardCuina from './DashboardCuina';


import { PerfilUsuariContext } from "../context/perfil-usuari";
//import { Api } from '../helpers';

function Dashboard(props){
  const [perfilUsuari, setPerfilUsuari] = useContext(PerfilUsuariContext);
/*
  const [nomes_bus, setNomes_bus] = useState(perfilUsuari.nomes_bus);
  const [nomes_acollida, setNomes_acollida] = useState(perfilUsuari.nomes_acollida);

  useEffect(() => {
    buscarDades();
  },[]);

  const buscarDades = async () => {
    if(perfilUsuari.account.id_profe) {
      const profe = await Api.get('/profe/classes/'+perfilUsuari.account.id_profe);
      console.log(profe)
      if(profe.result.success===1){
        let nomes_bus = false;
        let nomes_acollida = false;
        if(!profe.data.classes && !profe.data.extraescolars && profe.data.hi_ha_transport == true && profe.data.hi_ha_acollida == false){
          nomes_bus = true;
        }
        if(!profe.data.classes && !profe.data.extraescolars && profe.data.hi_ha_transport == false && profe.data.hi_ha_acollida == true){
          nomes_acollida = true;
        }
        setNomes_bus(nomes_bus);
        setNomes_acollida(nomes_acollida);
        setPerfilUsuari({...perfilUsuari, dades_profe: profe.data, nomes_bus, nomes_acollida});
      }
    }
  }
*/
  switch (perfilUsuari.account.perfil) {
    case "Administrador":
      return (<DashboardAdmin/>);
      break;
    case "Auxiliar":
      return (<DashboardAuxiliar/>);
      break;
    case "Pare":
      return (<DashboardPares/>);
      break;
    case "Professor":
      if(perfilUsuari.nomes_bus){
        return (<DashboardMonitorBus/>);
      }
      if(perfilUsuari.nomes_acollida){
        return (<DashboardAcollida/>);
      }
      return (<DashboardProfe/>);
      break;
    case "Manteniment":
      return (<DashboardManteniment/>);
      break;
    case "Cuina":
      return (<DashboardCuina/>);
      break;
    default:
      return(
        <div style={{padding: '1rem'}}>
        <Alert
        message="Error"
        description="Usuario incorrecto o datos de usuario corruptos. Pongase en contacto con administración."
        type="error"
        showIcon
        />
        </div>
      );
  }

}

export default translate(Dashboard);
