import React, { useState, useEffect } from "react";
import ReactExport from "react-export-excel";
import { translate } from 'react-multi-lang';
import { SyncOutlined, PercentageOutlined, LeftOutlined, EditOutlined, DeleteOutlined, SearchOutlined, FileExcelOutlined } from '@ant-design/icons';
import {Table, Tooltip, Popconfirm, Modal, Form, Row, Col, Select, Button, Input, InputNumber, Divider, Switch, DatePicker, Space, message } from 'antd';

import { AllHtmlEntities } from 'html-entities';
import moment from 'moment';
import 'moment/locale/ca';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const entities = new AllHtmlEntities();

function ComandesExcursionsExportaExcel(props){

  const [nursery, setNursery] = useState([]);
  const [reception, setReception] = useState([]);
  const [year1, setYear1] = useState([]);
  const [year2, setYear2] = useState([]);
  const [year34, setYear34] = useState([]);
  const [year56, setYear56] = useState([]);
  const [year78, setYear78] = useState([]);

  const nom_fulla = props.nom_fulla ? props.nom_fulla : props.t('menu.ComandesSummerCamp');
  const nom_fitxer = props.nom_fitxer ? props.nom_fitxer : props.t('menu.ComandesSummerCamp');

  useEffect(() => {


    const fnursery_temp = props.dades.filter((element, index, arr) => element.id_producte === 209);
    const fnursery = fnursery_temp.map(item =>{
      for(let i=1435; i<1460; i++ ){
        item[`w${i}`] = 0;
      }
      item.propietats.map(p =>{
        item[`w${p.id_propietat}`] = p.unitats;
      })
      return item;
    });
    setNursery(fnursery)

    const freception_temp = props.dades.filter((element, index, arr) => element.id_producte === 210);
    const freception = freception_temp.map(item =>{
      for(let i=1466; i<1491; i++ ){
        item[`w${i}`] = 0;
      }
      item.propietats.map(p =>{
        item[`w${p.id_propietat}`] = p.unitats;
      })
      return item;
    });
    setReception(freception)



    const fyear1_temp = props.dades.filter((element, index, arr) => element.id_producte === 211);
    const fyear1 = fyear1_temp.map(item =>{
      for(let i=1497; i<1522; i++ ){
        item[`w${i}`] = 0;
      }
      item.propietats.map(p =>{
        item[`w${p.id_propietat}`] = p.unitats;
      })
      return item;
    });
    setYear1(fyear1)



    const fyear2_temp = props.dades.filter((element, index, arr) => element.id_producte === 212);
    const fyear2 = fyear2_temp.map(item =>{
      for(let i=1529; i<1553; i++ ){
        item[`w${i}`] = 0;
      }
      item['w732'] = 0;
      item.propietats.map(p =>{
        item[`w${p.id_propietat}`] = p.unitats;
      })
      return item;
    });
    setYear2(fyear2)


    const fyear34_temp = props.dades.filter((element, index, arr) => element.id_producte === 213);
    const fyear34 = fyear34_temp.map(item =>{
      for(let i=1559; i<1584; i++ ){
        item[`w${i}`] = 0;
      }
      item.propietats.map(p =>{
        item[`w${p.id_propietat}`] = p.unitats;
      })
      return item;
    });
    setYear34(fyear34)

    const fyear56_temp = props.dades.filter((element, index, arr) => element.id_producte === 214);
    const fyear56 = fyear56_temp.map(item =>{
      for(let i=1590; i<1615; i++ ){
        item[`w${i}`] = 0;
      }
      item.propietats.map(p =>{
        item[`w${p.id_propietat}`] = p.unitats;
      })
      return item;
    });
    setYear56(fyear56)

    const fyear78_temp = props.dades.filter((element, index, arr) => element.id_producte === 215);
    const fyear78 = fyear78_temp.map(item =>{
      for(let i=1622; i<1646; i++ ){
        item[`w${i}`] = 0;
      }
      item.propietats.map(p =>{
        item[`w${p.id_propietat}`] = p.unitats;
      })
      return item;
    });
    setYear78(fyear78)





  },[props.dades]);

  //console.log("dins exporta: ", props.dades)
  return (
      <ExcelFile
        filename={nom_fitxer}
        element={<Button type="primary" style={{backgroundColor: '#217346', borderColor: '#217346', marginBottom: '0.5rem', marginLeft: '0.5rem', marginRight: '0.5rem'}} shape="round" icon={<FileExcelOutlined />}>{props.btn_txt}</Button>}>
          <ExcelSheet data={nursery} name={'Nursery'}>
            <ExcelColumn label="Id" value="id"/>
            <ExcelColumn label={props.t('botiga.titolEstat')} value={(col) => props.t(`botiga.estat.${col.estat}`)}/>
            <ExcelColumn label={props.t('botiga.producte')} value={(col) => entities.decode(col.nom[props.t('idioma')])}/>
            <ExcelColumn label={props.t('alumnes.classe')} value={(col) => entities.decode(col.classe)}/>
            <ExcelColumn label={props.t('generic.alumne')} value={(col) => entities.decode(col.alumne_complet)}/>
            <ExcelColumn label={'CatSalut o Mutua'} value={(col) => entities.decode(col.nss)}/>
            <ExcelColumn label={props.t('generic.total')} value={(col) => parseFloat(col.total)}/>

            <ExcelColumn label={'Setmana 1: 9h a 13h'} value="w1435"/>
            <ExcelColumn label={'Setmana 1: 9h-16h (dinar)'} value="w1436"/>
            <ExcelColumn label={'Setmana 1: bus anada'} value="w1437"/>
            <ExcelColumn label={'Setmana 1: bus tornada'} value="w1438"/>
            <ExcelColumn label={'Setmana 1: bus anada i tornada'} value="w1439"/>

            <ExcelColumn label={'Setmana 2: 9h a 13h'} value="w1440"/>
            <ExcelColumn label={'Setmana 2: 9h-16h (dinar)'} value="w1441"/>
            <ExcelColumn label={'Setmana 2: bus anada'} value="w1442"/>
            <ExcelColumn label={'Setmana 2: bus tornada'} value="w1443"/>
            <ExcelColumn label={'Setmana 2: bus anada i tornada'} value="w1444"/>

            <ExcelColumn label={'Setmana 3: 9h a 13h'} value="w1445"/>
            <ExcelColumn label={'Setmana 3: 9h-16h (dinar)'} value="w1446"/>
            <ExcelColumn label={'Setmana 3: bus anada'} value="w1447"/>
            <ExcelColumn label={'Setmana 3: bus tornada'} value="w1448"/>
            <ExcelColumn label={'Setmana 3: bus anada i tornada'} value="w1449"/>

            <ExcelColumn label={'Setmana 4: 9h a 13h'} value="w1450"/>
            <ExcelColumn label={'Setmana 4: 9h-16h (dinar)'} value="w1451"/>
            <ExcelColumn label={'Setmana 4: bus anada'} value="w1452"/>
            <ExcelColumn label={'Setmana 4: bus tornada'} value="w1453"/>
            <ExcelColumn label={'Setmana 4: bus anada i tornada'} value="w1454"/>

            <ExcelColumn label={'Setmana 5: 9h a 13h'} value="w1455"/>
            <ExcelColumn label={'Setmana 5: 9h-16h (dinar)'} value="w1456"/>
            <ExcelColumn label={'Setmana 5: bus anada'} value="w1457"/>
            <ExcelColumn label={'Setmana 5: bus tornada'} value="w1458"/>
            <ExcelColumn label={'Setmana 5: bus anada i tornada'} value="w1459"/>

            <ExcelColumn label={props.t('generic.created_at')} value={(col) => moment(col.creat).tz('Europe/Madrid').format('DD/MM/YYYY')}/>
          </ExcelSheet>

          <ExcelSheet data={reception} name={'Reception'}>
            <ExcelColumn label="Id" value="id"/>
            <ExcelColumn label={props.t('botiga.titolEstat')} value={(col) => props.t(`botiga.estat.${col.estat}`)}/>
            <ExcelColumn label={props.t('botiga.producte')} value={(col) => entities.decode(col.nom[props.t('idioma')])}/>
            <ExcelColumn label={props.t('alumnes.classe')} value={(col) => entities.decode(col.classe)}/>
            <ExcelColumn label={props.t('generic.alumne')} value={(col) => entities.decode(col.alumne_complet)}/>
            <ExcelColumn label={'CatSalut o Mutua'} value={(col) => entities.decode(col.nss)}/>
            <ExcelColumn label={props.t('generic.total')} value={(col) => parseFloat(col.total)}/>

            <ExcelColumn label={'Setmana 1: 9h a 13h'} value="w1466"/>
            <ExcelColumn label={'Setmana 1: 9h-16h (dinar)'} value="w1467"/>
            <ExcelColumn label={'Setmana 1: bus anada'} value="w1468"/>
            <ExcelColumn label={'Setmana 1: bus tornada'} value="w1469"/>
            <ExcelColumn label={'Setmana 1: bus anada i tornada'} value="w1470"/>

            <ExcelColumn label={'Setmana 2: 9h a 13h'} value="w1471"/>
            <ExcelColumn label={'Setmana 2: 9h-16h (dinar)'} value="w1472"/>
            <ExcelColumn label={'Setmana 2: bus anada'} value="w1473"/>
            <ExcelColumn label={'Setmana 2: bus tornada'} value="w1474"/>
            <ExcelColumn label={'Setmana 2: bus anada i tornada'} value="w1475"/>

            <ExcelColumn label={'Setmana 3: 9h a 13h'} value="w1476"/>
            <ExcelColumn label={'Setmana 3: 9h-16h (dinar)'} value="w1477"/>
            <ExcelColumn label={'Setmana 3: bus anada'} value="w1478"/>
            <ExcelColumn label={'Setmana 3: bus tornada'} value="w1479"/>
            <ExcelColumn label={'Setmana 3: bus anada i tornada'} value="w1480"/>

            <ExcelColumn label={'Setmana 4: 9h a 13h'} value="w1481"/>
            <ExcelColumn label={'Setmana 4: 9h-16h (dinar)'} value="w1482"/>
            <ExcelColumn label={'Setmana 4: bus anada'} value="w1483"/>
            <ExcelColumn label={'Setmana 4: bus tornada'} value="w1484"/>
            <ExcelColumn label={'Setmana 4: bus anada i tornada'} value="w1485"/>

            <ExcelColumn label={'Setmana 5: 9h a 13h'} value="w1486"/>
            <ExcelColumn label={'Setmana 5: 9h-16h (dinar)'} value="w1487"/>
            <ExcelColumn label={'Setmana 5: bus anada'} value="w1488"/>
            <ExcelColumn label={'Setmana 5: bus tornada'} value="w1489"/>
            <ExcelColumn label={'Setmana 5: bus anada i tornada'} value="w1490"/>

            <ExcelColumn label={props.t('generic.created_at')} value={(col) => moment(col.creat).tz('Europe/Madrid').format('DD/MM/YYYY')}/>
          </ExcelSheet>

          <ExcelSheet data={year1} name={'Year 1'}>
            <ExcelColumn label="Id" value="id"/>
            <ExcelColumn label={props.t('botiga.titolEstat')} value={(col) => props.t(`botiga.estat.${col.estat}`)}/>
            <ExcelColumn label={props.t('botiga.producte')} value={(col) => entities.decode(col.nom[props.t('idioma')])}/>
            <ExcelColumn label={props.t('alumnes.classe')} value={(col) => entities.decode(col.classe)}/>
            <ExcelColumn label={props.t('generic.alumne')} value={(col) => entities.decode(col.alumne_complet)}/>
            <ExcelColumn label={'CatSalut o Mutua'} value={(col) => entities.decode(col.nss)}/>
            <ExcelColumn label={props.t('generic.total')} value={(col) => parseFloat(col.total)}/>

            <ExcelColumn label={'Setmana 1: 9h a 13h'} value="w1497"/>
            <ExcelColumn label={'Setmana 1: 9h-16h (dinar)'} value="w1498"/>
            <ExcelColumn label={'Setmana 1: bus anada'} value="w1499"/>
            <ExcelColumn label={'Setmana 1: bus tornada'} value="w1500"/>
            <ExcelColumn label={'Setmana 1: bus anada i tornada'} value="w1501"/>

            <ExcelColumn label={'Setmana 2: 9h a 13h'} value="w1502"/>
            <ExcelColumn label={'Setmana 2: 9h-16h (dinar)'} value="w1503"/>
            <ExcelColumn label={'Setmana 2: bus anada'} value="w1504"/>
            <ExcelColumn label={'Setmana 2: bus tornada'} value="w1505"/>
            <ExcelColumn label={'Setmana 2: bus anada i tornada'} value="w1506"/>

            <ExcelColumn label={'Setmana 3: 9h a 13h'} value="w1507"/>
            <ExcelColumn label={'Setmana 3: 9h-16h (dinar)'} value="w1508"/>
            <ExcelColumn label={'Setmana 3: bus anada'} value="w1509"/>
            <ExcelColumn label={'Setmana 3: bus tornada'} value="w1510"/>
            <ExcelColumn label={'Setmana 3: bus anada i tornada'} value="w1511"/>

            <ExcelColumn label={'Setmana 4: 9h a 13h'} value="w1512"/>
            <ExcelColumn label={'Setmana 4: 9h-16h (dinar)'} value="w1513"/>
            <ExcelColumn label={'Setmana 4: bus anada'} value="w1514"/>
            <ExcelColumn label={'Setmana 4: bus tornada'} value="w1515"/>
            <ExcelColumn label={'Setmana 4: bus anada i tornada'} value="w1516"/>

            <ExcelColumn label={'Setmana 5: 9h a 13h'} value="w1517"/>
            <ExcelColumn label={'Setmana 5: 9h-16h (dinar)'} value="w1518"/>
            <ExcelColumn label={'Setmana 5: bus anada'} value="w1519"/>
            <ExcelColumn label={'Setmana 5: bus tornada'} value="w1520"/>
            <ExcelColumn label={'Setmana 5: bus anada i tornada'} value="w1521"/>

            <ExcelColumn label={props.t('generic.created_at')} value={(col) => moment(col.creat).tz('Europe/Madrid').format('DD/MM/YYYY')}/>
          </ExcelSheet>

          <ExcelSheet data={year2} name={'Year 2'}>
            <ExcelColumn label="Id" value="id"/>
            <ExcelColumn label={props.t('botiga.titolEstat')} value={(col) => props.t(`botiga.estat.${col.estat}`)}/>
            <ExcelColumn label={props.t('botiga.producte')} value={(col) => entities.decode(col.nom[props.t('idioma')])}/>
            <ExcelColumn label={props.t('alumnes.classe')} value={(col) => entities.decode(col.classe)}/>
            <ExcelColumn label={props.t('generic.alumne')} value={(col) => entities.decode(col.alumne_complet)}/>
            <ExcelColumn label={'CatSalut o Mutua'} value={(col) => entities.decode(col.nss)}/>
            <ExcelColumn label={props.t('generic.total')} value={(col) => parseFloat(col.total)}/>

            <ExcelColumn label={'Setmana 1: Sailing 9h-16h'} value="w1529"/>
            <ExcelColumn label={'Setmana 1: bus anada'} value="w1530"/>
            <ExcelColumn label={'Setmana 1: bus tornada'} value="w1531"/>
            <ExcelColumn label={'Setmana 1: bus anada i tornada'} value="w1532"/>

            <ExcelColumn label={'Setmana 2: 9h a 13h'} value="w1533"/>
            <ExcelColumn label={'Setmana 2: 9h-16h (dinar)'} value="w1534"/>
            <ExcelColumn label={'Setmana 2: bus anada'} value="w1535"/>
            <ExcelColumn label={'Setmana 2: bus tornada'} value="w1536"/>
            <ExcelColumn label={'Setmana 2: bus anada i tornada'} value="w1537"/>

            <ExcelColumn label={'Setmana 3: 9h a 13h'} value="w1538"/>
            <ExcelColumn label={'Setmana 3: 9h-16h (dinar)'} value="w1539"/>
            <ExcelColumn label={'Setmana 3: bus anada'} value="w1540"/>
            <ExcelColumn label={'Setmana 3: bus tornada'} value="w1541"/>
            <ExcelColumn label={'Setmana 3: bus anada i tornada'} value="w1542"/>

            <ExcelColumn label={'Setmana 4: 9h a 13h'} value="w1543"/>
            <ExcelColumn label={'Setmana 4: 9h-16h (dinar)'} value="w1544"/>
            <ExcelColumn label={'Setmana 4: bus anada'} value="w1545"/>
            <ExcelColumn label={'Setmana 4: bus tornada'} value="w1546"/>
            <ExcelColumn label={'Setmana 4: bus anada i tornada'} value="w1547"/>

            <ExcelColumn label={'Setmana 5: 9h a 13h'} value="w1548"/>
            <ExcelColumn label={'Setmana 5: 9h-16h (dinar)'} value="w1549"/>
            <ExcelColumn label={'Setmana 5: bus anada'} value="w1550"/>
            <ExcelColumn label={'Setmana 5: bus tornada'} value="w1551"/>
            <ExcelColumn label={'Setmana 5: bus anada i tornada'} value="w1552"/>

            <ExcelColumn label={props.t('generic.created_at')} value={(col) => moment(col.creat).tz('Europe/Madrid').format('DD/MM/YYYY')}/>
          </ExcelSheet>

          <ExcelSheet data={year34} name={'Year 3,4'}>
            <ExcelColumn label="Id" value="id"/>
            <ExcelColumn label={props.t('botiga.titolEstat')} value={(col) => props.t(`botiga.estat.${col.estat}`)}/>
            <ExcelColumn label={props.t('botiga.producte')} value={(col) => entities.decode(col.nom[props.t('idioma')])}/>
            <ExcelColumn label={props.t('alumnes.classe')} value={(col) => entities.decode(col.classe)}/>
            <ExcelColumn label={props.t('generic.alumne')} value={(col) => entities.decode(col.alumne_complet)}/>
            <ExcelColumn label={'CatSalut o Mutua'} value={(col) => entities.decode(col.nss)}/>
            <ExcelColumn label={props.t('generic.total')} value={(col) => parseFloat(col.total)}/>

            <ExcelColumn label={'Setmana 1: 9h a 13h'} value="w1559"/>
            <ExcelColumn label={'Setmana 1: 9h-16h (dinar)'} value="w1560"/>
            <ExcelColumn label={'Setmana 1: bus anada'} value="w1561"/>
            <ExcelColumn label={'Setmana 1: bus tornada'} value="w1562"/>
            <ExcelColumn label={'Setmana 1: bus anada i tornada'} value="w1563"/>

            <ExcelColumn label={'Setmana 2: 9h a 13h'} value="w1564"/>
            <ExcelColumn label={'Setmana 2: 9h-16h (dinar)'} value="w1565"/>
            <ExcelColumn label={'Setmana 2: bus anada'} value="w1566"/>
            <ExcelColumn label={'Setmana 2: bus tornada'} value="w1567"/>
            <ExcelColumn label={'Setmana 2: bus anada i tornada'} value="w1568"/>

            <ExcelColumn label={'Setmana 3: Sailing 9h-16h'} value="w1570"/>
            <ExcelColumn label={'Setmana 3: bus anada'} value="w1571"/>
            <ExcelColumn label={'Setmana 3: bus tornada'} value="w1572"/>
            <ExcelColumn label={'Setmana 3: bus anada i tornada'} value="w1573"/>

            <ExcelColumn label={'Setmana 4: 9h a 13h'} value="w1574"/>
            <ExcelColumn label={'Setmana 4: 9h-16h (dinar)'} value="w1575"/>
            <ExcelColumn label={'Setmana 4: bus anada'} value="w1576"/>
            <ExcelColumn label={'Setmana 4: bus tornada'} value="w1577"/>
            <ExcelColumn label={'Setmana 4: bus anada i tornada'} value="w1578"/>

            <ExcelColumn label={'Setmana 5: 9h a 13h'} value="w1579"/>
            <ExcelColumn label={'Setmana 5: 9h-16h (dinar)'} value="w1580"/>
            <ExcelColumn label={'Setmana 5: bus anada'} value="w1581"/>
            <ExcelColumn label={'Setmana 5: bus tornada'} value="w1582"/>
            <ExcelColumn label={'Setmana 5: bus anada i tornada'} value="w1583"/>

            <ExcelColumn label={props.t('generic.created_at')} value={(col) => moment(col.creat).tz('Europe/Madrid').format('DD/MM/YYYY')}/>
          </ExcelSheet>

          <ExcelSheet data={year56} name={'Year 5,6'}>
            <ExcelColumn label="Id" value="id"/>
            <ExcelColumn label={props.t('botiga.titolEstat')} value={(col) => props.t(`botiga.estat.${col.estat}`)}/>
            <ExcelColumn label={props.t('botiga.producte')} value={(col) => entities.decode(col.nom[props.t('idioma')])}/>
            <ExcelColumn label={props.t('alumnes.classe')} value={(col) => entities.decode(col.classe)}/>
            <ExcelColumn label={props.t('generic.alumne')} value={(col) => entities.decode(col.alumne_complet)}/>
            <ExcelColumn label={'CatSalut o Mutua'} value={(col) => entities.decode(col.nss)}/>
            <ExcelColumn label={props.t('generic.total')} value={(col) => parseFloat(col.total)}/>

            <ExcelColumn label={'Setmana 1: 9h a 13h'} value="w1590"/>
            <ExcelColumn label={'Setmana 1: 9h a 16h (Dinar)'} value="w1591"/>
            <ExcelColumn label={'Setmana 1: bus anada'} value="w1592"/>
            <ExcelColumn label={'Setmana 1: bus tornada'} value="w1593"/>
            <ExcelColumn label={'Setmana 1: bus anada i tornada'} value="w1594"/>

            <ExcelColumn label={'Setmana 2: Sailing 9h-16h'} value="w1596"/>
            <ExcelColumn label={'Setmana 2: bus anada'} value="w1597"/>
            <ExcelColumn label={'Setmana 2: bus tornada'} value="w1598"/>
            <ExcelColumn label={'Setmana 2: bus anada i tornada'} value="w1599"/>

            <ExcelColumn label={'Setmana 3: 9h a 13h'} value="w1600"/>
            <ExcelColumn label={'Setmana 3: 9h a 16h (Dinar)'} value="w1601"/>
            <ExcelColumn label={'Setmana 3: bus anada'} value="w1602"/>
            <ExcelColumn label={'Setmana 3: bus tornada'} value="w1603"/>
            <ExcelColumn label={'Setmana 3: bus anada i tornada'} value="w1604"/>

            <ExcelColumn label={'Setmana 4: 9h a 13h'} value="w1605"/>
            <ExcelColumn label={'Setmana 4: 9h a 16h (Dinar)'} value="w1606"/>
            <ExcelColumn label={'Setmana 4: bus anada'} value="w1607"/>
            <ExcelColumn label={'Setmana 4: bus tornada'} value="w1608"/>
            <ExcelColumn label={'Setmana 4: bus anada i tornada'} value="w1609"/>

            <ExcelColumn label={'Setmana 5: 9h a 13h'} value="w1610"/>
            <ExcelColumn label={'Setmana 5: 9h-16h (dinar)'} value="w1611"/>
            <ExcelColumn label={'Setmana 5: bus anada'} value="w1612"/>
            <ExcelColumn label={'Setmana 5: bus tornada'} value="w1613"/>
            <ExcelColumn label={'Setmana 5: bus anada i tornada'} value="w1614"/>

            <ExcelColumn label={props.t('generic.created_at')} value={(col) => moment(col.creat).tz('Europe/Madrid').format('DD/MM/YYYY')}/>
          </ExcelSheet>
          <ExcelSheet data={year78} name={'Year 7,8'}>
            <ExcelColumn label="Id" value="id"/>
            <ExcelColumn label={props.t('botiga.titolEstat')} value={(col) => props.t(`botiga.estat.${col.estat}`)}/>
            <ExcelColumn label={props.t('botiga.producte')} value={(col) => entities.decode(col.nom[props.t('idioma')])}/>
            <ExcelColumn label={props.t('alumnes.classe')} value={(col) => entities.decode(col.classe)}/>
            <ExcelColumn label={props.t('generic.alumne')} value={(col) => entities.decode(col.alumne_complet)}/>
            <ExcelColumn label={'CatSalut o Mutua'} value={(col) => entities.decode(col.nss)}/>
            <ExcelColumn label={props.t('generic.total')} value={(col) => parseFloat(col.total)}/>

            <ExcelColumn label={'Setmana 1: Trekking 9h a 16h (Dinar)'} value="w1622"/>
            <ExcelColumn label={'Setmana 1: bus anada'} value="w1623"/>
            <ExcelColumn label={'Setmana 1: bus tornada'} value="w1624"/>
            <ExcelColumn label={'Setmana 1: bus anada i tornada'} value="w1625"/>

            <ExcelColumn label={'Setmana 2: Sailing 9h-16h'} value="w1627"/>
            <ExcelColumn label={'Setmana 2: bus anada'} value="w1628"/>
            <ExcelColumn label={'Setmana 2: bus tornada'} value="w1629"/>
            <ExcelColumn label={'Setmana 2: bus anada i tornada'} value="w1630"/>

            <ExcelColumn label={'Setmana 3: 9h a 13h'} value="w1631"/>
            <ExcelColumn label={'Setmana 3: 9h a 16h (Dinar)'} value="w1632"/>
            <ExcelColumn label={'Setmana 3: bus anada'} value="w1633"/>
            <ExcelColumn label={'Setmana 3: bus tornada'} value="w1634"/>
            <ExcelColumn label={'Setmana 3: bus anada i tornada'} value="w1635"/>

            <ExcelColumn label={'Setmana 4: 9h a 13h'} value="w1636"/>
            <ExcelColumn label={'Setmana 4: 9h a 16h (Dinar)'} value="w1637"/>
            <ExcelColumn label={'Setmana 4: bus anada'} value="w1638"/>
            <ExcelColumn label={'Setmana 4: bus tornada'} value="w1639"/>
            <ExcelColumn label={'Setmana 4: bus anada i tornada'} value="w1640"/>

            <ExcelColumn label={'Setmana 5: 9h a 13h'} value="w1641"/>
            <ExcelColumn label={'Setmana 5: 9h-16h (dinar)'} value="w1642"/>
            <ExcelColumn label={'Setmana 5: bus anada'} value="w1643"/>
            <ExcelColumn label={'Setmana 5: bus tornada'} value="w1644"/>
            <ExcelColumn label={'Setmana 5: bus anada i tornada'} value="w1645"/>

            <ExcelColumn label={props.t('generic.created_at')} value={(col) => moment(col.creat).tz('Europe/Madrid').format('DD/MM/YYYY')}/>
          </ExcelSheet>
      </ExcelFile>
  );

}

export default translate(ComandesExcursionsExportaExcel);
