import React, { useContext } from 'react';
import { PoweroffOutlined, BellOutlined, BellFilled, MenuUnfoldOutlined, MenuFoldOutlined } from '@ant-design/icons';
import { Tooltip, Button, Row, Col, Badge } from 'antd';
import { translate } from 'react-multi-lang';

import { PerfilUsuariContext } from "../context/perfil-usuari";
import { MenuTriatContext } from "../context/menu-triat";
//import logo from '../images/netagencias.png';
//import logo from '../images/BackData.png';
//import logo from '../images/logo_h_transparent.png';
//import logo from '../images/logo_aqua.png';

//import logo from '{process.env.REACT_APP_LOGO_MENU}';


function MenuSuperior(props){
    const [perfilUsuari, setPerfilUsuari] = useContext(PerfilUsuariContext);
    const [menuTriat, setMenuTriat] = useContext(MenuTriatContext);

    let logo_menu = "";

    switch (global.app_id) {
      case "british":
          logo_menu=require('../images/logo_h_transparent.png');
        break;
      case "aquamora":
          logo_menu=require('../images/logo_aqua.png');
        break;
      case "erp":
          //logo_menu=require('../images/logo.png');
          logo_menu=require('../images/logo_h_t_ajustat.png');
        break;
      default:
        logo_menu =require('../images/BackData.png');
    }



    return (
      <Row>
      {perfilUsuari.account.perfil === "Pare" ? null :
        <Col span={1} onClick={()=>props.toggleCollapsed()} style={{fontSize: '1.5rem', color: 'white', paddingLeft: '1rem', paddingBottom: 16}}>
          {React.createElement(props.collapsed ? MenuUnfoldOutlined : MenuFoldOutlined)}
        </Col>
      }
        <Col span={23}>
          <Row justify="space-between">
            <Col span={8} style={{textAlign:'left', paddingLeft:'1rem'}}>
              <div className="BotoBack" onClick={(e) => setMenuTriat("Dashboard")}>
                <img src={logo_menu} alt="Logo" width="210"/>
              </div>
            </Col>
            { //Seguretat api correcte
              global.api_url === "https://api.app.bscd.eu/erp" ? null :
              <Col span={6} style={{textAlign:'center'}}>
                <span style={{fontSize:'2rem', fontWeight:'bold'}}>API TEST</span>
                <span style={{fontSize:'0.8rem', color:'white'}}>{global.api_url}</span>
              </Col>
            }
            <Col span={6} style={{textAlign:'right'}}>
              <Row justify="end" align="middle">
                <Col>
                  <span style={{ fontSize: '16px', color: 'white' }}>{props.t('login.User')}: {perfilUsuari.account.username}</span>
                </Col>
                <Col>
                  <Tooltip title={props.t('menu.cerrar_sesion')} placement="left">
                    <Button
                      type="link"
                      className="link-menu-superior"
                      onClick={(e) => setMenuTriat('Logout')}
                      >
                      <PoweroffOutlined style={{ fontSize: '16px', color: 'white' }} />
                    </Button>
                  </Tooltip>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    );

}

export default translate(MenuSuperior);
