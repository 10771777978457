import React, { useState, useEffect } from 'react';
import { Modal, Space, Tooltip, Button, Row, Col, Typography } from 'antd';


import locale_ca from 'antd/es/date-picker/locale/ca_ES';
import locale_en from 'antd/es/date-picker/locale/en_GB';
import locale_es from 'antd/es/date-picker/locale/es_ES';

import { translate } from 'react-multi-lang';
import AcollidaEditClasse from './AcollidaEditClasse';
import AcollidaEditFamilia from './AcollidaEditFamilia';


function AcollidaTriaClasseFamilia(props){

  const [isModalClasseVisible, setIsModalClasseVisible] = useState(false);
  const [isModalFamiliaVisible, setIsModalFamiliaVisible] = useState(false);


  useEffect(() => {
    console.log('AcollidaTriaClasseFamilia props:', props)
  },[props]);


  const showModalClasse = () => {
    setIsModalClasseVisible(true);
  };
  const showModalFamilia = () => {
    setIsModalFamiliaVisible(true);
  };


  const handleOk = () => {
    setIsModalClasseVisible(false);
    setIsModalFamiliaVisible(false);
    props.callback();
  };
  const handleClose = () => {
    setIsModalClasseVisible(false);
    setIsModalFamiliaVisible(false);
  };


  return (
    <>

    <Row justify="space-around" gutter={[16, 24]}>
      <Col span={24} align="center">
        <Typography.Title level={4}>
          {props.t("acollida.pregunta_tria")}
        </Typography.Title>
      </Col>
      <Col span={12} align="center">
        <Space direction="vertical">
          <Tooltip title={props.t("acollida.per_classe_desc")} placement="bottom">
            <Button type="primary" onClick={showModalClasse}>
              {props.t("acollida.per_classe")}
            </Button>
          </Tooltip>
          {props.t("acollida.per_classe_desc")}
        </Space>
      </Col>
      <Col span={12} align="center">
        <Space direction="vertical">
          <Tooltip title={props.t("acollida.per_familia_desc")} placement="bottom">
            <Button type="primary" onClick={showModalFamilia}>
              {props.t("acollida.per_familia")}
            </Button>
          </Tooltip>
          {props.t("acollida.per_familia_desc")}
        </Space>
      </Col>
    </Row>
      <Modal
        title={props.t("acollida.per_classe")}
        visible={isModalClasseVisible}
        onCancel={handleClose}
        footer={null}
        destroyOnClose={true}
        forceRender={true}
        width={600}
        style={{top:20}}
      >
        <AcollidaEditClasse item={props.item} id_curs_escolar={props.id_curs_escolar} diaTriat={props.extraProps.diaTriat} callback={handleOk} cancel={handleClose}/>
      </Modal>
      <Modal
        title={props.t("acollida.per_familia")}
        visible={isModalFamiliaVisible}
        onCancel={handleClose}
        footer={null}
        destroyOnClose={true}
        forceRender={true}
        width={600}
        style={{top:20}}
      >
        <AcollidaEditFamilia item={props.item} id_curs_escolar={props.id_curs_escolar} diaTriat={props.extraProps.diaTriat} callback={handleOk} cancel={handleClose}/>
      </Modal>
    </>
  );
}
export default translate(AcollidaTriaClasseFamilia);
