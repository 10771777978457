import React, { useContext, useEffect, useState } from 'react';
import { translate } from 'react-multi-lang';
import { Row, Col, Alert, Calendar, Button } from 'antd';
import { SyncOutlined } from '@ant-design/icons';
import locale_ca from 'antd/es/date-picker/locale/ca_ES';

import IndexTabs from './IndexTabs';
import IndexAcollida from './IndexAcollida';
import IndexPares from './IndexPares';

import { PerfilUsuariContext } from "../context/perfil-usuari";
import { Api } from '../helpers';


function Index(props){
  const [perfilUsuari, setPerfilUsuari] = useContext(PerfilUsuariContext);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    buscarDades();
  },[]);

  const buscarDades = async () => {
    setIsLoading(true);
    if(perfilUsuari.account.id_profe) {
      const profe = await Api.get('/profe/classes/'+perfilUsuari.account.id_profe);
      console.log('profe', profe)
      if(profe.result.success===1){
        let nomes_bus = false;
        let nomes_acollida = false;
        if(!profe.data.classes && !profe.data.extraescolars && profe.data.hi_ha_transport == true && profe.data.hi_ha_acollida == false){
          nomes_bus = true;
        }
        if(!profe.data.classes && !profe.data.extraescolars && profe.data.hi_ha_transport == false && profe.data.hi_ha_acollida == true){
          nomes_acollida = true;
        }
        setPerfilUsuari({...perfilUsuari, dades_profe: profe.data, nomes_bus, nomes_acollida});
      }
    }
    setIsLoading(false);
  }



  if(isLoading){
    return (
      <Row justify="center" align="middle" style={{height: '100vh'}}>
        <SyncOutlined spin title="Loading..." style={{fontSize: '3rem'}} />
      </Row>
    );
  } else {
    switch (perfilUsuari.account.perfil) {
      case "Administrador":
      case "Auxiliar":
      case "Manteniment":
      case "Cuina":
        return (<IndexTabs logout={props.logout}/>);
        break;
      case "Professor":
        if(perfilUsuari.nomes_acollida){
          return (<IndexAcollida logout={props.logout}/>);
        }
        return (<IndexTabs logout={props.logout}/>);
        break;
      case "Pare":
        return (<IndexPares logout={props.logout}/>);
        break;
      default:
        return(
          <div style={{padding: '1rem'}}>
          <Alert
          message="Error"
          description="Usuario incorrecto o datos de usuario corruptos. Pongase en contacto con administración."
          type="error"
          showIcon
          />
          </div>
        );
    }
  }

}

export default translate(Index);
