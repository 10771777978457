import React, { useState, useEffect } from 'react';
import { translate } from 'react-multi-lang';
import { Switch, Table, Input, Button, Space, Row, Col, Tooltip } from 'antd';
import Highlighter from 'react-highlight-words';
import { SearchOutlined, WarningTwoTone } from '@ant-design/icons';
import { AllHtmlEntities } from 'html-entities';

import ContainerPagina from '../../../components/ContainerPagina';
import TitolPagina from '../../../components/TitolPagina';
import LlistaEditable from '../../../components/LlistaEditable';
import Edit from '../../../components/Formularis/perfils/admin/UnitatFamiliar';
import { Api, InitDades } from '../../../helpers';

const { Column } = Table;
const entities = new AllHtmlEntities();

function UnitatFamiliar(props){

  const [mostrarDadesBancaries, setMostrarDadesBancaries] = useState(false);
  const [accioTaula, setAccioTaula] = useState('taula');
  //CERCA
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const [searchInput, setSearchInput] = useState([]);
  //FI CERCA

  const filtreEstat = [
    {text: props.t('alumnes.estat.actiu'), value: 'actiu'},
    {text: props.t('alumnes.estat.baixa'), value: 'baixa'}
  ];

  const buscarDades = async () => {
    const fam = await Api.get('/families');
    if(fam.result.success===1){

      const fams = fam.data.families.map((item) =>{
        let tel = '';
        let pare = '';
        if(item.responsables){
          if(item.responsables.length > 0){
            tel = item.responsables[0].tel1 ? item.responsables[0].tel1 : item.responsables[0].tel2;
            tel = tel ? tel : '';
            pare = item.responsables[0].nom ? ` - ${item.responsables[0].nom} ${item.responsables[0].cognom1}`: '';
          }
        }
        item.responsable1 = `${tel} ${entities.decode(pare)}`;
        tel = '';
        pare = '';
        if(item.responsables){
          if(item.responsables.length > 1){
            tel = item.responsables[1].tel1 ? item.responsables[1].tel1 : item.responsables[1].tel2;
            tel = tel ? tel : '';
            pare = item.responsables[1].nom ? ` - ${item.responsables[1].nom} ${item.responsables[1].cognom1}`: '';
          }
        }

        item.responsable2 = `${tel} ${entities.decode(pare)}`;
        return item;
      });

      fams.sort((a, b) =>  b.id - a.id)
      return fams;
    }
  }

  const marcarEliminat = async (id) => {
    //const fam = await Api.myFetch('/families/'+id, 'DELETE');
    const fam = await Api.myFetch(`/families/baixa/${id}`, 'DELETE');
    if(fam.result.success===1){

    }
  }

  //CERCA
    const getColumnSearchProps = (dataIndex, nom) => ({
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <div style={{ padding: 8 }}>
          <Input
            ref={node => {
              let search = searchInput;
              search[dataIndex] = node;
              setSearchInput(search);
            }}
            placeholder={`${props.t('generic.cercar')} ${nom}`}
            value={selectedKeys[0]}
            onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
            style={{ width: 188, marginBottom: 8, display: 'block' }}
            autoFocus="focus"
          />
          <Space>
            <Button
              type="primary"
              onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
              icon={<SearchOutlined />}
              size="small"
              style={{ width: 90 }}
            >
              {props.t('generic.cercar')}
            </Button>
            <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
              Reset
            </Button>
          </Space>
        </div>
      ),
      filterIcon: filtered => <SearchOutlined style={{color: filtered ? '#1890ff' : undefined }} />,
      onFilter: (value, record) =>
        record[dataIndex] ? arreglarTxt4Search(entities.decode(record[dataIndex].toString())).includes(arreglarTxt4Search(value)) : '',
      onFilterDropdownVisibleChange: visible => {
        if (visible) {
          setTimeout(() => searchInput[dataIndex].select(),100);
        }
      },
     render: text => (entities.decode(text)),
    });

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
      confirm();
      setSearchText(selectedKeys[0]);
      setSearchedColumn(dataIndex);
    };

    const handleReset = clearFilters => {
      clearFilters();
      setSearchText('');
    };

    const arreglarTxt4Search = text =>{
        // Poso en minuscules, normalitzo caracters treure accents i ñ , elimina els caracters diacrítics raros d'un text (ES6)
        //return text.trim().split(' ')[0].toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g,"");
        return text.trim().toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g,"");
    }
  //FI CERCA


  const columns_bancaries = [
  {
    title:"Id",
    key:"id",
    sorter: {
      compare: (a, b) =>  a.id - b.id,
      multiple: 0
    },
    render: (text,record) => (<span>{record.id}</span>)
  },{
    title: props.t('families.cognom1'),
    key: "cognom1",
    dataIndex: 'cognom1',
    render: (text,record) =>(entities.decode(record.cognom1)),
    sorter: {
      compare: (a, b) => { return entities.decode(a.cognom1).localeCompare(entities.decode(b.cognom1))},
      multiple: 0
    },
    ...getColumnSearchProps('cognom1',props.t('families.cognom1'))
  },{
    title:props.t('families.cognom2'),
    key:"cognom2",
    dataIndex: 'cognom2',
    render:(text,record) =>(entities.decode(record.cognom2)),
    sorter: {
      compare: (a, b) => { return entities.decode(a.cognom2).localeCompare(entities.decode(b.cognom2))},
      multiple: 0
    },
    ...getColumnSearchProps('cognom2',props.t('families.cognom2'))
  },{
    title: props.t('families.pagament_unic_totes_quotes_any'),
    key:"pagament_unic_totes_quotes_any",
    sorter: {
      compare: (a, b) =>  a.pagament_unic_totes_quotes_any - b.pagament_unic_totes_quotes_any,
      multiple: 0
    },
    render: (text,record) => {
      if(record.pagament_unic_totes_quotes_any){
        return (<span style={{color: 'red'}}>SI</span>)
      }else{
        return (<span>NO</span>)
      }
    }
  },{
    title: props.t('families.pare_treballador_centre'),
    key:"pare_treballador_centre",
    sorter: {
      compare: (a, b) =>  a.pare_treballador_centre - b.pare_treballador_centre,
      multiple: 0
    },
    render: (text,record) => {
      if(record.pare_treballador_centre){
        return (<span style={{color: 'red'}}>SI</span>)
      }else{
        return (<span>NO</span>)
      }
    }
  },{
    title: props.t('families.tipus_pagament'),
    key:"tipus_pagament",
    dataIndex: 'tipus_pagament',
    sorter: {
      compare: (a, b) => {
        let aa = a.tipus_pagament ? a.tipus_pagament : '';
        let bb = b.tipus_pagament ? b.tipus_pagament : '';
        return aa.localeCompare(bb)
      },
      multiple: 0
    },
  },{
    title: 'num IBAN',
    key:"num_bancs",
    dataIndex: 'num_bancs',
    sorter: {
      compare: (a, b) =>  a.num_bancs - b.num_bancs,
      multiple: 0
    },
  },{
    key:"alerta",
    render:(text, record) =>{

      let error_txt = [];

      if(!record.tipus_pagament){
        error_txt.push(<p style={{margin: 0, padding: 0}}>{props.t('banc.errors.tipus_pagament')}</p>);
      }

      if(record.tipus_pagament==='BANC'){
        if(record.num_bancs > 0){
          //record.bancs.bancs.map((banc) => {
          record.bancs.map((banc) => {
            if(!banc.mandat){
              error_txt.push(<p style={{margin: 0, padding: 0}}>{props.t('banc.errors.mandat')}</p>);
            }
            if(!banc.mandat_data){
              error_txt.push(<p style={{margin: 0, padding: 0}}>{props.t('banc.errors.mandat_data')}</p>);
            }
            if(banc.pendent_validar === 1){
              error_txt.push(<p style={{margin: 0, padding: 0}}>{props.t('banc.errors.validar')}</p>);
            }
          });
        }else{
          error_txt.push(<p style={{margin: 0, padding: 0}}>{props.t('banc.errors.num_bancs')}</p>);
        }
      }

      if(error_txt.length > 0){
        return (
          <Tooltip placement="top" title={error_txt}>
            <WarningTwoTone twoToneColor="red" style={{fontSize: '36px'}}/>
          </Tooltip>
        )
      }else{
        return '';
      }

    }
  },{
    title: props.t('alumnes.estat.titol'),
    key:"estat",
    dataIndex: 'estat',
    width: '10px',
    filters: filtreEstat,
    defaultFilteredValue: ['actiu'],
    onFilter: (value, record) => record.estat === value,
    render: (text,record) =>(props.t(`alumnes.estat.${record.estat}`)),
    sorter: {
      compare: (a, b) => { return a.estat.localeCompare(b.estat)},
      multiple: 0
    },
  }];

  const columns = [
  {
    title:"Id",
    key:"id",
    sorter: {
      compare: (a, b) =>  a.id - b.id,
      multiple: 0
    },
    render: (text,record) => (<span>{record.id}</span>)
  },{
    title: props.t('families.cognom1'),
    key: "cognom1",
    dataIndex: 'cognom1',
    render: (text,record) =>(entities.decode(record.cognom1)),
    sorter: {
      compare: (a, b) => { return entities.decode(a.cognom1).localeCompare(entities.decode(b.cognom1))},
      multiple: 0
    },
    ...getColumnSearchProps('cognom1',props.t('families.cognom1'))
  },{
    title:props.t('families.cognom2'),
    key:"cognom2",
    dataIndex: 'cognom2',
    render:(text,record) =>(entities.decode(record.cognom2)),
    sorter: {
      compare: (a, b) => { return entities.decode(a.cognom2).localeCompare(entities.decode(b.cognom2))},
      multiple: 0
    },
    ...getColumnSearchProps('cognom2',props.t('families.cognom2'))
  },{
    title: `${props.t('generic.responsable')}1`,
    key:"responsable1",
    dataIndex: 'responsable1',
    render:(text,record) => (record.responsable1),
    ...getColumnSearchProps('responsable1', `${props.t('generic.responsable')}1`)
  },{
    title: `${props.t('generic.responsable')}2`,
    key:"responsable2",
    dataIndex: 'responsable2',
    render:(text,record) => (record.responsable2),
    ...getColumnSearchProps('responsable2', `${props.t('generic.responsable')}2`)
  },{
    title: props.t('alumnes.estat.titol'),
    key:"estat",
    dataIndex: 'estat',
    width: '10px',
    filters: filtreEstat,
    defaultFilteredValue: ['actiu'],
    onFilter: (value, record) => record.estat === value,
    render: (text,record) =>(props.t(`alumnes.estat.${record.estat}`)),
    sorter: {
      compare: (a, b) => { return a.estat.localeCompare(b.estat)},
      multiple: 0
    },
  }];

  return(
    <ContainerPagina xl={24}>
      <TitolPagina txt={props.t('menu.UnitatFamiliar')}/>
      <Row type="flex" justify="center">
      {accioTaula !== 'taula' ? null :
        <Col>
          Mostrar resum dades bancaries <Switch onChange={setMostrarDadesBancaries} />
        </Col>
      }
      </Row>
      <div style={{marginTop:'1rem', marginLeft:'3rem'}}>
        <LlistaEditable
          callback_accio={setAccioTaula}
          txt_creats={props.t('families.creados')}
          txt_nou={props.t('families.nuevo')}
          buscarDades={buscarDades}
          edit={Edit}
          delete={marcarEliminat}
          itemBuit={InitDades.familia()}
          columns={mostrarDadesBancaries ? columns_bancaries : columns}
        />

      </div>
    </ContainerPagina>
  );

}

export default translate(UnitatFamiliar);
