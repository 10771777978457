import React, { useState, useEffect, useContext } from 'react';
import { translate, setLanguage, getLanguage } from 'react-multi-lang';
import { Menu, Select, Row, Col } from 'antd';
import {
  ApartmentOutlined,
  ContactsOutlined,
  FundProjectionScreenOutlined,
  HomeOutlined,
  PoweroffOutlined,
  SettingOutlined,
  SkinOutlined,
  TeamOutlined,
  UserOutlined,
  MobileOutlined,
  FolderOutlined,
  CheckSquareOutlined,
  MailOutlined,
  IdcardOutlined,
  CoffeeOutlined,
  ToolOutlined,
  MedicineBoxOutlined,
  DesktopOutlined
} from '@ant-design/icons';

import { MenuTriatContext } from "../context/menu-triat";
import { PerfilUsuariContext } from "../context/perfil-usuari";

import {ReactComponent as Botiga} from '../icons/icones_app_BSCD-01.svg';
import {ReactComponent as Serveis} from '../icons/icones_app_BSCD-02.svg';
import {ReactComponent as Dades} from '../icons/icones_app_BSCD-03.svg';
import {ReactComponent as Metge} from '../icons/icones_app_BSCD-07.svg';
import {ReactComponent as Documents} from '../icons/icones_app_BSCD-05.svg';
import {ReactComponent as Notificar} from '../icons/icones_app_BSCD-04.svg';
import {ReactComponent as Expedient} from '../icons/icones_app_BSCD-06.svg';
import {ReactComponent as Rebudes} from '../icons/icones_app_BSCD-08.svg';


const { SubMenu } = Menu;

function MenuLateral(props) {
  const [menuTriat, setMenuTriat] = useContext(MenuTriatContext);
  const [perfilUsuari, setPerfilUsuari] = useContext(PerfilUsuariContext);
  const [openKeys, setOpenKeys] = useState(['Dashboard']);
  const [mostrarAcollida, setMostrarAcollida] = useState(false);
  const [mostrarMealTimeReport, setMostrarMealTimeReport] = useState(false);

  // submenu keys of first level
  const rootSubmenuKeys = ['Dashboard','Pares_Botiga', 'Pares_Serveis', 'Pares_MevesDades', 'Pares_ServeiMedicArrel', 'Pares_Documents', 'Pares_Notificar', 'Pares_Expedient', 'Pares_NotificacionsRebudes', 'Pares_Configuracio'];

  useEffect(() => {
    if(menuTriat==='Dashboard'){
      setOpenKeys(['Dashboard']);
      document.getElementById("Dashboard").classList.add("ant-menu-item-selected");
      //Array.from(document.getElementsByClassName('MenuLateral ant-menu-submenu-open ant-menu-submenu-selected')).forEach((el) => el.classList.remove('ant-menu-submenu-selected'));
      //Array.from(document.getElementsByClassName('MenuLateral ant-menu-submenu-open')).forEach((el) => el.classList.remove('ant-menu-submenu-open'));
      Array.from(document.getElementsByClassName('ant-menu-item-only-child ant-menu-item-selected')).forEach((el) => el.classList.remove('ant-menu-item-selected'));
    }else{
      document.getElementById("Dashboard").classList.remove("ant-menu-item-selected");
    }
  },[menuTriat]);

  useEffect(()=>{
    if(perfilUsuari.dades_profe){
      setMostrarAcollida(perfilUsuari.dades_profe.hi_ha_acollida);
      setMostrarMealTimeReport(perfilUsuari.dades_profe.hi_ha_menjador);
    }
  },[perfilUsuari])


  const changeLang = (lang) =>{
      setLanguage(lang);
      sessionStorage.setItem('Language',lang);
    }

    const onOpenChange = openKeys_list => {
      //console.log('openKeys', openKeys);
      //console.log('openKeys_list', openKeys_list);
      const latestOpenKey = openKeys_list.find(key => openKeys.indexOf(key) === -1);
      if (rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
        setOpenKeys(openKeys_list);
      } else {
        setOpenKeys(latestOpenKey ? [latestOpenKey] : []);

      }
    };
    const triarMenu = (menuTriat) =>{
      //setPerfilUsuari(perfilUsuari => ({...perfilUsuari, menuTriat: key}));
      setMenuTriat(menuTriat);
    }


    return (
      <Menu
        theme="light"
        className="MenuLateral"
        mode="inline"
        onClick={({key}) => triarMenu(key)}
        openKeys={openKeys}
        onOpenChange={onOpenChange}
        style={{ width: '100%', height: '100%' }}
        defaultSelectedKeys="Dashboard"
      >
        <Menu.Item key="Dashboard" className="MenuLateral" id="Dashboard">
          <span>
            <HomeOutlined />
            <span>{props.t('menu.inicio')}</span>
          </span>
        </Menu.Item>
        <Menu.Item key="ServeiMedic" className="MenuLateral">
          <span>
            <MedicineBoxOutlined />
            <span>{props.t('menu.ServeiMedic')}</span>
          </span>
        </Menu.Item>
        <Menu.Item key="Profe_MealTimeReport" className="MenuLateral">
          <span>
            <CoffeeOutlined />
            <span>{props.t('menjador.report.titol')}</span>
          </span>
        </Menu.Item>
        <Menu.Item key="Manteniment" className="MenuLateral">
          <span>
            <ToolOutlined />
            <span>{props.t('menu.Manteniment')}</span>
          </span>
        </Menu.Item>
        <Menu.Item key="MantenimentTIC" className="MenuLateral">
          <span>
            <DesktopOutlined />
            <span>{props.t('menu.MantenimentTIC')}</span>
          </span>
        </Menu.Item>
        <Menu.Item key="Profe_Acollida" className="MenuLateral">
          <span>
            <CheckSquareOutlined />
            <span>{props.t('acollida.titol')}</span>
          </span>
        </Menu.Item>
{/*
        <Menu.Item key="Profe_Documents" className="MenuLateral">
          <span>
            <FolderOutlined />
            <span>{props.t('menu.Documents')}</span>
          </span>
        </Menu.Item>
*/}
        <SubMenu
         className="MenuLateral"
          key="configuracio"
          title={
            <span>
              <SettingOutlined />
              <span>{props.t('menu.configuracion')}</span>
            </span>
          }
        >

          <div style={{paddingLeft:'48px', paddingBottom:'1rem', paddingTop: '1rem', marginRight: '20px'}}>
            <span>{props.t('generic.idioma')}:&nbsp;&nbsp;</span>
            <Select defaultValue={getLanguage()} onChange={changeLang} style={{ width: 110}} size="small">
              <Select.Option value="es">Castellano</Select.Option>
              <Select.Option value="ca">Català</Select.Option>
              <Select.Option value="en">English</Select.Option>
              {/*idiomes*/}
            </Select>
          </div>
        </SubMenu>
      {/*  </SubMenu>*/}
        <Menu.Item key="Logout" className="MenuLateral">
          <span>
            <PoweroffOutlined />
            <span>{props.t('menu.cerrar_sesion')}</span>
          </span>
        </Menu.Item>
      </Menu>
    );
}

export default translate(MenuLateral);
