import React, { useContext, useState, useEffect } from 'react';
import { translate } from 'react-multi-lang';
import { Row, Col, Alert, Button, List, Card, Tabs, Modal, Typography } from 'antd';
import { AlertFilled, DatabaseOutlined, InboxOutlined } from '@ant-design/icons';
import locale_ca from 'antd/es/date-picker/locale/ca_ES';
import { AllHtmlEntities } from 'html-entities';
import { MenuTriatContext } from "../context/menu-triat";
import { PerfilUsuariContext } from "../context/perfil-usuari";
import {ReactComponent as Carrito} from '../icons/icones_app_BSCD-01.svg';
import DashboardClasse from '../components/Formularis/perfils/profe/DashboardClasse';
import NewPassword from '../components/PantallesObligadesInici/NewPassword';
import {useInterval} from '../helpers/UseInterval';

const entities = new AllHtmlEntities();

function DashboardManteniment(props){
  const [perfilUsuari, setPerfilUsuari] = useContext(PerfilUsuariContext);
  const [menuTriat, setMenuTriat] = useContext(MenuTriatContext);
  const [tabs, setTabs] = useState([]);
  const [refrescar, setRefrescar] = useState(0);
  const [mostrarNewPassword, setMostrarNewPassword] = useState(false);

  useEffect(() => {
    setMostrarNewPassword(perfilUsuari.account.obligar_modificar_password === 1);
  },[perfilUsuari]);

  const mides = {width: '100%',height: '100px'};
  const span_botons = 'ant-col-xs-24 ant-col-sm-24 ant-col-md-12 ant-col-lg-8 ant-col-xl-6 ant-col-xxl-6';
  const color1 = {padding: 0, cursor: 'pointer', width: mides.width, height: mides.height, backgroundColor: '#00e5ff', borderColor: '#00e5ff', color: 'rgba(0,0,0,0.87)'};
  const color2 = {padding: 0, cursor: 'pointer', width: mides.width, height: mides.height, backgroundColor: '#eeff41', borderColor: '#eeff41', color: 'rgba(0,0,0,0.87)'};
  const color3 = {padding: 0, cursor: 'pointer', width: mides.width, height: mides.height, backgroundColor: '#42a5f5', borderColor: '#42a5f5', color: 'rgba(0,0,0,0.87)'};
  const color4 = {padding: 0, cursor: 'pointer', width: mides.width, height: mides.height, backgroundColor: '#448aff', borderColor: '#448aff', color: 'rgba(0,0,0,0.87)'};

  return(
    <>


        <Row type="flex" justify="start" style={{padding: '25px'}} gutter={[16,16]}>
          <Col className={span_botons}>
            <div style={color3} onClick={(e) => setMenuTriat("Manteniment")}>
              <p style={{padding: '5px 0 0 10px', color: 'white'}}><DatabaseOutlined/>&nbsp;{props.t('menu.Gestio')}&nbsp;&gt;&nbsp;<InboxOutlined />&nbsp;{props.t('menu.Manteniment')}</p>
              <Row justify="center" align="middle">
                <Col><Typography.Title level={4}>{props.t('menu.Manteniment')}</Typography.Title></Col>
              </Row>
            </div>
          </Col>
          <Col className={span_botons}>
            <div style={color4} onClick={(e) => setMenuTriat("MantenimentTIC")}>
              <p style={{padding: '5px 0 0 10px', color: 'white'}}><DatabaseOutlined/>&nbsp;{props.t('menu.Gestio')}&nbsp;&gt;&nbsp;<InboxOutlined />&nbsp;{props.t('menu.MantenimentTIC')}</p>
              <Row justify="center" align="middle">
                <Col><Typography.Title level={4}>{props.t('menu.MantenimentTIC')}</Typography.Title></Col>
              </Row>
            </div>
          </Col>
          <Col className={span_botons}>
            <div style={color1} onClick={(e) => setMenuTriat("Profe_Acollida")}>
              <p style={{padding: '5px 0 0 10px', color: 'white'}}><DatabaseOutlined/>&nbsp;{props.t('menu.Serveis')}&nbsp;&gt;&nbsp;<InboxOutlined />&nbsp;{props.t('menu.Acollida')}</p>
              <Row justify="center" align="middle">
                <Col><Typography.Title level={4}>{props.t('menu.Acollida')}</Typography.Title></Col>
              </Row>
            </div>
          </Col>
        </Row>


        {mostrarNewPassword
          ?
            <Modal
            key="NewPassword"
            visible={mostrarNewPassword}
            footer={null}
            width={550}
            style={{top:20}}
            bodyStyle={{ height: "350px"}}
            destroyOnClose={true}
            //forceRender={true}
            maskClosable={false}
            closable={false}
            >
              <NewPassword/>
            </Modal>
          :
          null
        }



    </>
  );
}

export default translate(DashboardManteniment);
