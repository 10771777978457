import React, { useState, useEffect, useContext } from 'react';
import moment from 'moment';
import 'moment/locale/ca';

import locale_ca from 'antd/es/date-picker/locale/ca_ES';
import locale_en from 'antd/es/date-picker/locale/en_GB';
import locale_es from 'antd/es/date-picker/locale/es_ES';

import { translate } from 'react-multi-lang';
import { Table, Row, Col, Pagination, DatePicker, Input, Space, Button, Modal, Select, Form, Tooltip, Popconfirm, message } from 'antd';
import { SearchOutlined, GoldOutlined, CheckOutlined, CloseOutlined, EditOutlined, DeleteOutlined, AlertFilled, ClearOutlined} from '@ant-design/icons';
import { AllHtmlEntities } from 'html-entities';

import ContainerPagina from '../../../components/ContainerPagina';
import TitolPagina from '../../../components/TitolPagina';
import TriaCursEscolarMultiple from '../../../components/TriaCursEscolarMultiple';
import RebutsForm from '../../../components/Formularis/perfils/admin/Rebuts';
//import RebutsBancExportaExcel from '../../../components/Formularis/perfils/admin/RebutsBancExportaExcel';
//import RebutsAltresExportaExcel from '../../../components/Formularis/perfils/admin/RebutsAltresExportaExcel';
import RebutsGeneraExportacions from '../../../components/Formularis/perfils/admin/RebutsGeneraExportacions';

import { Api, InitDades } from '../../../helpers';
import { PerfilUsuariContext } from "../../../context/perfil-usuari";

const { Column } = Table;
const entities = new AllHtmlEntities();
const dateFormat = 'DD/MM/YYYY HH:mm';
const dateRangeFormat = 'DD/MM/YYYY';


function Rebuts(props) {
  moment.locale('es');

  const [perfilUsuari] = useContext(PerfilUsuariContext);
  const [cursosEscolarsTriats, setCursosEscolarsTriats] = useState([perfilUsuari.curs_escolar_actual.id]);
  const [llista, setLlista] = useState([]);
  const [llistaFiltrada, setLlistaFiltrada] = useState([]);
  const [filtreCreated, setFiltreCreated] = useState([]);
  const [filtresClasses, setFiltresClasses] = useState([]);
  const [filtresPressupost, setFiltresPressupost] = useState([]);

  //const [fini, setFini] = useState(moment('2021-03-03'));
  //const [ffin, setFfin] = useState(moment('2021-03-03'));
  const [fini, setFini] = useState(moment().clone().startOf('month'));
  const [ffin, setFfin] = useState(moment().clone().endOf('month'));
  const [dataEnCurs, setDataEnCurs] = useState(moment());
  const [modalVisible, setModalVisible] = useState(false);
  const [refrescar, setRefrescar] = useState(0);
  const [tipus, setTipus] = useState('');
  const [id_taula, setId_taula] = useState(0);
  const [rebutTriat, setRebutTriat] = useState([]);
  const [dataAmbit, setDataAmbit] = useState([]);
  const [id_ambit, setId_ambit] = useState(null);
  const [id_profe, setId_profe] = useState(0);
  const [optionsProfessors, setOptionsProfessors] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [liniesIsLoading, setLiniesIsLoading] = useState(false);
  const [expandedRowKeys, setExpandedRowKeys] = useState([]);
  const [id_rebutActual, setId_rebutActual] = useState(0);


  //Canvi massiu estat
  const [triats, setTriats] = useState([]);
  const [estatTriat, setEstatTriat] = useState('pendent');
  const [estatsOptions, setEstatsOptions] = useState([]);
  const [modificarEstatTriatsLoading, setModificarEstatTriatsLoading] = useState(false);
  const [modalEstatVisible, setModalEstatVisible] = useState(false);

  //Exportar rebuts
  const [modalExportarVisible, setModalExportarVisible] = useState(false);
  //const [dadesRebutsBanc, setDadesRebutsBanc] = useState([]);
  //const [dadesRebutsAltres, setDadesRebutsAltres] = useState([]);

  //CERCA
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const [searchInput, setSearchInput] = useState([]);
  //FI CERCA
  //filtres i ordenacio
  const [filteredInfo, setFilteredInfo] = useState({});
  const [sortedInfo, setSortedInfo] = useState({});

  const tableHandleChange = (pagination, filters, sorter) => {
    setFilteredInfo(filters);
    setSortedInfo(sorter);
  }
  const clearAllFilters = () => {
    setFilteredInfo({});
    setSortedInfo({});
  }
  //FI filtres i ordenacio

  useEffect(() => {
    muntarSelectEstat();
    muntarFiltreClasses();
  },[]);


  useEffect(() => {
    buscarDades();
  },[refrescar, fini, ffin]);

  useEffect(() => {
    filtrarPerDates();
  },[llista]);

  /*const dadesRebutsExcel = async () =>{

    const rebutsBanc = await Api.get('/rebuts/fitxer/excel/banc');
    if(rebutsBanc.result.success===1){
      setDadesRebutsBanc(rebutsBanc.data.rebuts);
      let dades = {
        estat: 'enviat',
        ids_rebuts: rebutsBanc.data.llista_ids
      }
      const resposta = await Api.myFetch(`/rebuts/estat/enviat`, 'PUT', JSON.stringify(dades));
      setRefrescar(refrescar+1);
    }

    const rebutsAltres = await Api.get('/rebuts/fitxer/excel/altres');
    if(rebutsAltres.result.success===1){
      setDadesRebutsAltres(rebutsAltres.data.rebuts);
    }
  }*/

  const estatModificat = () =>{
    setRefrescar(refrescar+1);
  }

  const buscarDades = async () => {
    setExpandedRowKeys([]);
    setIsLoading(true);
    const data_inicial = fini ? fini.clone().format('YYYY-MM-DD') : null;
    const data_final = ffin ? ffin.clone().add(1, 'days').format('YYYY-MM-DD') : null;
    const cursosEscolars = cursosEscolarsTriats.length > 0 ? cursosEscolarsTriats.join(',') : '0';
    //let r = await Api.get(`/rebuts/${data_inicial}/${data_final}`);
    //console.log(`/rebuts/headers/${data_inicial}/${data_final}/cursos/${cursosEscolars}`)
    let r = await Api.get(`/rebuts/headers/${data_inicial}/${data_final}/cursos/${cursosEscolars}`);
    if(r.result.success===1){
      setLlista(r.data.rebuts);
      //console.log(r.data.rebuts)
    }
    setIsLoading(false);
  }

  const muntarFiltrePerDates = (dades) =>{
    dades.sort((a, b) => moment(b.creat).diff(moment(a.creat)));
    const createdList = dades.map((item) => {
      return moment(item.creat).format('DD/MM/YYYY')
    });
    const createdListSenseDuplicats = createdList.filter((item, index, arr) => arr.indexOf(item) == index);
    const filtre = createdListSenseDuplicats.map(item => {
      return {text: item, value: item}
    });
    setFiltreCreated(filtre);
  }


  const filtrarPerDates = () =>{
    //console.log('filtrarPerDates')
    if(!fini && !ffin){
      setLlistaFiltrada(llista);
      muntarFiltrePerDates(llista);
      muntarFiltrePressupost(llista);
    }else{
      const llFiltrada = llista.filter((item, index, array) => moment(item.creat).isBetween(fini, ffin, 'day', '[]'));
      setLlistaFiltrada(llFiltrada);
      muntarFiltrePerDates(llFiltrada);
      muntarFiltrePressupost(llFiltrada);
    }

  }

  const muntarFiltreClasses = async() =>{
    const cla = await Api.get('/classes');
    if(cla.result.success!==1){return [];}
    const filtreCl = cla.data.classes.map((item)=>{
      return {text: item.nom, value: item.id}
    });
    setFiltresClasses(filtreCl);
  }


  const muntarFiltrePressupost = (dades) =>{

    let temp_list = [];
    let pressupostList = [];
    dades.map((item) => {
      if(temp_list.indexOf(item.id_pressupost) === -1){
        temp_list.push(item.id_pressupost)
        if(item.id_pressupost > 0){
          pressupostList.push({text: entities.decode(item.pressupost.descripcio), value: item.pressupost.id_pressupost});
        }else{
          pressupostList.push({text: '', value: 0});
        }
      }
    });

    pressupostList.sort((a, b) => a.value - b.value);
    setFiltresPressupost(pressupostList);
  }

  const netejarFiltres = () =>{
    setFini(null);
    setFfin(null);
  }
  const avuiFiltre = () => {
    setFini(moment());
    setFfin(moment());
  }
  const mesActualFiltre = () => {
    setFini(moment().clone().startOf('month'));
    setFfin(moment().clone().endOf('month'));
  }
  const mesAnteriorfiltre = () => {
    setFini(moment().clone().subtract(1,'months').startOf('month'));
    setFfin(moment().clone().subtract(1,'months').endOf('month'));
  }


  const onDateRangeChange = (dates, dateStrings) => {
    setFini(dates ? dates[0] : '');
    setFfin(dates ? dates[1] : '');
    /*
    const llFiltrada = llista.filter((item, index, array) => moment(item.date).isBetween(dates[0], dates[1], 'day', '[]'));
    setLlistaFiltrada(llFiltrada);
    */

  }


  const onExpandLinies = async (expanded, record) =>{
    if(expanded){
      setId_rebutActual(record.id);
      let expan = expandedRowKeys;
      expan.push(record.id);
      setExpandedRowKeys(expan);
    }else{
      const expan = expandedRowKeys.filter((element, index, arr) => element !== record.id);
      setExpandedRowKeys(expan);
    }

    await refrescarLinies(record.id);

  }

  const refrescarLinies = async(id_rebut) =>{

    console.log('dins refrescarLinies', id_rebut)
    setLiniesIsLoading(true);
    let linies_tt = [];
    const linies = await Api.get(`/rebut/linies/${id_rebut}`);
    if(linies.result.success===1){
      linies_tt = linies.data.linies;
      const llista_t = llista.map((item) =>{
        if(item.id === id_rebut){
          item.linies = linies_tt;
        }
        return item;
      });
      setLlista(llista_t);
/*
      const llista_tt = llistaFiltrada.map((item) =>{
        if(item.id === id_rebut){
          item.linies = linies_tt;
        }
        return item;
      });
      setLlistaFiltrada(llista_tt);
      */

    }
    setLiniesIsLoading(false);
    return linies_tt;

  }

  const refrescarHeader = async(id_rebut) =>{
    console.log('dins refrescarHeader', id_rebut);

    let header_tt = [];
    const rebut = await Api.get(`/rebut/header/${id_rebut}`);
    if(rebut.result.success===1){
      header_tt = rebut.data.rebut;
      console.log('rebut', header_tt)

      //setRebutTriat(rebut.data.rebut);

      const llista_tt = llistaFiltrada.map((item) =>{
        if(item.id === id_rebut){
          item = header_tt;
        }
        return item;
      });
      await setLlistaFiltrada(llista_tt);

      const llista_t = llista.map((item) =>{
        if(item.id === id_rebut){
          item = header_tt;
        }
        return item;
      });
      await setLlista(llista_t);

    }
    return header_tt;
  }


  const modificarEstatTriats = async () =>{

    setModificarEstatTriatsLoading(true);

    let dades = {
      estat: estatTriat,
      ids_rebuts: triats
    }

    try{
        //console.log(JSON.stringify(dades))
      const resposta = await Api.myFetch(`/rebuts/estat/${estatTriat}`, 'PUT', JSON.stringify(dades));
      if(resposta.result.success === 1){
        setTriats([]);
        setEstatTriat('Pendent');
        setModificarEstatTriatsLoading(false);
        setModalEstatVisible(false);
        setRefrescar(refrescar+1);
      }else{
        setModificarEstatTriatsLoading(false);
        console.error('Error: submit ComandesExtraescolarsEdit: ',resposta);
        message.config({top: 50});
        message.open({content: resposta.data.message, duration:10});
        return false;
      }

    }catch(error){
      setModificarEstatTriatsLoading(false);
      message.config({top: 50});
      message.open({content: "Error saving state", duration:10});
      console.error('Error: ComandesRoba guardar estat: ', error);
      return false;
    }

  }

  let localeDatePicker;
  switch (props.t('idioma')) {
    case 'ca':
      localeDatePicker = locale_ca;
      break;
    case 'en':
      localeDatePicker = locale_en;
      break;
    case 'es':
      localeDatePicker = locale_es;
      break;
    default:
      localeDatePicker = locale_ca;
  }

  //CERCA
    const getColumnSearchProps = (dataIndex, nom) => ({
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <div style={{ padding: 8 }}>
          <Input
            ref={node => {
              let search = searchInput;
              search[dataIndex] = node;
              setSearchInput(search);
            }}
            placeholder={`${props.t('generic.cercar')} ${nom}`}
            value={selectedKeys[0]}
            onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
            style={{ width: 188, marginBottom: 8, display: 'block' }}
            autoFocus="focus"
          />
          <Space>
            <Button
              type="primary"
              onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
              icon={<SearchOutlined />}
              size="small"
              style={{ width: 90 }}
            >
              {props.t('generic.cercar')}
            </Button>
            <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
              Reset
            </Button>
          </Space>
        </div>
      ),
      filterIcon: filtered => <SearchOutlined style={{color: filtered ? '#1890ff' : undefined }} />,
      onFilter: (value, record) =>
        record[dataIndex] ? arreglarTxt4Search(entities.decode(record[dataIndex].toString())).includes(arreglarTxt4Search(value)) : '',
      onFilterDropdownVisibleChange: visible => {
        if (visible) {
          setTimeout(() => searchInput[dataIndex].select(),100);
        }
      },
     render: text => (entities.decode(text)),
    });

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
      confirm();
      setSearchText(selectedKeys[0]);
      setSearchedColumn(dataIndex);
    };

    const handleReset = clearFilters => {
      clearFilters();
      setSearchText('');
    };

    const arreglarTxt4Search = text =>{
        // Poso en minuscules, normalitzo caracters treure accents i ñ , elimina els caracters diacrítics raros d'un text (ES6)
        //return text.trim().split(' ')[0].toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g,"");
        return text.trim().toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g,"");
    }
  //FI CERCA

// Editar Afegir a traves de modal
  const tancarModal = async e =>{
    //setRefrescar(refrescar+1);

    //setExpandedRowKeys([]);

    //await refrescarHeader(rebutTriat.id);
    await refrescarLinies(rebutTriat.id);
    setModalVisible(false);

  }

  const afegirRebut = () =>{
    let rebut = InitDades.rebut();
    rebut.fcrea = moment();
    setRebutTriat(rebut);
    setModalVisible(true);
  }

  const editarRebut = async (item) =>{

    item.linies = await refrescarLinies(item.id);

    item.fcrea = moment(item.fcrea).tz('Europe/Madrid');
    setRebutTriat(item);
    setModalVisible(true);
  }

  const submitDelete = async (id) => {

    const r = await Api.myFetch(`/rebut/${id}`, 'DELETE');
    if(r.result.success===1){
      setRefrescar(refrescar+1);
    }
  }

  const triaCursChange = (cursos) => {
    //console.log(cursos)
    setCursosEscolarsTriats(cursos);
    setRefrescar(refrescar+1);
  }


const tradueixTipusPagament = (value) =>{
  switch (value) {
    case 'BANC':
      return props.t('families.pagament_banc');
      break;
    case 'TRANSFERENCIA':
      return props.t('families.pagament_transferencia');
      break;
    case 'EFECTIU':
      return props.t('families.pagament_efectiu');
      break;
    case 'TARGETA':
      return props.t('families.pagament_targeta');
      break;
    case 'XEC_GUARDERIA':
      return props.t('families.pagament_xec_guarderia');
      break;
    default:
      return '--';

  }
}

const filtreTipusPagament =[
  {text: <span>{props.t('families.pagament_banc')}</span>, value: 'BANC'},
  {text: <span>{props.t('families.pagament_transferencia')}</span>, value: 'TRANSFERENCIA'},
  {text: <span>{props.t('families.pagament_efectiu')}</span>, value: 'EFECTIU'},
  {text: <span>{props.t('families.pagament_targeta')}</span>, value: 'TARGETA'},
  {text: <span>{props.t('families.pagament_xec_guarderia')}</span>, value: 'XEC_GUARDERIA'},

];

const muntarSelectEstat = () =>{

  let estats = [
    'temporal',
    'revisio',
    'pendent',
    'enviat',
    'rebutjat',
    'cobrat'
  ];

  const llista_estats = estats.map((valor) =>{
    return(
      <Select.Option key={valor} value={valor}>
        <span><AlertFilled style={{color: `${props.t(`rebuts.estatColor.${valor}`)}`}}/>&nbsp;{props.t(`rebuts.estat.${valor}`)}</span>
      </Select.Option>
    );
  });
  setEstatsOptions(llista_estats);
}

const filtreEstat =[
  {text: <span><AlertFilled style={{color: `${props.t('rebuts.estatColor.temporal')}`}}/>&nbsp;{props.t('rebuts.estat.temporal')}</span>, value: 'temporal'},
  {text: <span><AlertFilled style={{color: `${props.t('rebuts.estatColor.revisio')}`}}/>&nbsp;{props.t('rebuts.estat.revisio')}</span>, value: 'revisio'},
  {text: <span><AlertFilled style={{color: `${props.t('rebuts.estatColor.pendent')}`}}/>&nbsp;{props.t('rebuts.estat.pendent')}</span>, value: 'pendent'},
  {text: <span><AlertFilled style={{color: `${props.t('rebuts.estatColor.enviat')}`}}/>&nbsp;{props.t('rebuts.estat.enviat')}</span>, value: 'enviat'},
  {text: <span><AlertFilled style={{color: `${props.t('rebuts.estatColor.rebutjat')}`}}/>&nbsp;{props.t('rebuts.estat.rebutjat')}</span>, value: 'rebutjat'},
  {text: <span><AlertFilled style={{color: `${props.t('rebuts.estatColor.cobrat')}`}}/>&nbsp;{props.t('rebuts.estat.cobrat')}</span>, value: 'cobrat'}
];

const columnesTaulaPropietats = [
    {
      title: 'id',
      dataIndex: "id_linia",
      key: "id_linia"
    },
    {
      title: props.t('generic.concepte'),
      dataIndex: "concepte",
      key: "concepte",
      render: (text,record) => <span>{entities.decode(record.concepte)}</span>
    },
    {
      title: props.t('generic.preu'),
      dataIndex: "preu",
      key: "preu",
      render: (text,record) => <span>{record.preu}€</span>

    },
    {
      title: props.t('generic.unitats'),
      dataIndex: "unitats",
      key: "unitats"
    },
    {
      title: props.t('generic.descompte'),
      dataIndex: "descompte",
      key: "descompte",
      render: (text,record) => <span>{record.descompte}%</span>
    },
    {
      title: props.t('banc.iban_percentatge_curt'),
      dataIndex: "participacio",
      key: "participacio",
      render: (text,record) => <span>{record.participacio}%</span>       
    },
    {
      title: props.t('generic.subtotal'),
      dataIndex: "subtotal",
      key: "subtotal",
      render: (text,record) => <span>{record.subtotal}€</span>

    },

  ];

const columnes =[
  {
    title:"Id",
    key:"id",
    dataIndex:'id',
    sorter: (a, b) =>  a.id - b.id,
    sortOrder: sortedInfo.columnKey === 'id' && sortedInfo.order,
    render: (text,record) => (<span>{record.id}</span>)
  },{
    title: props.t('botiga.titolEstat'),
    key:"estat",
    dataIndex: 'estat',
    filters: filtreEstat,
    filteredValue: filteredInfo.estat || null,
    onFilter: (value, record) => record.estat === value,
    render: (text,record) =>{
      return (<span><AlertFilled style={{color: `${props.t(`rebuts.estatColor.${record.estat}`)}`}}/>&nbsp;{props.t(`rebuts.estat.${record.estat}`)}</span>);
    },
    sorter: (a, b) => { return a.estat.localeCompare(b.estat)},
    sortOrder: sortedInfo.columnKey === 'estat' && sortedInfo.order,
  },{
    title: props.t('menu.Pressupost'),
    key:"id_pressupost",
    dataIndex: 'id_pressupost',
    filters: filtresPressupost,
    filteredValue: filteredInfo.id_pressupost || null,
    onFilter: (value, record) => record.id_pressupost === value,
    render: (text,record) =>(entities.decode(record.pressupost.descripcio)),
    sorter: (a, b) => { return entities.decode(a.pressupost.descripcio).localeCompare(entities.decode(b.pressupost.descripcio))},
    sortOrder: sortedInfo.columnKey === 'id_pressupost' && sortedInfo.order,
  },{
    title: props.t('alumnes.classe'),
    key:"classe",
    dataIndex: 'classe',
    filters: filtresClasses,
    filteredValue: filteredInfo.classe || null,
    onFilter: (value, record) => record.id_classe === value,
    render: (text,record) =>(entities.decode(record.classe)),
    sorter: (a, b) => { return entities.decode(a.classe).localeCompare(entities.decode(b.classe))},
    sortOrder: sortedInfo.columnKey === 'classe' && sortedInfo.order,
  },{
    title: props.t('generic.alumne'),
    key: "alumne",
    dataIndex: "alumne",
    render: (text,record) => (entities.decode(record.alumne)),
    sorter: (a, b) => { return entities.decode(a.alumne).localeCompare(entities.decode(b.alumne))},
    sortOrder: sortedInfo.columnKey === 'alumne' && sortedInfo.order,
    ...getColumnSearchProps('alumne',props.t('generic.alumne')),
    filteredValue: filteredInfo.alumne || null,
  },{
    title: props.t('banc.titular'),
    key: "titular",
    dataIndex: "titular",
    render: (text,record) => (entities.decode(record.titular)),
    sorter: (a, b) => { return entities.decode(a.titular).localeCompare(entities.decode(b.titular))},
    sortOrder: sortedInfo.columnKey === 'titular' && sortedInfo.order,
    ...getColumnSearchProps('titular',props.t('banc.titular')),
    filteredValue: filteredInfo.titular || null,
  },{
    title: props.t('families.tipus_pagament'),
    key: "tipus_pagament",
    width: "175px",
    dataIndex: "tipus_pagament",
    filters: filtreTipusPagament,
    filteredValue: filteredInfo.tipus_pagament || null,
    onFilter: (value, record) => record.tipus_pagament === value,
    render: (text,record) => (tradueixTipusPagament(record.tipus_pagament)),
    sorter: (a, b) => { return entities.decode(tradueixTipusPagament(a.tipus_pagament)).localeCompare(entities.decode(tradueixTipusPagament(b.tipus_pagament)))},
    sortOrder: sortedInfo.columnKey === 'tipus_pagament' && sortedInfo.order,
  },{
    title: props.t('generic.total'),
    width: '100px',
    dataIndex: "import",
    key: "import",
    render: (text,record) => (<span style={parseFloat(record.import) <= 0 ? {color:'red'} : {}}><b>{parseFloat(record.import).toFixed(2)}€</b></span>),
    sorter: (a, b) =>  a.import - b.import,
    sortOrder: sortedInfo.columnKey === 'import' && sortedInfo.order,
  },{
    title: props.t('rebuts.estat.pendent'),
    width: '100px',
    dataIndex: "import_entregat",
    key: "import_entregat",
    render: (text,record) => {
      if(record.estat === 'cobrat'){
        return <b>-</b>
      }
      const pendent = parseFloat(record.import) - parseFloat(record.import_entregat);
      return <span style={pendent <= 0 ? {color:'red'} : {}}><b>{parseFloat(pendent).toFixed(2)}€</b></span>
      },
    sorter: (a, b) => {
      var pendentA = 0;
      var pendentB = 0;

      if(a.estat !== 'cobrat'){
        pendentA = parseFloat(a.import) - parseFloat(a.import_entregat);
      }
      if(b.estat !== 'cobrat'){
        pendentB = parseFloat(b.import) - parseFloat(b.import_entregat);
      }

      return pendentA - pendentB;
    },
    sortOrder: sortedInfo.columnKey === 'import_entregat' && sortedInfo.order,
  },{
    title: props.t('generic.created_at'),
    key: "creat",
    dataIndex: "creat",
    width: "175px",
    filters: filtreCreated,
    filteredValue: filteredInfo.creat || null,
    onFilter: (value, record) => (moment(record.creat).format('DD/MM/YYYY') === value),
    sorter: (a, b) => moment(a.creat).diff(moment(b.creat)),
    sortOrder: sortedInfo.columnKey === 'creat' && sortedInfo.order,
    render: (text,record) =>(
        record.creat ? moment(record.creat).tz('Europe/Madrid').format(dateFormat) : ''
    )
  },{
    title: (
      <Tooltip placement="top" title="Clear filters">
        <Button icon={<ClearOutlined />} onClick={(e) => clearAllFilters()} ghost="true" className="BotoTaula"/>
      </Tooltip>
    ),
    key: "actions",
    align: "right",
    width: "75px",
    render: (text,record) => (
      <Row type="flex" justify="end" id={"actions_"+record.id}>
        <Col span={12 }>
          <Tooltip placement="top" title={props.t('generic.modify')}>
            <Button icon={<EditOutlined />} onClick={(e) => editarRebut(record)} ghost="true" className="BotoTaula"/>
          </Tooltip>
        </Col>
        <Col span={12}>
          <Tooltip placement="top" title={props.t('generic.delete')}>
            <Popconfirm placement="bottom" title={props.t('generic.confirm_delete')} okText={props.t('generic.si')} cancelText={props.t('generic.no')} onConfirm={()=>submitDelete(record.id)}>
              <Button icon={<DeleteOutlined />} data-record={JSON.stringify(record)} ghost="true" className="BotoTaula"/>
            </Popconfirm>
          </Tooltip>
        </Col>
      </Row>
  )
  }
];


//return <Col span={12} style={{margin: 0, padding: 0}}><Table style={{backgroundColor: '#eeeeee'}} columns={columnesTaulaPropietats} dataSource={row.propietats} pagination={false} size={'small'} bordered={false}/></Col>;
const subTaulaPropietats = row => {
  return (
    <Col span={12} style={{margin: 10, padding: 0}}>
      <Table
        rowKey="id_linia"
        columns={columnesTaulaPropietats}
        dataSource={row.linies}
        pagination={false} size={'small'}
        bordered={false}
        loading={liniesIsLoading && id_rebutActual == row.id}
      />
    </Col>
  );
};

  return(
    <ContainerPagina xl={24}>
      <TitolPagina txt={props.t('menu.Rebuts')}/>
      <div style={{marginTop:'1rem', marginLeft:'3rem', marginRight: '1rem'}}>
      <Row align="middle" justify="space-between" gutter={5} style={{marginBottom: '1rem'}}>
        <Col>
          <Space>
            <span><b>{props.t('generic.filtre')}:&nbsp;</b></span>
            <DatePicker.RangePicker
              value={[fini ? moment(fini, dateRangeFormat) : null, ffin ? moment(ffin, dateRangeFormat) : null]}
              format={dateRangeFormat}
              locale={localeDatePicker}
              onChange={onDateRangeChange}
              size="large"
            />
            <Button type="primary" onClick={mesActualFiltre}>{props.t('generic.mes_actual')}</Button>
            <Button type="primary" onClick={mesAnteriorfiltre}>{props.t('generic.mes_anterior')}</Button>
            <Button type="primary" onClick={avuiFiltre}>{props.t('generic.avui')}</Button>
            <Button type="primary" onClick={netejarFiltres}>{props.t('generic.tot')}</Button>
            <TriaCursEscolarMultiple onChange={(c) => triaCursChange(c)}/>
          </Space>
        </Col>
        <Col>
          {/*<Space>
            <Button type="secondary" onClick={() => dadesRebutsExcel()}>Generar Excel Banc</Button>
          {dadesRebutsBanc.length ==0 ? null :
              <RebutsBancExportaExcel dades={dadesRebutsBanc} btn_txt="Rebuts BANC" nom_fulla="Rebuts" nom_fitxer={'Rebuts_BANC'}/>
           }
           {dadesRebutsAltres.length ==0 ? null :
              <RebutsAltresExportaExcel dades={dadesRebutsAltres} btn_txt="Rebuts ALTRES" nom_fulla="Rebuts" nom_fitxer={'Rebuts_ALTRES'}/>
           }
          </Space>*/}
        <Button type="secondary" onClick={()=>setModalExportarVisible(true)}>{props.t('rebuts.exportar')}</Button>
        </Col>
        <Col>
          <Space>
            <Button type="default" disabled={!triats.length > 0 } onClick={()=>setModalEstatVisible(true)}>{props.t('rebuts.modificar_estat')}</Button>
            <Tooltip placement="top" title={props.t('comandes.triats_netejar')}>
              <Button type="default" disabled={!triats.length > 0 }  icon={<ClearOutlined/>} onClick={()=>setTriats([])}></Button>
            </Tooltip>
            <Button type="primary" onClick={afegirRebut}>{props.t('generic.add')}</Button>
          </Space>
        </Col>
      </Row>
        <Table
          scroll={{ x: 1500}}
          size="small"
          loading={isLoading}
          pagination={{position:['topRight', 'bottomRight'], showSizeChanger: true, defaultPageSize: 500, pageSizeOptions:[10,15,20,25,50,100,300,500], total: llistaFiltrada.length, showTotal: (total, range) => `${range[0]}-${range[1]} de ${total} rebuts`}}
          rowKey="id"
          expandedRowRender={subTaulaPropietats}
          defaultExpandAllRows={false}
          expandedRowKeys={expandedRowKeys}
          onExpand={(expanded, record) => onExpandLinies(expanded, record)}
          dataSource={llistaFiltrada}
          locale={{filterConfirm: 'OK', filterReset: 'Reset', emptyText: 'No Data'}}
          columns={columnes}
          onChange={tableHandleChange}
          onRow={(record, rowIndex) => {
            return {
              onClick: event => {}, //onClick: (e) => showEdit(record), // click row
              onDoubleClick: (e) => editarRebut(record), // double click row
              onContextMenu: (e) => {e.preventDefault(); editarRebut(record);}, //event => {}, // right button click row
            };
          }}
          rowSelection={{
          selectedRowKeys: triats,
          hideSelectAll: false,
          type: 'checkbox',
          onChange: (selectedRowKeys, selectedRows) => {
            setTriats(selectedRowKeys);
          }
        }}
        summary={(row) =>{
          let sumaSubtotals = 0;
          let sumaPendents = 0;
          row.forEach((item) => {
            sumaSubtotals += item.import;
            if(item.estat !== 'cobrat'){
              sumaPendents += (parseFloat(item.import) - parseFloat(item.import_entregat));
            }
          });
          return(
            <Table.Summary.Row>
              <Table.Summary.Cell colSpan={8}></Table.Summary.Cell>
              <Table.Summary.Cell><b>Total:</b></Table.Summary.Cell>
              <Table.Summary.Cell><b>{parseFloat(sumaSubtotals).toFixed(2)}€</b></Table.Summary.Cell>
              <Table.Summary.Cell><b>{parseFloat(sumaPendents).toFixed(2)}€</b></Table.Summary.Cell>
            </Table.Summary.Row>
          )}}
        />
      </div>
      <Modal
          key="rollcall"
          title={`${rebutTriat.id === 0 ? props.t('generic.add') : props.t('generic.modify')} ${props.t('menu.Rebuts')} ${rebutTriat.id === 0 ? '' : ' - ID: '+rebutTriat.id}`}
          visible={modalVisible}
          footer={null}
          onCancel={tancarModal}
          width={1200}
          style={{top:20}}
          destroyOnClose={true}
          forceRender={true}
        >
        {/*<RebutsForm item={rebutTriat} callback={tancarModal} pressupostos={filtresPressupost}/>*/}
        <RebutsForm item={rebutTriat} callback={tancarModal} callback_refrescar={estatModificat}/>
      </Modal>
      <Modal
        title={props.t('rebuts.modificar_estat')}
        destroyOnClose={true}
        visible={modalEstatVisible}
        onOk={modificarEstatTriats}
        confirmLoading={modificarEstatTriatsLoading}
        onCancel={()=>setModalEstatVisible(false)}
      >
        <p>{props.t('rebuts.modificar_estat_rebuts')}</p>
        <Form.Item label={props.t('botiga.titolEstat')}>
          <Select onChange={(value) => setEstatTriat(value)} defaultValue="pendent">
            {estatsOptions}
          </Select>
        </Form.Item>
      </Modal>
      <Modal
          title={props.t('rebuts.exportar')}
          key="exportarRebuts"
          visible={modalExportarVisible}
          footer={null}
          onCancel={()=>setModalExportarVisible(false)}
          width={600}
          style={{top:20}}
          destroyOnClose={true}
          forceRender={true}
        >
        <RebutsGeneraExportacions callback={estatModificat}/>
      </Modal>
    </ContainerPagina>
  );

}

export default translate(Rebuts);
