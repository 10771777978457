import React, { useState, useEffect } from 'react';
import { translate } from 'react-multi-lang';
import { SyncOutlined } from '@ant-design/icons';
import {Form, Row, Col, Select, Button, Input, InputNumber, Divider, Switch, DatePicker, message } from 'antd';
import moment from 'moment';
import 'moment/locale/ca';
import 'moment/locale/es';

import locale_ca from 'antd/es/date-picker/locale/ca_ES';
import locale_en from 'antd/es/date-picker/locale/en_GB';
import locale_es from 'antd/es/date-picker/locale/es_ES';

import { AllHtmlEntities } from 'html-entities';

import { Api, HtmlStrings } from '../../../../helpers';
import TextAreaCount from '../../../TextAreaCount';

const entities = new AllHtmlEntities();

function RebutsEntregatCompteEdit({t, item, pendent, callback}){

  const [id, setId] = useState(item.id);
  const [id_rebut, setId_rebut] = useState(item.id_rebut);
  const [importe, setImporte] = useState(item.import);
  const [concepte, setConcepte] = useState(entities.decode(item.concepte));
  const [tipus_pagament, setTipus_pagament] = useState(item.id===0 ? '' : item.tipus_pagament);
  //const [data_pagament, setData_pagament] = useState(item.data_pagament ? item.data_pagament : moment.utc(new Date()));
  const [data_pagament, setData_pagament] = useState(item.data_pagament);

  const [guardant, setGuardant] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [nou, setNou] = useState(item.id===0);

  const [errorTipusPagament, setErrorTipusPagament] = useState('');
  const [errorTipusPagamentText, setErrorTipusPagamentText] = useState('');
  const [errorImport, setErrorImport] = useState('');
  const [errorConcepte, setErrorConcepte] = useState('');
  const [errorDataPagament, setErrorDataPagament] = useState('');



  const changeFcrea = (date, dateString) => {
    if(date){
      setData_pagament(date.toISOString());

      setErrorDataPagament('');
    }else{
      setData_pagament(moment.utc(new Date()));
    }
  }

  const triaTipusPagament = (value) =>{
    setTipus_pagament(value);
    if(value.length>0){
      setErrorTipusPagament('');
      setErrorTipusPagamentText('');
    }
  }

  const submitForm = async (valors) => {

    let correcte = true;

    if(!data_pagament){
      setErrorDataPagament('error');
      correcte = false;
    }

    if(tipus_pagament === ''){
      setErrorTipusPagament('error');
      setErrorTipusPagamentText(t('generic.tria'))
      correcte = false;
    }

    if(importe == 0){
      setErrorImport('error');
      correcte = false;
    }
    if(!concepte){
      setErrorConcepte('error');
      correcte = false;
    }

    if(!correcte){return false}

    setGuardant(true);

    let dades = {
        id,
        id_rebut,
        import: importe,
        concepte,
        tipus_pagament,
        data_pagament: moment.utc(data_pagament)
      }

      let method = nou?'POST':'PUT';

      let url_api = nou? '/rebuts/entregat/compte/'+id_rebut : '/rebuts/entregat/compte/'+id;

      console.log(JSON.stringify(dades));
      try{
        const resposta = await Api.myFetch(url_api, method, JSON.stringify(dades));

        if(resposta.result.success === 1){
          setGuardant(false);
          console.log('resposta', resposta);
          message.success(t('generic.OK_saved'), 5);
          callback();
        }else{
          setGuardant(false);
          console.error('Error: submit Rebuts entregat a compte edit: ',resposta);
          message.config({top: 50});
          message.open({content: resposta.data.message, duration:10});
        }
      }catch(error){
        setGuardant(false);
        message.config({top: 50});
        message.open({content: "Error saving", duration:10});
        console.error('Error: submit Rebuts entregat a compte edit guardar: ', error);
      }

  }

  let localeDatePicker;
  switch (t('idioma')) {
    case 'ca':
      localeDatePicker = locale_ca;
      break;
    case 'en':
      localeDatePicker = locale_en;
      break;
    case 'es':
      localeDatePicker = locale_es;
      break;
    default:
      localeDatePicker = locale_ca;
  }
  const dateFormat = 'DD/MM/YYYY';

  if(isLoading){
    return <SyncOutlined spin title="Loading..." style={{fontSize: '3rem'}} />;
  }

  return(
    <div style={{padding:'10px 24px 10px 0px'}}>
      <Form onFinish={submitForm}
        layout="horizontal"
        labelCol={{span:6}}
        wrapperCol={{span:18}}
        colon={true}
        autoComplete="off"
      >
        <Form.Item label={t('generic.data')} required={true} validateStatus={errorDataPagament}>
          <DatePicker
            onChange={changeFcrea}
            value={ data_pagament ? moment(data_pagament).tz('Europe/Madrid') : null}
            format={dateFormat}
            locale={localeDatePicker}
            />
        </Form.Item>

        <Form.Item label={t('families.tipus_pagament')} required={true} validateStatus={errorTipusPagament} help={errorTipusPagamentText} style={{marginBottom: 0}}>
          <Select defaultValue={tipus_pagament} onChange={triaTipusPagament} style={{ width: 160}}>
            <Select.Option key="BANC" value="BANC">{t('families.pagament_banc')}</Select.Option>
            <Select.Option key="TRANSFERENCIA" value="TRANSFERENCIA">{t('families.pagament_transferencia')}</Select.Option>
            <Select.Option key="EFECTIU" value="EFECTIU">{t('families.pagament_efectiu')}</Select.Option>
            <Select.Option key="TARGETA" value="TARGETA">{t('families.pagament_targeta')}</Select.Option>
            <Select.Option key="XEC_GUARDERIA" value="XEC_GUARDERIA">{t('families.pagament_xec_guarderia')}</Select.Option>
          </Select>
        </Form.Item>

        <Form.Item label={t('generic.concepte')} validateStatus={errorConcepte} required={true}>
          <TextAreaCount autoSize={{ minRows: 2, maxRows: 25 }} maxLength={255} name="concepte" placeholder={t('generic.concepte')} onChange={setConcepte} defaultValue={concepte}/>
        </Form.Item>

        <Form.Item label={t('quotes.import')} validateStatus={errorImport} required={true}>
          <InputNumber onChange={setImporte} value={importe}
          step={1.0}
          formatter={value => `${value}€`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
          parser={value => value.replace(/€\s?|(,*)/g, '')}
          />
          <Button type="primary" style={{marginLeft: '5px'}} onClick={(e)=>setImporte(pendent)}>{t('rebuts.assignar_pendent')}</Button>
        </Form.Item>

        <Col span={24}>
          <Row type="flex" justify={"space-between"} style={{marginTop: 25, marginBottom: 25}}>
            <Col>
              <Button onClick={callback}>{t('generic.cancel')}</Button>
            </Col>
            <Col style={{marginLeft:'10px'}}>
              <Button type="primary" htmlType="submit" loading={guardant}>{t('generic.save')}</Button>
            </Col>
          </Row>
        </Col>

      </Form>
    </div>
  );
}
export default translate(RebutsEntregatCompteEdit);
