import React, { useState, useEffect, useContext } from 'react';
import moment from 'moment';
import 'moment/locale/ca';
import 'moment/locale/es';

import locale_ca from 'antd/es/date-picker/locale/ca_ES';
import locale_en from 'antd/es/date-picker/locale/en_GB';
import locale_es from 'antd/es/date-picker/locale/es_ES';

import { translate } from 'react-multi-lang';
import { Row, Col, Typography, Button, Card, Switch, Radio, InputNumber, Form, Select, Space, DatePicker, message } from 'antd';
import { MinusOutlined, PlusOutlined, CarOutlined, ExperimentOutlined, CloseOutlined, PauseOutlined, AlertFilled} from '@ant-design/icons';
import { AllHtmlEntities } from 'html-entities';

import { PerfilUsuariContext } from "../../../../context/perfil-usuari";
import { Api, HtmlStrings } from '../../../../helpers';
import TextAreaCount from '../../../TextAreaCount';

const entities = new AllHtmlEntities();

function MantenimentEditDades(props){

  const [perfilUsuari] = useContext(PerfilUsuariContext);
  const [id, setId] = useState(props.item.id);
  const [estat, setEstat] = useState(props.item.estat);
  const [problema, setProblema]  = useState(props.item.problema || '');
  const [ubicacio, setUbicacio] = useState(props.item.ubicacio || '');
  const [solucio, setSolucio] = useState(props.item.solucio || '');
  const [observacions, setObservacions] = useState(props.item.observacions || '');
  const [h_ini, setH_ini] = useState(props.item.h_ini);
  const [m_ini, setM_ini] = useState(props.item.m_ini);
  const [h_fin, setH_fin] = useState(props.item.h_fin);
  const [m_fin, setM_fin] = useState(props.item.m_fin);

  const [estatsOptions, setEstatsOptions] = useState([])

  const [isLoadingClasse, setIsLoadingClasse] = useState(false);
  const [guardant, setGuardant] = useState(false);
  const [visibleHora, setVisibleHora] = useState(props.item.h_ini > 0);

  const [errorProblema, setErrorProblema] = useState('');

  let nou = (props.item.id===0);

  useEffect(() => {
    muntarSelectEstat();
  },[]);

  useEffect(() => {
    props.actualitzarId(id)
    nou = (props.item.id===0);
  },[id]);

  const handleVisibleHora = (enable) =>{
    setVisibleHora(enable)
  }

  const submitForm = async (valors) => {

    let correcte = true;

    if(problema.length <= 0){
      correcte = false;
    }

    if(!correcte){
      return false;
    }

    setGuardant(true);

    let dades = {
      estat,
      problema,
      ubicacio,
      solucio,
      observacions,
      h_ini: !visibleHora ? 0 : h_ini,
      m_ini: !visibleHora ? 0 : m_ini,
      h_fin: !visibleHora ? 0 : h_fin,
      m_fin: !visibleHora ? 0 : m_fin
    }

    //console.log(dades);
    console.log(JSON.stringify(dades));

    let method = nou?'POST':'PUT';
    let url_api = nou? '/tic/issue' : '/tic/'+id;


    try{
      const resposta = await Api.myFetch(url_api, method, JSON.stringify(dades));
      if(resposta.result.success === 1){
        console.log(resposta.data.id)
        setId(resposta.data.id);
        props.actualitzarId(resposta.data.id)
        setGuardant(false);
        message.success(props.t('generic.OK_saved'), 5);
/*
        if(props.tancarModal){
          props.tancarModal();
        }
        if(props.callback){
          props.callback();
        }
*/
      }else{
        setGuardant(false);
        console.error('Error: enviant notificacio: ',resposta);
        message.config({top: 50});
        message.open({content: resposta.data.message, duration:10});
      }
    }catch(error){
      setGuardant(false);
      message.config({top: 50});
      message.open({content: "Error saving", duration:10});
      console.error('Error: Enviant Notificacio: ', error);
    }

  }
  const problema_Change = (n) =>{
    setProblema(n);
    if(n.length>0){
      setErrorProblema('');
    }
  }

  const muntarSelectEstat = () =>{

    let estats = [
      'pendent',
      'encurs',
      'anulat',
      'resolt',
      'pausat',
      'faltamaterial'
    ]

    const llista_estats = estats.map((valor) =>{
      return(
        <Select.Option key={valor} value={valor}>
          <span><AlertFilled style={{color: `${props.t(`manteniment.estatColor.${valor}`)}`}}/>&nbsp;{props.t(`manteniment.estat.${valor}`)}</span>
        </Select.Option>
      );
    });
    setEstatsOptions(llista_estats);

  }


  return(
    <>
      <Form onFinish={submitForm} layout="vertical" autoComplete="off">
      {nou ? null :
        <Row style={{marginTop: '10px', marginBottom: '10px'}}>
          <b>ID: {id}</b>
        </Row>
      }
      <Row gutter={10}>
        <Col span={6}>
          <Form.Item label={props.t('botiga.titolEstat')}>
            <Select defaultValue={estat} onChange={(value) => setEstat(value)} disabled={estat === 'Facturat' ? true : false}>
              {estatsOptions}
            </Select>
          </Form.Item>
        </Col>

        <Col offset={6} span={12}>
          <Row>
            <Col>{props.t('manteniment.hora_recomanada')}&nbsp;&nbsp;</Col>
            <Col><Switch checked={visibleHora} onChange={handleVisibleHora} /></Col>
          </Row>
          { !visibleHora ? null :
            <Row justify="space-between" align="middle">
              <Col>
                <Form.Item label={props.t('manteniment.inici_recomanat')}>
                    <InputNumber name="hora" onChange={setH_ini} value={h_ini} min={0} max={23} step={1} formatter={value => `${value}h`} parser={value => value.replace('h', '')}/>
                    <span>&nbsp;:&nbsp;</span>
                    <InputNumber name="minut" onChange={setM_ini} value={m_ini} min={0} max={59} step={1} formatter={value => `${value}m`} parser={value => value.replace('m', '')}/>
                </Form.Item>
                </Col>
                <Col>
                <Form.Item label={props.t('manteniment.fi_recomanat')}>
                    <InputNumber name="hora" onChange={setH_fin} value={h_fin} min={0} max={23} step={1} formatter={value => `${value}h`} parser={value => value.replace('h', '')}/>
                    <span>&nbsp;:&nbsp;</span>
                    <InputNumber name="minut" onChange={setM_fin} value={m_fin} min={0} max={59} step={1} formatter={value => `${value}m`} parser={value => value.replace('m', '')}/>
                </Form.Item>
              </Col>
            </Row>
          }
        </Col>
      </Row>

      <Form.Item
        validateStatus={errorProblema}
        label={props.t('manteniment.problema')}
        required={true}
      >
        <TextAreaCount autoSize={{ minRows: 4, maxRows: 25 }} maxLength={1024} name="problema" placeholder={props.t('manteniment.problema')} onChange={problema_Change} value={problema}/>
      </Form.Item>

      <Form.Item label={props.t('manteniment.ubicacio')}>
        <TextAreaCount autoSize={{ minRows: 1, maxRows: 5 }} maxLength={256} name="ubicacio" placeholder={props.t('manteniment.ubicacio')} onChange={setUbicacio} value={ubicacio}/>
      </Form.Item>
      <Form.Item label={props.t('manteniment.solucio')}>
        <TextAreaCount autoSize={{ minRows: 4, maxRows: 25 }} maxLength={1024} name="solucio" onChange={setSolucio} value={solucio} disabled={!["Administrador", "Manteniment"].includes(perfilUsuari.account.perfil)}/>
      </Form.Item>
      { ["Administrador", "Manteniment"].includes(perfilUsuari.account.perfil)
        ?
        <Form.Item label={props.t('manteniment.observacions')+' (Visible només per usuaris Administradors i de Manteniment)'}>
          <TextAreaCount autoSize={{ minRows: 4, maxRows: 25 }} maxLength={1024} name="observacions" placeholder={props.t('manteniment.observacions')} onChange={setObservacions} value={observacions}/>
        </Form.Item>
        : null
      }

        <Row type="flex" justify="start" style={{marginTop: 25, marginBottom: 25}}>
          <Col>
            <Button onClick={()=>props.callback()}>{props.t('generic.back')}</Button>
          </Col>
          <Col style={{marginLeft:'10px'}}>
            <Button type="primary" htmlType="submit" loading={guardant}>{props.t('generic.save')}</Button>
          </Col>
        </Row>
      </Form>
    </>
  );
}
export default translate(MantenimentEditDades);
