import React, { useState, useEffect } from 'react';
import { translate } from 'react-multi-lang';
import { Table, Input, Button, Space, Row, Col, Tooltip, Popconfirm, Modal, Form, Select, Card, message } from 'antd';

import { SearchOutlined, EditOutlined, DeleteOutlined, AlertFilled, ClearOutlined } from '@ant-design/icons';
import { AllHtmlEntities } from 'html-entities';

import Moment from 'react-moment';
import moment from 'moment';

import TitolPagina from '../../../TitolPagina';
import LlistaEditableModalNouEdit from '../../../LlistaEditableModalNouEdit';
import Edit from './RebutsEntregatCompteEdit';
import { Api, InitDades } from '../../../../helpers';

const entities = new AllHtmlEntities();

function RebutsEntregatCompte({t, id, rebut_total, estat, callback_entregat}) {
  moment.locale('es');
  const [id_rebut, setId_rebut] = useState(id)
  const [refrescar, setRefrescar] = useState(0);
  const [triat, setTriat] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [dades, setDades] = useState([]);
  const [total, setTotal] = useState(0);
  const [pendent, setPendent] = useState(parseFloat(rebut_total) - parseFloat(total));
  const [actualitzar, setActualitzar] = useState(0);
  const [isLoading, setIsLoading] = useState(false);


  let itemBuit = InitDades.rebut_entregat();
  itemBuit.id_rebut = id_rebut;

/*
  useEffect(() => {
    setId_rebut(id);
  },[id]);
*/

useEffect(() => {
  buscarDades()
},[actualitzar]);

useEffect(() => {
  callback_entregat(total);
},[total]);

useEffect(() => {
  setPendent(parseFloat(rebut_total) - parseFloat(total))
},[rebut_total, total]);



  const buscarDades = async () => {
    if(id_rebut > 0){
      setIsLoading(true)
      const tmp = await Api.get(`/rebuts/entregat/compte/${id_rebut}`);
      if(tmp.result.success===1){
        setDades(tmp.data.rebuts_entregat);
        setTotal(tmp.data.total_entregat);
        console.log(tmp.data)
      }
      setIsLoading(false)
    }
  }


  const marcarEliminat = async (id) => {
    const fam = await Api.myFetch(`/rebuts/entregat/compte/${id}`, 'DELETE');
    if(fam.result.success===1){
      setActualitzar(actualitzar+1);
    }
  }

  const tradueixTipusPagament = (value) =>{
    switch (value) {
      case 'BANC':
        return t('families.pagament_banc');
        break;
      case 'TRANSFERENCIA':
        return t('families.pagament_transferencia');
        break;
      case 'EFECTIU':
        return t('families.pagament_efectiu');
        break;
      case 'TARGETA':
        return t('families.pagament_targeta');
        break;
      case 'XEC_GUARDERIA':
        return t('families.pagament_xec_guarderia');
        break;
      default:
        return '--';

    }
  }

  const obrirModal = item =>{
    setTriat(item);
    setModalVisible(true);
  }

  const tancarModal = e =>{
    setActualitzar(actualitzar+1);
    setModalVisible(false);
  }

  const columns = [
    {
      title:"Id",
      key:"id",
      render: (text,record) => (<span>{record.id}</span>)
    },
    {
      title: t('generic.concepte'),
      key: "concepte",
      dataIndex: "concepte",
      render: (text,record) => <span>{entities.decode(record.concepte)}</span>
    },
    {
      title: t('quotes.import'),
      key: "import",
      dataIndex: "import",
      render: (text,record) => <span>{parseFloat(record.import).toFixed(2)}€</span>,
    },
    {
      title: t('families.tipus_pagament'),
      key: "tipus_pagament",
      width: "175px",
      dataIndex: "tipus_pagament",
      render: (text,record) => (tradueixTipusPagament(record.tipus_pagament))
    },
    {
      title: t('generic.data'),
      key: "data_pagament",
      dataIndex: "data_pagament",
      width: "175px",
      render: (text,record) =>(
          record.data_pagament ? moment(record.data_pagament).tz('Europe/Madrid').format("DD/MM/YYYY") : ''
      )
    }
    ,{
      key: "actions",
      align: "right",
      width: "95px",
      render: (text,record) => (
        <Row type="flex" justify="end" id={"actions_"+record.id} key={"actions_"+record.id}>
          <Col span={12}>
            <Tooltip placement="top" title={t('generic.modify')}>
              <Button icon={<EditOutlined />} onClick={(e) => obrirModal(record)} ghost="true" className="BotoTaula"/>
            </Tooltip>
          </Col>
          <Col span={12}>
            <Tooltip placement="top" title={t('generic.delete')}>
              <Popconfirm placement="bottom" title={t('generic.confirm_delete')} okText="Si" cancelText="No" onConfirm={()=>marcarEliminat(record.id)}>
                <Button icon={<DeleteOutlined />} ghost="true" className="BotoTaula"/>
              </Popconfirm>
            </Tooltip>
          </Col>
        </Row>
      )
    }
  ];


  return (
    <>
      <Card
      headStyle={{backgroundColor: "#fbe9e7"}}
      style={{borderColor: "#fbe9e7", marginTop:'1rem'}}
      hoverable={true}
      //title={total > 0 ? t('rebuts.entregat_compte')+': '+total+'€ '+t('rebuts.estat.pendent')+': '+pendent+'€' : t('rebuts.entregat_compte') }
      title={total > 0 ? <span>{`${t('rebuts.entregat_compte')}: ${parseFloat(total).toFixed(2)}€`}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<b>{`${t('rebuts.estat.pendent')}: ${parseFloat(pendent).toFixed(2)}€`}</b></span> : t('rebuts.entregat_compte') }
      extra={<Button type="primary" onClick={() => obrirModal(itemBuit)} disabled={estat === 'cobrat'}>{t('generic.add')}</Button>}>
        {!dades.length ? null :
          <Table
            loading={isLoading}
            key="entregat_compte_taula"
            dataSource={dades}
            columns={columns}
            paginatio
            pagination={false}
            size={'small'}
            onRow={(record, rowIndex) => {
              return {
                onClick: event => {},
                onDoubleClick: (e) => obrirModal(record),
                onContextMenu: (e) => {e.preventDefault(); obrirModal(record);},
              };
            }}
            />
          }
      </Card>
      <Modal
          key="entregat_compte"
          title={t('rebuts.entregat_compte')}
          visible={modalVisible}
          footer={null}
          onCancel={tancarModal}
          width={550}
          style={{top:20}}
          //bodyStyle={{ height: "90vh", overflow: "auto" }}
          destroyOnClose={true}
          forceRender={true}
        >
          <Edit item={triat} pendent={pendent} callback={tancarModal}/>
        </Modal>
    </>
  );

}

export default translate(RebutsEntregatCompte);
