/* HtmStrings.js */
/* Funcions per a modificar strings que contenene html */

function stripHtml(html){
  //Treure tots els tags html d'un string.
  // Create a new div element
  var temporalDivElement = document.createElement("div");
  // Set the HTML content with the providen
  temporalDivElement.innerHTML = html;
  // Retrieve the text property of the element (cross-browser support)
  return temporalDivElement.textContent || temporalDivElement.innerText || "";
}


function arreglarTxt4Search(texto){
    // Poso en minuscules, normalitzo caracters treure accents i ñ , elimina els caracters diacrítics raros d'un text (ES6)
    return texto.trim().split(' ')[0].toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g,"");
}
function normalize(text){
    // Poso en minuscules, normalitzo caracters treure accents i ñ , elimina els caracters diacrítics raros d'un text (ES6)
    //return texto.trim().split(' ')[0].toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g,"");
    return text.trim().toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g,"");
}

function keyGen(keyLength) {
    //keyGen(12) retorna "QEt9mYBiTpYD"
    var i, key = "", characters = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
    var charactersLength = characters.length;
    for (i = 0; i < keyLength; i++) {
        key += characters.substr(Math.floor((Math.random() * charactersLength) + 1), 1);
    }
    return key;
}

function filtrarNumText(string){//solo letras y numeros
    var out = '';
    //Se añaden las letras validas
    var filtro = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890-_.@';//Caracteres validos

    for (var i=0; i<string.length; i++)
       if (filtro.indexOf(string.charAt(i)) != -1)
	     out += string.charAt(i);
    return out;
}

function urlAddHttps(url){
  if (!url) return url;
  if (!/^https?:\/\//i.test(url)) {
    const retorna = 'https://' + url;
    return retorna;
  }
}

export {
  stripHtml,
  arreglarTxt4Search,
  normalize,
  keyGen,
  filtrarNumText,
  urlAddHttps,
};
