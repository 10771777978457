import React, { useState, useEffect, useContext } from 'react';
import { translate } from 'react-multi-lang';
import { SyncOutlined, PercentageOutlined, LeftOutlined, EditOutlined, DeleteOutlined, SearchOutlined, FileExcelOutlined } from '@ant-design/icons';
import {Table, Tooltip, Popconfirm, Modal, Form, Row, Col, Select, Button, Input, InputNumber, Divider, Switch, DatePicker, Space, message } from 'antd';
import moment from 'moment';
import 'moment/locale/ca';
import 'moment/locale/es';
import locale_ca from 'antd/es/date-picker/locale/ca_ES';
import locale_en from 'antd/es/date-picker/locale/en_GB';
import locale_es from 'antd/es/date-picker/locale/es_ES';

import { AllHtmlEntities } from 'html-entities';

import { PerfilUsuariContext } from "../../../context/perfil-usuari";
import { Api, InitDades, HtmlStrings } from '../../../helpers';
import ContainerPagina from '../../../components/ContainerPagina';
import TitolPagina from '../../../components/TitolPagina';
import MedicEditOne from '../../../components/Formularis/perfils/admin/MedicEditOne';
import MedicAddOne from '../../../components/Formularis/perfils/admin/MedicAddOne';

const entities = new AllHtmlEntities();


function Medic(props){

  const [perfilUsuari] = useContext(PerfilUsuariContext);
  const [linies, setLinies]=useState([]);
  const [itemActual, setItemActual] = useState(null);
  const [modalVisible, setModalVisible] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [refrescar, setRefrescar] = useState(0);

  const [filtresClasses, setFiltresClasses] = useState([]);
  const [dadesRebutsBanc, setDadesRebutsBanc] = useState([]);
  const [dadesRebutsAltres, setDadesRebutsAltres] = useState([]);

  const [edit, setEdit] = useState(true);
  //CERCA
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const [searchInput, setSearchInput] = useState([]);
  //FI CERCA


  useEffect(() => {
    buscarDades();
  },[refrescar]);

  useEffect(() => {
    llistatClasses();
  },[]);


  const buscarDades = async () =>{
    setIsLoading(true);
    const m = await Api.get('/medic/alumnes');
    if(m.result.success===1){

      const me = m.data.medic.map((item)=>{

          item.alergia = null
          item.intolerancia = null
          item.medicacio = null
          item.cronica = null

        if (item.medic) {
          item.medic.map((m) => {
            if(m.id_categoria == 4){
              item.alergia = m.text
            }
            if(m.id_categoria == 5){
              item.intolerancia = m.text
            }
            if(m.id_categoria == 6){
              item.medicacio = m.text
            }
            if(m.id_categoria == 7){
              item.cronica = m.text
            }
          });
        }


        return item;
      })


      console.log('medic',me)
      //setLinies(m.data.medic);
      setLinies(me);
    }

    setIsLoading(false);
  }



  const llistatClasses = async() =>{
    const cla = await Api.get('/classes');
    if(cla.result.success===1){
      const filtreCl = cla.data.classes.map((item)=>{
        return {text: item.nom, value: item.id}
      });
      //console.log('classes: ', filtreCl)
      setFiltresClasses(filtreCl);
    }
  }

  const afegirMedic = () =>{

    setEdit(false);
    setModalVisible(true);

  }

  const editarMedic = (item) =>{

    setEdit(true);
    setItemActual(item);
    setModalVisible(true);

  }

  const submitDelete = async (id) =>{
    const r = await Api.myFetch(`/medic/delete/${id}`, 'DELETE');
    if(r.result.success===1){
      setRefrescar(refrescar+1);
    }
  }

  const tancarModal = e =>{
    setRefrescar(refrescar+1);
    setModalVisible(false);
  }



  //CERCA
    const getColumnSearchProps = (dataIndex, nom) => ({
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <div style={{ padding: 8 }}>
          <Input
            ref={node => {
              let search = searchInput;
              search[dataIndex] = node;
              setSearchInput(search);
            }}
            placeholder={`${props.t('generic.cercar')} ${nom}`}
            value={selectedKeys[0]}
            onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
            style={{ width: 188, marginBottom: 8, display: 'block' }}
            autoFocus="focus"
          />
          <Space>
            <Button
              type="primary"
              onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
              icon={<SearchOutlined />}
              size="small"
              style={{ width: 90 }}
            >
              {props.t('generic.cercar')}
            </Button>
            <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
              Reset
            </Button>
          </Space>
        </div>
      ),
      filterIcon: filtered => <SearchOutlined style={{color: filtered ? '#1890ff' : undefined }} />,
      onFilter: (value, record) =>
        record[dataIndex] ? arreglarTxt4Search(entities.decode(record[dataIndex].toString())).includes(arreglarTxt4Search(value)) : '',
      onFilterDropdownVisibleChange: visible => {
        if (visible) {
          setTimeout(() => searchInput[dataIndex].select(),100);
        }
      },
     render: text => (entities.decode(text)),
    });

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
      confirm();
      setSearchText(selectedKeys[0]);
      setSearchedColumn(dataIndex);
    };

    const handleReset = clearFilters => {
      clearFilters();
      setSearchText('');
    };

    const arreglarTxt4Search = text =>{
        // Poso en minuscules, normalitzo caracters treure accents i ñ , elimina els caracters diacrítics raros d'un text (ES6)
        //return text.trim().split(' ')[0].toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g,"");
        return text.trim().toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g,"");
    }
  //FI CERCA


  const columns = [
    {
      title: `#${props.t('generic.alumne')}`,
      key:"id_alumne",
      dataIndex: "id_alumne",
      width: 80,
      sorter: (a, b) =>  a.id_alumne - b.id_alumne,
      render: (text,record) => (<span>{record.id_alumne}</span>)
    },{
      title: props.t('alumnes.classe'),
      width: 180,
      key:"classe",
      dataIndex: 'classe',
      filters: filtresClasses.length > 0 ? filtresClasses : null,
      onFilter: (value, record) => {
        return parseInt(record.id_classe) === value;
      },
      render: (text,record) =>(entities.decode(record.classe)),
      sorter: (a, b) => { return entities.decode(a.classe).localeCompare(entities.decode(b.classe))},
      defaultSortOrder: 'ascend',
    },{
      title: props.t('alumnes.nom'),
      key:"alumne_nom",
      dataIndex: "alumne_nom",
      sorter: (a, b) => {return entities.decode(a.alumne_nom).localeCompare(entities.decode(b.alumne_nom))},
      defaultSortOrder: 'ascend',
      render: (text,record) => (<span>{entities.decode(record.alumne_nom)}</span>),
      ...getColumnSearchProps('alumne_nom', props.t('alumnes.nom'))
    },{
      title: props.t('alumnes.cognom1'),
      key:"alumne_cognom1",
      dataIndex: "alumne_cognom1",
      sorter: (a, b) => {return entities.decode(a.alumne_cognom1).localeCompare(entities.decode(b.alumne_cognom1))},
      render: (text,record) => (<span>{entities.decode(record.alumne_cognom1)}</span>),
      ...getColumnSearchProps('alumne_cognom1', props.t('alumnes.cognom1'))
    },{
      title: props.t('alumnes.cognom2'),
      key:"alumne_cognom2",
      dataIndex: "alumne_cognom2",
      sorter: (a, b) => {return entities.decode(a.alumne_cognom2).localeCompare(entities.decode(b.alumne_cognom2))},
      render: (text,record) => (<span>{entities.decode(record.alumne_cognom2)}</span>),
      ...getColumnSearchProps('alumne_cognom2', props.t('alumnes.cognom2'))
    },{
      title: 'Al·lergia',
      key:"alergia",
      dataIndex: "alergia",
      sorter: (a, b) => {return entities.decode(a.alergia).localeCompare(entities.decode(b.alergia))},
      render: (text,record) => (<span>{entities.decode(record.alergia)}</span>),
      ...getColumnSearchProps('alergia', 'Al·lergia')
    },{
      title: 'Intolerància',
      key:"intolerancia",
      dataIndex: "intolerancia",
      sorter: (a, b) => {return entities.decode(a.intolerancia).localeCompare(entities.decode(b.intolerancia))},
      render: (text,record) => (<span>{entities.decode(record.intolerancia)}</span>),
      ...getColumnSearchProps('intolerancia', 'Intolerància')
    },{
      title: 'Medicació',
      key:"medicacio",
      dataIndex: "medicacio",
      sorter: (a, b) => {return entities.decode(a.medicacio).localeCompare(entities.decode(b.medicacio))},
      render: (text,record) => (<span>{entities.decode(record.medicacio)}</span>),
      hidden: ["Administrador"].includes(perfilUsuari.account.perfil) ? false : true, //PER OCULTAR MEDICACIO SI NO ES Administrador 1/2
      ...getColumnSearchProps('medicacio', 'Medicació')
    },{
      title: 'Malaltia Crònica',
      key:"cronica",
      dataIndex: "cronica",
      sorter: (a, b) => {return entities.decode(a.cronica).localeCompare(entities.decode(b.cronica))},
      render: (text,record) => (<span>{entities.decode(record.cronica)}</span>),
      ...getColumnSearchProps('cronica', 'Malaltia Crònica')
    }
  ].filter(item => !item.hidden); //PER OCULTAR MEDICACIO SI NO ES Administrador 2/2


  const columnesTaulaDetall = [
      { title: props.t('medic.categoria'), key: "concepte",
        render: (text,record) =><span>{entities.decode(record.categoria[props.t('idioma')])}</span>
      },
      { title: props.t('medic.text_lliure'), key: "text",
        render: (text,record) =><span>{entities.decode(record.text)}</span>
      },
      { title: props.t('medic.file_count'), dataIndex: "file_count", key: "file_count"
      },{
        key: "actions",
        align: "right",
        width: "75px",
        render: (text,record) => (
          <Row type="flex" justify="end" id={"actions_"+record.id}>
            <Col span={12 }>
              <Tooltip placement="top" title={props.t('generic.modify')}>
                <Button icon={<EditOutlined />} onClick={(e) => editarMedic(record)} ghost="true" className="BotoTaula"/>
              </Tooltip>
            </Col>
            <Col span={12}>
              <Tooltip placement="top" title={props.t('generic.delete')}>
                <Popconfirm placement="bottom" title={props.t('generic.confirm_delete')} okText={props.t('generic.si')} cancelText={props.t('generic.no')} onConfirm={()=>submitDelete(record.id)}>
                  <Button icon={<DeleteOutlined />} ghost="true" className="BotoTaula"/>
                </Popconfirm>
              </Tooltip>
            </Col>
          </Row>
      )
      }
    ];

  const subTaulaDetall = row => {
    return (
      <div>
        <Row style={{margin: '1rem'}}>
          <Col span={22}>
            <Table rowKey="id" columns={columnesTaulaDetall} dataSource={row.medic} pagination={false} size={'small'} bordered={false} />
            </Col>
        </Row>
      </div>
    );
  };


  return(
    <ContainerPagina xl={24}>

      <Row align="middle" justify="space-between">
        <Col>
          <TitolPagina txt={props.t('medic.titol')}/>
        </Col>
        <Col>
          <Button type="primary" onClick={afegirMedic}>{props.t('generic.add')}</Button>
        </Col>
      </Row>
        <div style={{marginTop:'1rem', marginLeft:'3rem'}}>

          <Table
            locale={{filterConfirm: 'OK', filterReset: 'Reset'}}
            rowKey="id_alumne"
            pagination={false}
            loading={isLoading}
            dataSource={linies}
            expandedRowRender={subTaulaDetall}
            defaultExpandAllRows={false}
            columns={columns}
            size="small"
          />
          <Modal
              key="concepte"
              //title={`${props.t('generic.add')} ${props.t('generic.concepte')}`}
              visible={modalVisible}
              footer={null}
              onCancel={tancarModal}
              width={600}
              style={{top:20}}
              destroyOnClose={true}
              forceRender={true}
            >
            {edit
              ? <MedicEditOne item={itemActual} callback={tancarModal}/>
              : <MedicAddOne callback={tancarModal}/>
            }

          </Modal>
      </div>
    </ContainerPagina>
  );
}
export default translate(Medic);
