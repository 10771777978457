import React, { useState, useEffect } from 'react';
import { translate } from 'react-multi-lang';
import { Table, Input, Button, Space, Row, Col, Tooltip, Popconfirm, Modal, Form, Select, Switch, message } from 'antd';
import { SearchOutlined, EditOutlined, DeleteOutlined, InboxOutlined, AlertFilled, ClearOutlined } from '@ant-design/icons';
import { AllHtmlEntities } from 'html-entities';

import Moment from 'react-moment';
import moment from 'moment';

import ContainerPagina from '../../../components/ContainerPagina';
import TitolPagina from '../../../components/TitolPagina';

import LlistaEditableModalNouEdit from '../../../components/LlistaEditableModalNouEdit';
import Edit from '../../../components/Formularis/perfils/admin/ComandesRoba';
import OpenDaysExportaExcel from '../../../components/Formularis/perfils/admin/OpenDaysExportaExcel';
import { Api, InitDades } from '../../../helpers';


const entities = new AllHtmlEntities();

function OpenDays(props){
  moment.locale('es');

  const [refrescar, setRefrescar] = useState(0);

  //CERCA
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const [searchInput, setSearchInput] = useState([]);
  //FI CERCA

  const buscarDades = async () => {
    const o = await Api.get(`/opendays`);
    console.log(o)
    if(o.result.success===1){
      return o.data.opendays;
    }
  }

  //CERCA
    const getColumnSearchProps = (dataIndex, nom) => ({
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <div style={{ padding: 8 }}>
          <Input
            ref={node => {
              let search = searchInput;
              search[dataIndex] = node;
              setSearchInput(search);
            }}
            placeholder={`${props.t('generic.cercar')} ${nom}`}
            value={selectedKeys[0]}
            onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
            style={{ width: 188, marginBottom: 8, display: 'block' }}
            autoFocus="focus"
          />
          <Space>
            <Button
              type="primary"
              onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
              icon={<SearchOutlined />}
              size="small"
              style={{ width: 90 }}
            >
              {props.t('generic.cercar')}
            </Button>
            <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
              Reset
            </Button>
          </Space>
        </div>
      ),
      filterIcon: filtered => <SearchOutlined style={{color: filtered ? '#1890ff' : undefined }} />,
      onFilter: (value, record) =>
        record[dataIndex] ? arreglarTxt4Search(entities.decode(record[dataIndex].toString())).includes(arreglarTxt4Search(value)) : '',
      onFilterDropdownVisibleChange: visible => {
        if (visible) {
          setTimeout(() => searchInput[dataIndex].select(),100);
        }
      },
     render: text => (entities.decode(text)),
    });

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
      confirm();
      setSearchText(selectedKeys[0]);
      setSearchedColumn(dataIndex);
    };

    const handleReset = clearFilters => {
      clearFilters();
      setSearchText('');
    };

    const arreglarTxt4Search = text =>{
        // Poso en minuscules, normalitzo caracters treure accents i ñ , elimina els caracters diacrítics raros d'un text (ES6)
        //return text.trim().split(' ')[0].toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g,"");
        return text.trim().toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g,"");
    }
  //FI CERCA


  const columns = [
    {
        title:"Id",
        key:"id_open_days",
        sorter: (a, b) =>  a.id_open_days - b.id_open_days,
        render: (text,record) => (<span>{record.id_open_days}</span>),
      },{
        title:props.t('generic.data'),
        key:"dia_triat",
        sorter: (a, b) => moment(a.dia_triat).diff(moment(b.dia_triat)),
        render:(text,record) => (record.dia_triat ? <Moment date={moment(record.dia_triat)} format="DD/MM/YYYY"/> : "" )
    },{
        title: props.t('generic.nom'),
        key: "name",
        dataIndex: 'name',
        sorter: (a, b) =>  a.name - b.name,
        ...getColumnSearchProps('name',props.t('generic.nom'))
    },{
        title: props.t('pares.cognoms'),
        key: "surnames",
        dataIndex: 'surnames',
        sorter: (a, b) =>  a.surnames - b.surnames,
        ...getColumnSearchProps('surnames',props.t('pares.cognoms'))
    },{
        title: props.t('pares.email'),
        key: "email",
        dataIndex: 'email',
        sorter: (a, b) =>  a.email - b.email,
        ...getColumnSearchProps('email',props.t('pares.email'))
    },{
        title: props.t('pares.tel1'),
        key: "phone",
        dataIndex: 'phone',
        sorter: (a, b) =>  a.phone - b.phone,
        ...getColumnSearchProps('phone',props.t('pares.tel1'))
    },{
        title: props.t('generic.alumne'),
        key: "name_student",
        dataIndex: 'name_student',
        sorter: (a, b) =>  a.name_student - b.name_student,
        ...getColumnSearchProps('name_student',props.t('generic.alumne'))
    },{
      title:props.t('alumnes.naixement'),
      key:"date_student",
      sorter: (a, b) => moment(a.date_student).diff(moment(b.date_student)),
      render:(text,record) => (record.date_student ? <Moment date={moment(record.date_student)} format="DD/MM/YYYY"/> : "" )
    },{
      title:props.t('generic.created_at'),
      key:"created_at",
      sorter: (a, b) => moment(a.created_at).diff(moment(b.created_at)),
      render:(text,record) => (record.created_at ? <Moment date={moment(record.created_at).tz('Europe/Madrid')} format="DD/MM/YYYY HH:mm"/> : "" )
    }];

return(
  <ContainerPagina xl={24}>
    <TitolPagina txt={props.t('menu.OpenDays')}/>
    <div style={{marginTop:'1rem', marginLeft:'3rem'}}>
      <LlistaEditableModalNouEdit
        scroll={{ x: 1500}}
        txt_creats={props.t('comandes.creados')}
        txt_nou={props.t('comandes.nuevo')}
        modal_width={900}
        buscarDades={buscarDades}
        dadesMostradesCallback={(dadesMostrades) => {
          return(
            <div style={{marginTop: '1rem'}}>
              <OpenDaysExportaExcel dades={dadesMostrades} nom_fulla={'OpenDays'} nom_fitxer={`OpenDays_${moment().format('YYMMDD_HHmm')}`} btn_txt="Export"/>
            </div>
          )
        }}
        refrescar={refrescar}
        //edit={Edit}
        //add={Edit}
        //duplica={true}
        //delete={marcarEliminat}
        itemBuit={InitDades.comandes_roba()}
        columns={columns}
        icon_creats={InboxOutlined}
        />

    </div>
    </ContainerPagina>
  );

}
export default translate(OpenDays);
