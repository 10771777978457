import React, { useState, useEffect, useContext } from 'react';
import {isMobile} from 'react-device-detect';
import { translate } from 'react-multi-lang';
import { Table, Input, Button, Space, Row, Col, Tooltip, Popconfirm, Modal, Form, Select, List, Card, message } from 'antd';
import Highlighter from 'react-highlight-words';
import { SearchOutlined, ClearOutlined, EditOutlined, DeleteOutlined, InboxOutlined, AlertFilled, AlertTwoTone, AppstoreOutlined, SyncOutlined, TableOutlined, PlusOutlined } from '@ant-design/icons';
import { AllHtmlEntities } from 'html-entities';

import Moment from 'react-moment';
import moment from 'moment';

import ContainerPagina from '../../../components/ContainerPagina';
import TitolPagina from '../../../components/TitolPagina';
import LlistaEditableModalNouEdit from '../../../components/LlistaEditableModalNouEdit';
import Edit from '../../../components/Formularis/perfils/admin/MantenimentTicEdit';
//import ComandesExcursionsExportaExcel from '../../../components/Formularis/perfils/admin/ComandesExcursionsExportaExcel';
import { Api, InitDades } from '../../../helpers';
import { PerfilUsuariContext } from "../../../context/perfil-usuari";

const { Column } = Table;
const entities = new AllHtmlEntities();



function Manteniment(props){
  moment.locale('es');
  const [perfilUsuari, setPerfilUsuari] = useContext(PerfilUsuariContext);
  //const [filtresClasses, setFiltresClasses] = useState([]);
  //const [filtresExcursions, setFiltresExcursions] = useState([]);
  const [triats, setTriats] = useState([]);
  const [modificarEstatTriatsLoading, setModificarEstatTriatsLoading] = useState(false);
  const [modalEstatVisible, setModalEstatVisible] = useState(false);
  const [refrescar, setRefrescar] = useState(0);
  const [estatsOptions, setEstatsOptions] = useState([]);
  const [estatTriat, setEstatTriat] = useState('Pendent');


  const [mostrarTaula, setMostrarTaula ] = useState(!isMobile);
  const [dades, setDades] = useState([]);
  const [accio, setAccio] = useState('taula');
  const [modalVisible, setModalVisible] = useState(false);
  const [itemActual, setItemActual] = useState(null);

    //CERCA
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const [searchInput, setSearchInput] = useState([]);
  //FI CERCA

  useEffect(() => {
    muntarSelectEstat();
  },[]);

  useEffect(() => {
    buscarDades2();
  },[refrescar]);

  const obrirModalEditar = (item) =>{
    setAccio('editar')
    setItemActual(item);
    setModalVisible(true);
  }
  const obrirModalAdd = () =>{
    setAccio('add')
    setItemActual(InitDades.manteniment());
    setModalVisible(true);
  }

  const tancarModal = e =>{
    setRefrescar(refrescar+1);
    setModalVisible(false);
  }

  const buscarDades = async () => {
    const com = await Api.get(`/tic/all`);
    if(com.result.success===1){
      return com.data.manteniment;
    }
  }
  const buscarDades2 = async () => {
    const com = await Api.get(`/tic/all`);
    if(com.result.success===1){
      console.log('Dades manteniment: ', com.data.manteniment)
      setDades(com.data.manteniment);
    }
  }



  const eliminarComanda = async (id) => {
  const c = await Api.myFetch(`/tic/${id}`, 'DELETE');
    if(c.result.success===1){
      //setRefrescar(refrescar+1)
    }

  }


  const modificarEstatTriats = async () =>{

    setModificarEstatTriatsLoading(true);

    let dades = {
      estat: estatTriat,
      ids_comandes: triats
    }

    try{
      //  console.log(JSON.stringify(dades))
      const resposta = await Api.myFetch(`/tic/estat/${estatTriat}`, 'PUT', JSON.stringify(dades));
      if(resposta.result.success === 1){
        setTriats([]);
        setEstatTriat('Pendent');
        setModificarEstatTriatsLoading(false);
        setModalEstatVisible(false);
        setRefrescar(refrescar+1);
      }else{
        setModificarEstatTriatsLoading(false);
        console.error('Error: submit MantenimentTic estat: ',resposta);
        message.config({top: 50});
        message.open({content: resposta.data.message, duration:10});
        return false;
      }

    }catch(error){
      setModificarEstatTriatsLoading(false);
      message.config({top: 50});
      message.open({content: "Error saving state", duration:10});
      console.error('Error: MantenimentTic guardar estat: ', error);
      return false;
    }

  }

  //CERCA
    const getColumnSearchProps = (dataIndex, nom) => ({
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <div style={{ padding: 8 }}>
          <Input
            ref={node => {
              let search = searchInput;
              search[dataIndex] = node;
              setSearchInput(search);
            }}
            placeholder={`${props.t('generic.cercar')} ${nom}`}
            value={selectedKeys[0]}
            onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
            style={{ width: 188, marginBottom: 8, display: 'block' }}
            autoFocus="focus"
          />
          <Space>
            <Button
              type="primary"
              onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
              icon={<SearchOutlined />}
              size="small"
              style={{ width: 90 }}
            >
              {props.t('generic.cercar')}
            </Button>
            <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
              Reset
            </Button>
          </Space>
        </div>
      ),
      filterIcon: filtered => <SearchOutlined style={{color: filtered ? '#1890ff' : undefined }} />,
      onFilter: (value, record) =>
        record[dataIndex] ? arreglarTxt4Search(entities.decode(record[dataIndex].toString())).includes(arreglarTxt4Search(value)) : '',
      onFilterDropdownVisibleChange: visible => {
        if (visible) {
          setTimeout(() => searchInput[dataIndex].select(),100);
        }
      },
     render: text => (entities.decode(text)),
    });

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
      confirm();
      setSearchText(selectedKeys[0]);
      setSearchedColumn(dataIndex);
    };

    const handleReset = clearFilters => {
      clearFilters();
      setSearchText('');
    };

    const arreglarTxt4Search = text =>{
        // Poso en minuscules, normalitzo caracters treure accents i ñ , elimina els caracters diacrítics raros d'un text (ES6)
        //return text.trim().split(' ')[0].toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g,"");
        return text.trim().toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g,"");
    }
  //FI CERCA

  const muntarSelectEstat = () =>{

    let estats = [
      'pendent',
      'encurs',
      'faltamaterial',
      'resolt',
      'pausat',
      'anulat'
    ];

    const llista_estats = estats.map((valor) =>{
      return(
        <Select.Option key={valor} value={valor}>
          <span><AlertFilled style={{color: `${props.t(`manteniment.estatColor.${valor}`)}`}}/>&nbsp;{props.t(`manteniment.estat.${valor}`)}</span>
        </Select.Option>
      );
    });
    setEstatsOptions(llista_estats);
  }

  const filtreEstat =[
  {text: <span><AlertFilled style={{color: `${props.t('manteniment.estatColor.pendent')}`}}/>&nbsp;{props.t('manteniment.estat.pendent')}</span>, value: 'pendent'},
  {text: <span><AlertFilled style={{color: `${props.t('manteniment.estatColor.encurs')}`}}/>&nbsp;{props.t('manteniment.estat.encurs')}</span>, value: 'encurs'},
  {text: <span><AlertFilled style={{color: `${props.t('manteniment.estatColor.faltamaterial')}`}}/>&nbsp;{props.t('manteniment.estat.faltamaterial')}</span>, value: 'faltamaterial'},
  {text: <span><AlertFilled style={{color: `${props.t('manteniment.estatColor.resolt')}`}}/>&nbsp;{props.t('manteniment.estat.resolt')}</span>, value: 'resolt'},
  {text: <span><AlertFilled style={{color: `${props.t('manteniment.estatColor.pausat')}`}}/>&nbsp;{props.t('manteniment.estat.pausat')}</span>, value: 'pausat'},
  {text: <span><AlertFilled style={{color: `${props.t('manteniment.estatColor.anulat')}`}}/>&nbsp;{props.t('manteniment.estat.anulat')}</span>, value: 'anulat'}
  ];

  const findEstat = (estat) =>{
    let est = {text: '', value: ''};
    est = filtreEstat.find((element, index) => element.value === estat);

    return est.text;
  }

const columns = [
{
  title:"Id",
  key:"id",
  sorter: (a, b) =>  a.id - b.id,
  render: (text,record) => (<span>{record.id}</span>)
},{
  title: props.t('botiga.titolEstat'),
  key:"estat",
  dataIndex: 'estat',
  filters: filtreEstat,
  defaultFilteredValue: ['pendent','encurs','faltamaterial','pausat'],
  onFilter: (value, record) => record.estat === value,
  render: (text,record) =>{
    return (<span><AlertFilled style={{color: `${props.t(`manteniment.estatColor.${record.estat}`)}`}}/>&nbsp;{props.t(`manteniment.estat.${record.estat}`)}</span>);
  },
  sorter: (a, b) => { return a.estat.localeCompare(b.estat)}
},{
  title: props.t('manteniment.problema'),
  key: "problema",
  dataIndex: 'problema',
  render: (text,record) =>(entities.decode(record.problema))
},{
  title: props.t('manteniment.ubicacio'),
  key: "ubicacio",
  dataIndex: 'ubicacio',
  render: (text,record) =>(entities.decode(record.ubicacio))
},

/*{
  title: props.t('manteniment.solucio'),
  key: "solucio",
  dataIndex: 'solucio',
  render: (text,record) =>(entities.decode(record.solucio))
},{
  title: props.t('manteniment.observacions'),
  key: "observacions",
  dataIndex: 'observacions',
  render: (text,record) =>(entities.decode(record.observacions))
},*/

{
  title: props.t('manteniment.hora_recomanada'),
  children: [
    {
      title: props.t('generic.hini'),
      key:"h_ini",
      render:(text,record) => ((record.h_ini==0) ? '-' : <span>{String(record.h_ini).padStart(2, '0')}:{String(record.m_ini).padStart(2, '0')}</span>)
    },{
      title: props.t('generic.hfin'),
      key:"h_fin",
      render:(text,record) => ((record.h_fin==0) ? '-' : <span>{String(record.h_fin).padStart(2, '0')}:{String(record.m_fin).padStart(2, '0')}</span>)
    }
  ]
},{
  title:props.t('generic.created_at'),
  key:"created_at_z",
  sorter: (a, b) => moment(a.creat).diff(moment(b.creat)),
  render:(text,record) => (record.created_at_z ? <Moment date={moment(record.created_at_z).tz('Europe/Madrid')} format="DD/MM/YYYY HH:mm"/> : "" )
}];

  return(
    <ContainerPagina xl={24}>
    <Row justify="space-between" align="middle">
      <TitolPagina txt={props.t('menu.MantenimentTIC')}/>
      <Row justify="end">
        <Tooltip title="New">
          <Button type="text" icon={<PlusOutlined  style={{fontSize:'1.5rem'}}/>} style={{marginRight:'0.5rem'}} onClick={()=>obrirModalAdd()}/>
        </Tooltip>
        <Tooltip title="Cards">
          <Button type="text" icon={<AppstoreOutlined style={{fontSize:'1.5rem'}}/>} style={{marginRight:'0.5rem'}} onClick={()=>setMostrarTaula(false)}/>
        </Tooltip>
        <Tooltip title="Table">
          <Button type="text" shape="circle" icon={<TableOutlined style={{fontSize:'1.5rem'}}/>} style={{marginRight:'0.5rem'}} onClick={()=>setMostrarTaula(true)}/>
        </Tooltip>
        <Tooltip title="Refresh">
          <Button type="text" shape="circle" icon={<SyncOutlined style={{fontSize:'1.5rem'}}/>} onClick={()=>setRefrescar(refrescar+1)}/>
        </Tooltip>
      </Row>
      </Row>
      <div style={{marginTop:'1rem', marginLeft:'3rem'}}>


      {mostrarTaula===true ?
        <LlistaEditableModalNouEdit
          scroll={{ x: 1500}}
          txt_creats={props.t('comandes.creados')}
          txt_nou={props.t('comandes.nuevo')}
          modal_width={900}
          buscarDades={buscarDades}
          /*dadesMostradesCallback={(dadesMostrades) => {
            return(
              <div style={{marginTop: '1rem'}}>
                <ComandesExcursionsExportaExcel dades={dadesMostrades} btn_txt="Export"/>
              </div>
            )
          }}*/
          refrescar={refrescar}
          edit={Edit}
          add={null}
          btn_extra={
            perfilUsuari.account.perfil==="Administrador"
            ?
              <>
                <Button type="default" disabled={!triats.length > 0 } onClick={()=>setModalEstatVisible(true)}>{props.t('comandes.modificar_estat')}</Button>
                <Tooltip placement="top" title={props.t('comandes.triats_netejar')}>
                   <Button type="default" disabled={!triats.length > 0 }  icon={<ClearOutlined/>} onClick={()=>setTriats([])}></Button>
                </Tooltip>
              </>
            : null
          }
          duplica={true}
          delete={perfilUsuari.account.perfil==="Administrador" ? eliminarComanda : null}
          columns={columns}
          icon_creats={InboxOutlined}
          rowSelection={
            perfilUsuari.account.perfil==="Administrador"
            ?
              {
                selectedRowKeys: triats,
                hideSelectAll: false,
                type: 'checkbox',
                onChange: (selectedRowKeys, selectedRows) => {
                  setTriats(selectedRowKeys);
                }
              }
            : null
        }
          />
      :

        <List
          grid={{
            gutter: 16,
            xs: 1,
            sm: 2,
            md: 3,
            lg: 3,
            xl: 4,
            xxl: 4,
          }}
          dataSource={dades}
          renderItem={item => (
            <List.Item id={item.id}>
              <Card hoverable
              onClick={() => obrirModalEditar(item)}
              title={<Row justify="space-between">
                        <Col>{findEstat(item.estat)}</Col>
                        <Col><span style={{fontSize:'0.8rem', fontWeight:'bold'}}><Moment date={moment(item.created_at_z).tz('Europe/Madrid')} format="DD/MM/YYYY HH:mm"/></span></Col>
                     </Row>}
              >
              <p><b>{props.t('manteniment.problema')}:</b> {item.problema}</p>
              <p><b>{props.t('manteniment.ubicacio')}:</b> {item.ubicacio}</p>
              <p><b>{props.t('manteniment.hora_recomanada')}</b><br/>
              <b>&nbsp;&nbsp;{props.t('generic.hini')}:</b> <span>{String(item.h_ini).padStart(2, '0')}:{String(item.m_ini).padStart(2, '0')}</span><br/>
              <b>&nbsp;&nbsp;{props.t('generic.hfin')}:</b> <span>{String(item.h_fin).padStart(2, '0')}:{String(item.m_fin).padStart(2, '0')}</span>
              </p>

              </Card>
            </List.Item>
          )}
        />

      }

      </div>

      <Modal
        title={props.t('comandes.modificar_estat')}
        destroyOnClose={true}
        visible={modalEstatVisible}
        onOk={modificarEstatTriats}
        confirmLoading={modificarEstatTriatsLoading}
        onCancel={()=>setModalEstatVisible(false)}
      >
        <p>{props.t('comandes.modificar_estat_comandes')}</p>
        <Form.Item label={props.t('botiga.titolEstat')}>
          <Select onChange={(value) => setEstatTriat(value)} defaultValue="Pendent">
            {estatsOptions}
          </Select>
        </Form.Item>
      </Modal>

      <Modal
          key="mantenimentTic"
          title={accio==='editar' ? props.t('generic.modify') : props.t('generic.add')}
          visible={modalVisible}
          footer={null}
          onCancel={tancarModal}
          width={900}
          style={{top:20}}
          destroyOnClose={true}
          forceRender={true}
        >
           <Edit item={itemActual} callback={tancarModal} modal={true}/>
      </Modal>
    </ContainerPagina>
  );
}


export default translate(Manteniment);
